import React from 'react';

export const PrivacyPolicy = () => {
  return (
    <div className="policy">
      <p><strong>DEKLARACJA DOSTĘPNOŚCI</strong></p>
      <p>&nbsp;</p>
      <p>Samodzielny Publiczny Zesp&oacute;ł Opieki Zdrowotnej w Sycowie&nbsp;zobowiązuje się zapewnić dostępność swojej strony internetowej zgodnie z Ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiot&oacute;w publicznych. Oświadczenie w sprawie dostępności ma zastosowanie do strony internetowej: <u>Samodzielny Publiczny Zakład Opieki Zdrowotnej Przychodnia w Sycowie.&nbsp;</u></p>
      <p>&nbsp;</p>
      <p><strong>Status pod względem zgodności</strong></p>
      <p>Strona internetowa jest częściowo zgodna z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiot&oacute;w publicznych z powodu niezgodności lub wyłączeń wymienionych poniżej:</p>
      <ul>
        <li>część plik&oacute;w nie jest dostępnych cyfrowo,</li>
        <li>treści na stronie bez audiodeskrypcji</li>
      </ul>
      <p>Wyłączenia:</p>
      <ul>
        <li>mapy są wyłączone z obowiązku zapewniania dostępności.</li>
      </ul>
      <p>&nbsp;</p>
      <h2>Przygotowanie oświadczenia w sprawie dostępności</h2>
      <p>Niniejsze oświadczenie sporządzono dnia:&nbsp;2020-11-09.</p>
      <h3>Metoda przygotowania oświadczenia:</h3>
      <p>Deklarację sporządzono na podstawie samooceny przeprowadzonej przez podmiot publiczny.</p>
      <h2>Informacje zwrotne i dane kontaktowe</h2>
      <p>W przypadku problem&oacute;w z dostępnością strony internetowej prosimy o kontakt z sekretariatem: <a aria-label="Otwarcie klienta pocztowego" href="mailto:sekretariat@spzozprzychodnia.pl">sekretariat@spzozprzychodnia.pl</a>.</p>
      <p>Kontaktować można się także dzwoniąc na numer telefonu&nbsp;62-785-51-88</p>
      <p>&nbsp;Tą samą drogą można składać wnioski o udostępnienie informacji niedostępnej oraz składać skargi na brak zapewnienia dostępności.</p>
      <p>&nbsp;</p>
      <h2>Informacje na temat procedury</h2>
      <p>Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich elementu. Można także zażądać udostępnienia informacji za pomocą alternatywnego sposobu dostępu, na przykład przez odczytanie niedostępnego cyfrowo dokumentu, opisanie zawartości filmu bez audiodeskrypcji itp. Żądanie powinno zawierać dane osoby zgłaszającej żądanie, wskazanie, o kt&oacute;rą stronę internetową lub aplikację mobilną chodzi oraz spos&oacute;b kontaktu. Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji za pomocą alternatywnego sposobu dostępu, powinna także określić dogodny dla niej spos&oacute;b przedstawienia tej informacji. Podmiot publiczny powinien zrealizować żądanie niezwłocznie, nie p&oacute;źniej niż w ciągu 7 dni od dnia wystąpienia z żądaniem. Jeżeli dotrzymanie tego terminu nie jest możliwe, podmiot publiczny niezwłocznie informuje o tym wnoszącego żądanie, kiedy realizacja żądania będzie możliwa, przy czym termin ten nie może być dłuższy niż 2 miesiące od dnia wystąpienia z żądaniem. Jeżeli zapewnienie dostępności cyfrowej nie jest możliwe, podmiot publiczny może zaproponować alternatywny spos&oacute;b dostępu do informacji. W przypadku, gdy podmiot publiczny odm&oacute;wi realizacji żądania zapewnienia dostępności lub alternatywnego sposobu dostępu do informacji, wnoszący żądanie możne złożyć skargę w sprawie zapewniana dostępności cyfrowej strony internetowej, aplikacji mobilnej lub elementu strony internetowej, lub aplikacji mobilnej. Po wyczerpaniu wskazanej wyżej procedury można także złożyć wniosek do Rzecznika Praw Obywatelskich.</p>
      <h2>Informacje uzupełniające</h2>
      <p>Data publikacji strony internetowej: XII 2017</p>
      <p>Data ostatniej istotnej aktualizacji: 2020-10-31.</p>
      <h3>Dane teleadresowe podmiotu publicznego:</h3>
      <p>Ul. Wrocławska 2<br /> 56-500 Syc&oacute;w</p>
      <p>Gł&oacute;wny numer Samodzielnego Publicznego Zakładu Opieki Zdrowotnej: 62&nbsp;785 -51- 88</p>
      <p>&nbsp;</p>
      <p>E-mail:&nbsp;sekretariat@spzozprzychodnia.pl</p>
      <p>Strona internetowa: https://spzozprzychodnia.pl&nbsp;</p>
      <h3>&nbsp;</h3>
      <h3><strong>Skr&oacute;ty klawiszowe:</strong></h3>
      <p>Na stronie internetowej można używać standardowych skr&oacute;t&oacute;w klawiaturowych przeglądarki:</p>
      <ul>
        <li>TAB &ndash; przejście do kolejnego elementu</li>
        <li>SHIFT + TAB &ndash; przejście do poprzedniego elementu</li>
      </ul>
      <p>Oraz indywidualnie przygotowanych:</p>
      <ul>
        <li>Lewy Alt + S &ndash; przejście do paska wyszukiwania</li>
        <li>Lewy Alt + O &ndash; przejście do MENU</li>
      </ul>
      <h3><strong>&nbsp;</strong></h3>
      <h3><strong>Strona posiada następujące ułatwienia dla os&oacute;b z niepełnosprawnościami:</strong></h3>
      <ul>
        <li>podwyższony kontrast (jasne tło, czarne i granatowe litery),</li>
        <li>możliwość powiększenia wielkości liter na stronie za pomocą narzędzi przeglądarki,</li>
        <li>możliwość podkreślania link&oacute;w,</li>
        <li>odpowiednia struktura nagł&oacute;wkowa,</li>
        <li>wszystkie grafiki i zdjęcia użyte na stronie posiadają tekst alternatywny,</li>
        <li>strona nie posiada żadnych animowanych treści, kt&oacute;re mogłyby wytrącać z r&oacute;wnowagi osoby niepełnosprawne,</li>
        <li>teksty na stronie zostały sformatowane w spos&oacute;b czytelny dla os&oacute;b niepełnosprawnych.</li>
      </ul>
    </div>
  )
}