import CallbacksStateTypes from "./callbacksState.types";

export const setIsGlobalToastFailedToFetchActive = (isActive: boolean) => {
  return {
    type: CallbacksStateTypes.TOAST_FAILEDTOFETCH_ACTIVATED,
    payload: isActive
  };
};

export const hideSlideOverlay = () => {
  return {
    type: CallbacksStateTypes.HIDE_SLIDE_OVERLAY_SUCCESS
  }
}

export const showSlideOverlay = (redirectTo: string) => {
  return {
    type: CallbacksStateTypes.SHOW_SLIDE_OVERLAY_SUCCESS,
    payload: redirectTo
  };
};

export const setNavbarBar = (isActive: boolean) => {
  return {
    type: CallbacksStateTypes.SET_NAVBARBAR,
    payload: isActive

  }
}


export const setNavbarOpen = (isOpen: boolean) => {
  return {
    type: CallbacksStateTypes.SET_NAVBAROPEN,
    payload: isOpen

  }
}


export const setSearchPhrase = (searchPhrase: string) => {
  return {
    type: CallbacksStateTypes.SET_SEARCHPHRASE,
    payload: searchPhrase
  }
}

