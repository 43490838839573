export const vaccinations = [
    {
        name:'WZW A',
        products: [
            {name:'Havrix Adult', price: "300zł"}, {name:'Havrix 720 junior', price: "160 zł"},
        ]
    },
    {
        name:'WZW A i B',
        products: [
            {name:'Twinrix', price: "300 zł"}
        ]
    },
    {
        name:'Pneumokoki',
        products: [
            {name:'Prevenar', price: "300 zł"},
        ]
    },
    {
        name:'Meningokoki grupy B',
        products: [
            {name:'Bexsero', price: "450 zł"}
        ]
    },
    {
        name:'Ospa wietrzna',
        products: [
            {name:'Varilrix', price: "300 zł"}
        ]
    },
    {
        name:'5 w 1',
        products: [
            {name:'Inf + ipv + Hib', price: "200 zł"},
            {name:'Pentaxim', price: "200 zł"},
            {name:'Boostrix', price: "140 zł"},
            {name:'Boostrix polio', price: "150 zł"},
        ]
    },
    {
        name:'6 w 1',
        products: [
            {name:'Inf. hexa', price: "300 zł"},
            {name:'Hexacima', price: "250 zł"}
        ]
    },
]

export const dentists = [{
    name:'Wypełnianie materiałem światłoutwardzalnym',
        products: [
            {name:'Druga klasa ubytku (średnia)', price: "100 zł"},
            {name:'Trzecia klasa ubytku (MOD – duże)', price: "120 zł"},
            {name:'Odbudowa zęba', price: "170 zł"},
            {name:'Scaling (usuwanie złogów nazębnych)', price: "150 zł"}
        ]
    },
    {
        name:'Leczenie kanałowe',
            products: [
                {name:'Wypełnienie jednego kanału', price: "120 zł"},
                {name:'Wypełnienie dwóch kanałów (przedtrzonowe)', price: "170 zł"},
                {name:'Wypełnienie trzech kanałów (trzonowce)', price: "300 zł"}
            ]
    },
]

export const orthodontics = [
    {
        name:'ŚWIADCZENIE ORTODONTYCZNE',
            products: [
                {name:'Wycisk jednej szczęki dla diagnozy, planowania i kontroli, jako samodzielne postępowanie', price: "80 zł"},
                {name:'Korekcyjne szlifowanie zębów', price: "50 zł"},
                {name:'Wykonanie zgryzu konstrukcyjnego jako czynność odrębna', price: "50 zł"},
                {name:'Utrzymywacz przestrzeni jako samodzielne postępowanie', price: "180 zł"},   
                {name:'Środki do prowadzenia pojedynczego zęba do łuku po chirurgicznym jego odsłonięciu – z wyłączeniem aparatów stałych', price: "200 zł"},   
                {name:'Leczenie aparatem ortodontycznym, ruchomym, jednoszczękowym', price: "600 zł"}, 
                {name:'Leczenie aparatem ortodontycznym, ruchomym, dwuszczytowym', price: "700 zł"}, 
                {name:'Kontrola przebiegu leczenia z aparatem ruchomym', price: "80 zł"},
                {name:'Naprawa aparatu ruchomego z wyciskiem', price: "100 zł"},
                {name:'Rekonstrukcja aparatu ruchomego przy pacjencie w niewielkim zakresie', price: "55 zł"},
                {name:'Proteza dziecięca częściowa', price: "400 zł"},
                {name:'Proteza dziecięca całkowita', price: "600 zł"},
                {name:'Badanie specjalistyczne', price: "50 zł"},
            ]
    },
];

export const medicalServices = [
    {
        name:'USŁUGI MEDYCZNE',
            products: [
                {name:'Porada lekarska POZ', price: "100 zł"},
                {name:'Konsultacje specjalistyczne (laryngolog, neurolog, okulista, dermatolog, ginekolog, psychiatra, reumatolog)', price: "100 zł"},
                {name:'Wydanie zaświadczenia lekarskiego - internista, ginekolog, psycholog', price: "30 zł"},

                {name:'Wydanie zaświadczenia zaświadczenia sanitarno-epidemiologicznego', price: "40 zł"},   
                {name:'Iniekcja domięśniowa', price: "25 zł"},   
                {name:'Iniekcja dożylna', price: "30 zł"}, 
                {name:'Iniekcja podskórna', price: "20 zł"}, 
                {name:'Podłączenie kroplówki', price: "20 zł"},
                {name:'Zabieg krioterapii', price: "150 zł"},
                {name:'EKG', price: "40 zł"},
                // {name:'Punkcja diagnostyczna', price: "30 zł"},
                // {name:'Punkcja jamy stawowej', price: "100 zł"},
                // {name:'Wprowadzenie cewnika', price: "25 zł"},
                {name:'Opatrunek prosty', price: "20 zł"},
                {name:'Opatrunek złożony', price: "30 zł"},
                {name:'Zdjęcie I szwu', price: "20 zł"},
                {name:'Zdjęcie II szwu i następne', price: "10 zł"},
                {name:'Badanie spirometryczne', price: "60 zł"},
                {name:'Badanie audiometryczne', price: "60 zł"},
                // {name:'Elektrokoagulacja', price: "220 zł"},
                {name:'Badanie cytologiczne', price: "40 zł"},
                {name:'USG', price: "100 zł"},
                // {name:'USG Tarczycy', price: "60 zł"},
                // {name:'USG Ginekologiczno-Położnicze', price: "60 zł"},
                {name:'Badania wprofilaktyczne (stępne, okresowe, kontrolne)', price: "80 zł"},
                {name:'Poziom cukru', price: "10 zł"},
                {name:'Wydanie zaświadczenia lekarskiego dla celów sanit.-epidem.', price: "40 zł"},
                {name:'Aktualizacja pracowniczej książeczki zdrowia dla celów sanit.-epidem.', price: "40 zł"},
                // {name:'Badania profilaktyczne uczniów', price: "60 zł"},
                {name:'KTG', price: "40 zł"},
                {name:'Porada urologiczna z USG', price: "200 zł"},
                // {name:'TEST CRP', price: "10 zł"},
                // {name:'TEST NA PACIORKOWCA', price: "20 zł"},
                {name:'Dopłata LUX-MED', price: "zgodnie z umową"},
                {name:'Test w kierunku wykrywania antygenu Sars-CoV-2', price: "100,00 zł"},
















            ]
    },
]