/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import "./download-block.scss";

interface Props {
  img: string;
  title: string;
  to: string;
  size: string;
  label?: string;
}

const DownloadBlock: React.FC<Props> = (props: Props) => {
  const { title, img, to, size, label } = props;
  return (
    <>
      <section className="download-block">
        <div className="download-block__icon">
          <img aria-hidden={true} src={img} alt={title} />
        </div>
        <div className="download-block__content">
          <div className="download-block__title">{title}</div>
          <a href={to} target="_blank" rel="noopener noreferrer" className="download-block__downloadBtn" aria-label={label?label:""}>
            <div className="btn">POBIERZ{size ? ` (${size})` : ""}</div>
          </a>
        </div>
      </section>
    </>
  );
};

export default DownloadBlock;
