import { RoutePath } from "../../../route-paths";

export const searchData = [
  {
    to: RoutePath.BASICHEALTHCARE,
    title: "Podstawowa opieka zdrowotna",
    keywords: "podstawowa opieka zdrowotna internista pediatra lekarz poz"
  },
  {
    to: RoutePath.NEWS,
    title: "Aktualności",
    keywords: "aktualności aktualnosci newsy informacja aktualne"
  },
  {
    to: RoutePath.COVIDINFO,
    title: "Koronawirus (COVID-19) - informacje",
    keywords: "wirus koronawirus covid sars-cov2 cov2 sars pandemia"
  },
  {
    to: RoutePath.OCHRONADANYCHOSOBOWYCHPAGE,
    title: "Ochrona danych osobowych | SPZOZ Przychodnia Syców",
    keywords: "rodo RODO dane osobowe przechowywanie danych klauzule informacyjne realizacja praw poradniki"
  },
  {
    to: RoutePath.ROZPOZADZENIEMINISTRAZDROWIAPAGE,
    title: "Rozporządzenie Ministra Zdrowia | SPZOZ Przychodnia Syców",
    keywords: "rozporządzenie ministra zdrowia rozporzadzenie"
  },
  {
    to: RoutePath.INFORMACJEDLAPACJENTAPAGE,
    title: "Informacje dla pacjenta | SPZOZ Przychodnia Syców",
    keywords: "informacje dla pacjenta świadczenie swiadczenie usg badanie przygotowanie ktg KTG USG upowaznienie upowaznienie pliki do pobrania"
  },
  {
    to: RoutePath.LAWINFO,
    title: "Informacje prawne | SPZOZ Przychodnia Syców",
    keywords: "informacje prawne karta praw pacjenta skargi wnioski"
  },
  {
    to: RoutePath.CONTACT,
    title: "Kontakt | SPZOZ Przychodnia Syców",
    keywords: "kontakt sekretariat telefon numer email wiadomość wiadomosc sekretariat dyrektor poradnia dziecięca dziecieca gabinet zabiegowy poradnia dermatologiczna rum księgowa główna ksiegowa glowna kadry płace place"
  },
  {
    to: RoutePath.DYZURYAPTEK,
    title: "Dyżury aptek | SPZOZ Przychodnia Syców",
    keywords: "dyżury dyzury apteka aptek kiedy otwarte styczeń styczen luty marzec kwiecień kwiecien maj czerwiec lipiec sierpień sierpien wrzesień wrzesien październik pazdziernik listopad grudzień grudzien"
  },
  {
    to: RoutePath.SKARGIIWNIOSKI,
    title: "Skargi i Wnioski | SPZOZ Przychodnia Syców",
    keywords: "skargi wnioski skarga"
  },
  {
    to: RoutePath.KARTAPRAWPACJENTA,
    title: "Karta praw pacjenta | SPZOZ Przychodnia Syców",
    keywords: "karta praw pacjenta prawo pacjent jakie prawa ma pacjent jakie mam prawa"
  },
  {
    to: RoutePath.PRESCRIPTIONS,
    title: "Wniosek o przepisanie recepty | SPZOZ Przychodnia Syców",
    keywords: "recepta erecepta przepisanie leków lekow leki stałe stale wniosek"
  },
  {
    to: RoutePath.SKIEROWANIENABADANIA,
    title: "Wniosek o skierowanie do specjalisty | SPZOZ Przychodnia Syców",
    keywords: "skierowanie na badania skierowanie do specjalisty wniosek skierowania badanie"
  },
  {
    to: RoutePath.BASICHEALTHCARE,
    title: "Lekarze POZ | SPZOZ Przychodnia Syców",
    keywords: "podstawowa opieka zdrowotna lekarze pierwszego kontaktu internista pediatra"
  },
  {
    to: RoutePath.PUBLICTENDERS,
    title: "Przetargi | SPZOZ Przychodnia Syców",
    keywords: "zamówienia zamowienia publiczne przetarg przetargi"
  },
  {
    to: RoutePath.SPECIALISTCLINICS,
    title: "Poradnie specjalistyczne | SPZOZ Przychodnia Syców'",
    keywords: "poradnie specjalistyczne okulista ortodonta ginekolog neurolog stomatolog psycholog psychiatra reumatolog dermatolog medycyna pracy"
  },
  {
    to: RoutePath.STRADOMIAWIERZCHNIA,
    title: "Wiejski Ośrodek Zdrowia Stradomia Wierzchnia | SPZOZ Przychodnia Syców'",
    keywords: "wiejski ośrodek osrodek zdrowia stradomia wierzchnia przychodnia"
  },
  {
    to: RoutePath.TREATMENTOFFICE,
    title: "Gabinet zabiegowy | SPZOZ Przychodnia Syców'",
    keywords: "gabinet zabiegowy"
  },
  {
    to: RoutePath.PRIVOFFICES,
    title: "Gabinety prywatne | SPZOZ Przychodnia Syców'",
    keywords: "gabinet prywatny gabinety prywatne"
  },
  // {
  //   to: RoutePath.GABINETINFEKCYJNY,
  //   title: "Gabinet infekcyjny | SPZOZ Przychodnia Syców'",
  //   keywords: "gabinet infekcyjny infekcja covid koronawirus sarscov2 covid19"
  // },
  {
    to: RoutePath.PRINCING,
    title: "Cennik usług | SPZOZ Przychodnia Syców'",
    keywords: "cennik usług uslug cenniki koszty badań badan koszty szczepień szczepien koszt szczepionka stomatolog usług uslug medycznych szybki test"
  },
]