import React from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { selectContrastState } from "../../../redux/contrastState/contrastState.selectors";
import { minusSizeFonts, plusSizeFonts } from "../../../redux/fontSizeState/fontSizeState.actions";
import { selectFontSizeState } from "../../../redux/fontSizeState/fontSizeState.selectors";
import "./font-size-changer.scss";
import MinusImg from "./minus.svg";
import PlusImg from "./plus.svg";

interface Props {

}

const FontSizeChanger: React.FC<Props> = () => {
  const theme = useSelector(selectContrastState);
  const fontSize = useSelector(selectFontSizeState);
  const dispatch = useDispatch();

  const handleKeyDownMinus = (e: any) => {
    if (e.key === 'Enter') dispatch(minusSizeFonts()) 
  }

  const handleKeyDownPlus = (e: any) => {
    if (e.key === 'Enter') dispatch(plusSizeFonts()) 
  }
  

  return (
    <>
      <Helmet htmlAttributes={{ class: `theme-${theme} font-size-${fontSize}` }} />

      <div className="font-size-changer">
        <div className="changer-btn changer-btn--minus" onClick={() => dispatch(minusSizeFonts())} onKeyDown={handleKeyDownMinus} tabIndex={0} >
          <img src={MinusImg} alt="Zmniejsz czcionkę" />
        </div>
        <div className="changer-btn changer-btn--plus" onClick={() => dispatch(plusSizeFonts())} onKeyDown={handleKeyDownPlus} tabIndex={0} >
          <img src={PlusImg} alt="Zwiększ czcionkę" />
        </div>
      </div>
    </>
  )

}

export default FontSizeChanger;