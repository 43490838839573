import React from "react";
import "./nocna-opieka-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import CollapsibleBlock from "../../components/collapsible-block/collapsible-block";
import CollapsibleBlocksWrapper from "../../components/collapsible-block/collapsible-blocks-wrapper";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import TpkImg from "./assets/tpk2-min.jpg";

const NocnaOpiekaPage: React.FC<any> = () => {

  return (
    <PageWrapper className="nocna-opieka-page">
      <Helmet>
        <title>{'Nocna i Świąteczna Opieka Zdrowotna | SPZOZ Przychodnia Syców'}</title>
      </Helmet>

      <section className="pageWrapper__section">
        <Container>
          <MainHeader>NOCNA I ŚWIĄTECZNA OPIEKA ZDROWOTNA</MainHeader>
          <StandardTextBlock>
          <h1>Jeśli nagle zachorujesz lub stan Twojego zdrowia nagle się pogorszy, możesz udać się po pomoc do dowolnego punktu nocnej i świątecznej opieki zdrowotnej. Pamiętaj, że nie obowiązuje rejonizacja.</h1>
          <br />
          <p>Przychodnie podstawowej opieki zdrowotnej (POZ) nie pełnią dyżurów całodobowych. W noce i w dni ustawowo wolne od pracy ich rolę przejmują przychodnie nocnej i świątecznej opieki zdrowotnej, w których możesz otrzymać pomoc w godzinach od 18:00 do 8:00 dnia następnego oraz całodobowo w dni ustawowo wolne od pracy.</p>
          <br />
          <br />
          </StandardTextBlock>
          <CollapsibleBlocksWrapper>

          {/* <CollapsibleBlock
              title="Teleplatforma Pierwszego Kontaktu - Twoja pomoc medyczna w godzinach wieczornych, w weekendy i święta"
              isSplited={true}
            >
              <StandardTextBlock>
              <p>Narodowy Funduszu Zdrowia uruchomił <strong>Teleplatformę Pierwszego Kontaktu (TPK)</strong>. Dzwoniąc pod bezpłatny numer TPK – <a aria-label="Pod numerem telefonu 800 137 200" href="tel:800137200">800 137 200</a> –  pacjenci otrzymają niezbędną pomoc medyczną poza godzinami pracy lekarzy rodzinnych, w weekendy oraz święta. Z platformy mogą korzystać również osoby niesłyszące. Porady udzielane są w kilku językach.</p>
              <br />
              <p>
              Każdy z nas potrzebował pomocy lekarskiej w nocy lub w dni wolne od pracy, gdy przychodnie podstawowej opieki zdrowotnej są nieczynne. Teraz otrzymanie takiej pomocy jest łatwiejsze niż dotychczas, dodatkowo bez wychodzenia z domu.
              </p>
              <p>
              Udostępniona przez Narodowy Fundusz Zdrowia platforma jest miejscem pierwszego kontaktu dla pacjenta, w sytuacji nagłego zachorowania lub konieczności wystawienia np. e-recepty, gdy poradnie POZ już zakończyły pracę oraz w weekendy i święta. Porad medycznych udzielają profesjonalni medycy – pielęgniarki lub położne oraz lekarze. Aby uzyskać pomoc wystarczy mieć telefon i połączyć się z bezpłatnym numerem TPK.
              </p>
              <br />
              <p>
             <i>– Otwieramy nowy rozdział w rozwoju telemedycyny w Polsce – podkreśla Filip Nowak, p.o. prezesa Narodowego Funduszu Zdrowia.</i>
              </p>
              <br />
              <p>
                <strong>Jeden numer dla całej Polski</strong>
              </p>
              <p>
              Pod numerem <a aria-label="Pod numerem telefonu 800 137 200" href="tel:800137200">800 137 200</a> dyżurują pielęgniarki lub położne i lekarze gotowi do udzielenia pomocy dzwoniącym pacjentom.
              </p>
              <p>
              Połączenie jest <strong>bezpłatne</strong> i może być wykonane tylko z Polski, ponieważ platforma jest przeznaczona tylko dla osób ubezpieczonych i mieszkających w naszym kraju.
              </p>
              <br />
              <p>
                <strong>Pomoc medyczna w nocy, w weekendy i święta? Otrzymasz ją w TPK</strong>
              </p>
              <p>
              Telefony od pacjentów odbierane są od poniedziałku do piątku, w godzinach od 18.00 do 8.00 rano kolejnego dnia, czyli po godzinach pracy poradni POZ.
              </p>
              <p>
              Platforma działa również całodobowo w sobotę, niedzielę i święta. 
              </p>
              <p>
              Obsługuje połączenia w języku polskim oraz w kilku językach obcych: angielskim, rosyjskim i ukraińskim.
              </p>
              <br />
              <p>
                <strong>Zadzwoń, jeśli potrzebujesz nagłej pomocy medycznej, konsultacji, e-recepty lub e-skierowania</strong>
              </p>
              <p>
              TPK działa na podobnych zasadach do nocnej i świątecznej opieki zdrowotnej, z tą różnicą, że konsultacje udzielane są przez telefon. Jeśli poradnia lekarza rodzinnego jest już zamknięta, a stan zdrowia budzi Twój niepokój, wtedy możesz skorzystać ze zdalnej porady z pielęgniarką lub położną, bądź z lekarzem.
              </p>
              <p>
              Podczas połączenia, w razie potrzeby, personel medyczny może wystawić e-receptę, e-skierowanie lub e-zwolnienie. Wszystko cyfrowo i wygodnie.
              </p>
              <p>
              Jeśli konsultacja telefoniczna okaże się niewystarczająca, wtedy personel medyczny zaleci:
              <ul>
                <li>wizytę w najbliższej stacjonarnej placówce nocnej i świątecznej opieki zdrowotnej (NiŚOZ)</li>
                <li>kontakt ze swoim lekarzem POZ (w godzinach pracy POZ: 8.00 - 18.00, od poniedziałku do piątku)</li>
                <li>a w przypadku stwierdzenia stanu nagłego zagrożenia zdrowia i życia, wezwanie zespołu ratownictwa medycznego.</li>
              </ul>
              </p>
              <br />
              <p>
                <i>– Dzięki teleplatformie kontakt z lekarzem, pielęgniarką, bądź położną w stanach nagłego zachorowania poza godzinami pracy POZ będzie znacznie łatwiejszy. Bez wychodzenia z domu połączymy się bezpłatnie z personelem medycznym i otrzymamy niezbędną pomoc, szczegółowo skonsultujemy nasze dolegliwości, otrzymamy e-receptę, e-skierowanie lub e-zwolnienie – zaznacza <strong>Filip Nowak,</strong> p.o. prezesa NFZ.</i>
              </p>
              <br />
              <p>
                <strong>Jak wygląda rozmowa?</strong>
              </p>
              <p>
              Po połączeniu, pacjent wysłucha krótki komunikat, informujący o dodzwonieniu się na Teleplatformę Pierwszego Kontaktu. Po odsłuchaniu komunikatu, pacjent będzie mógł wybrać język, w którym będzie udzielana konsultacja.
              </p>
              <p>
              W pierwszej kolejności z pacjentem połączy się pielęgniarka lub położna. Po sprawdzeniu danych pacjenta pod kątem uprawnień do świadczeń opieki zdrowotnej i zarejestrowaniu zgłoszenia, pielęgniarka lub położna przeprowadzi wywiad niezbędny do oceny stanu zdrowia pacjenta. Podczas połączenia pacjent może liczyć na profesjonalną konsultację, w czasie której pielęgniarka lub położna może wystawić e-receptę oraz udzielić porad do dalszego sposobu postępowania. W stanach nagłych, które zagrażają życiu i zdrowiu, zaleci wezwanie zespołu ratownictwa medycznego. 
              </p>
              <p>
              Jeśli pielęgniarka lub położna uzna, że pacjent potrzebuje dodatkowej konsultacji, wówczas przekieruje pacjenta do lekarza, podczas tego samego połączenia.
              </p>
              <p>
              Konsultacja lekarska rozpoczyna się od uzupełnienia wywiadu przeprowadzonego przez pielęgniarkę lub położną. W czasie rozmowy z lekarzem, po ocenie stanu zdrowia, pacjent może otrzymać e-receptę, e-skierowanie lub e-zwolnienie. Gdy będzie potrzebna pilnej interwencji medycznej, lekarz zaleci wezwanie do pacjenta zespołu ratownictwa medycznego. Może także skierować pacjenta do najbliższej stacjonarnej placówki nocnej i świątecznej opieki zdrowotnej, jeśli pacjent będzie wymagał osobistego kontaktu z lekarzem. 
              </p>
              <br />
              <p>
                <strong>Platforma jest dostępna dla osób niesłyszących</strong>
              </p>
              <p>
              Ze zdalnej konsultacji z pielęgniarką, położną lub lekarzem mogą korzystać także pacjenci niesłyszący. Specjalnie z myślą o nich, teleplatforma oferuje udzielanie porad przez specjalny wideoczat. Komunikacja odbywa się w języku migowym, przy wsparciu tłumacza Polskiego Języka Migowego.  
              </p>
              <br />
              <p>
              <strong>Dowiedz się więcej i w razie nagłego zachorowania w nocy, weekendy lub święto, wybierz Teleplatformę Pierwszego Kontaktu</strong>
              </p>
              <br />
              <p>
              <u>Zadzwoń pod bezpłatny numer</u>  <a aria-label="Pod numerem telefonu 800 137 200" href="tel:800137200">800 137 200</a>
              </p>
              <img src={TpkImg} alt="Gdy dziecko trafia do szpitala, a rodzic nie wie do którego" style={{ maxWidth: 600 }} />
              </StandardTextBlock>
            </CollapsibleBlock> */}

          <CollapsibleBlock
              title="Kiedy korzystać z nocnej i świątecznej opieki zdrowotnej?"
              isSplited={true}
            >
              <StandardTextBlock>
              <p>Skorzystaj z niej, jeśli Ty lub Twoje dziecko źle się czujecie i obawiasz się, że stan zdrowia pogorszy się do następnego dnia. Ale nie ma zagrożenia życia. Udaj się do najbliższego punktu nocnej i świątecznej opieki zdrowotnej. W uzasadnionych medycznie przypadkach lekarz może zdecydować się na wizytę domową. Jeśli jednak Ty lub Twoje dziecko czujecie się źle, ale stan jest stabilny, poczekaj do następnego dnia. Zwróć się po pomoc do swojego lekarza POZ.</p>
              </StandardTextBlock>
            </CollapsibleBlock>

          <CollapsibleBlock
              title="Zasady postępowania w przypadku zgonu"
              isSplited={true}
            >
              <StandardTextBlock>
                <p>
                  Jeżeli nastąpi śmierć osoby bliskiej  w domu w sobotę i niedzielę lub w  dni ustawowo  wolne od pracy należy zadzwonić do Nocnej i Świątecznej Opieki Zdrowotnej na numery telefonów : <a aria-label="Pod numerem telefonu 71 77 67 400" href="tel:717767400">71 77 67 400</a> oraz <a aria-label="Pod numerem telefonu 71 77 67 337" href="tel:717767337">71 77 67 337</a>. Lekarz  stwierdza zgon i wystawia kartę informacyjną.
                </p>
              </StandardTextBlock>
            </CollapsibleBlock>
          </CollapsibleBlocksWrapper>
          <br />
          <br />
          <StandardTextBlock>
              <h1>Gdzie szukać pomocy?</h1>
              <br />
              <h2><strong>POWIATOWY ZESPÓŁ SZPITALI W OLEŚNICY</strong></h2>
              <p>ŚWIADCZENIA NOCNEJ I ŚWIĄTECZNEJ OPIEKI ZDROWOTNEJ </p>
              <p>Armii Krajowej 1</p>
              <p>56-400 OLEŚNICA</p>
              <p>Kontakt z placówką</p>
              <p><a aria-label="Pod numerem telefonu 71 77 67 337" href="717767337">71 77 67 337</a></p>
              <br />
              <br />
              <p>Powiatowy Zespół Szpitali w Oleśnicy</p>
              <p>Szpital ul. Oleśnicka 25 w Sycowie</p>
              <p>Kontakt z placówką</p>
              <p><a aria-label="Pod numerem telefonu 71 77 67 400" href="71767400">71 77 67 400</a></p>
              <br />
              <br />
              <p><strong>W piątek od godziny 18:00 do 08:00 w poniedziałek i  od godziny 18:00 dnia poprzedzającego dzień wolny od pracy do godziny 08:00 dnia po okresie świątecznym.</strong></p>
            </StandardTextBlock>
        </Container>
      </section>
    </PageWrapper>
  )
}

export default NocnaOpiekaPage;