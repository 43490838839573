import React from "react";
import "./standard-text-block.scss";

interface Props {
  children: React.ReactNode,
  className?: string
}

const StandardTextBlock: React.FC<Props> = (props: Props) => {
  const { children, className } = props;

  return (
    <div className={`standard-text-block${className?" " + className:""}`}>
      {children}
    </div>
  )
}

export default StandardTextBlock;
