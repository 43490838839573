import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cookiePolicyPopupReducer from './cookiePolicyPopup/cookiePolicyPopup.reducer';

import callbacksStateReducer from './callbacksState/callbacksState.reducer';
import fontSizeStateReducer from './fontSizeState/fontSizeState.reducer';
import contrastStateReducer from './contrastState/contrastState.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cookiePolicyPopup', 'fontSizeState', 'contrastState']
}

const rootReducer = combineReducers({
  cookiePolicyPopup: cookiePolicyPopupReducer,
  callbacksState: callbacksStateReducer,
  fontSizeState: fontSizeStateReducer,
  contrastState: contrastStateReducer
})

export default persistReducer(persistConfig, rootReducer);