/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import TopLink from "../top-link/top-link";
import "./news-tile.scss";

interface Props {
  title: string;
  children: React.ReactNode;
  img: string;
  to?: string;
  isClassicLink?: boolean;
  alt?: string;
  lang?: string;
}

const NewsTile: React.FC<Props> = (props: Props) => {
  const { title, children, img, to, isClassicLink, alt, lang} = props;
  return (
    <div lang={lang ? lang : ""} className="news-tile">
      {to ? (
        isClassicLink ? (
          <>
            <a
              href={to}
              rel="noopener noreferrer"
              className="topLinkWrapper"
            >
            {img?
              <img className={"news-tile__img no-invert"} src={img} alt={alt} />
            :null}


            </a>
            <div className="news-tile__title" tabIndex={0}>{title} </div>
            <div className="news-tile__description">{children}</div>
          </>
        ) : (<>
          <TopLink to={to}>
            <img className={"news-tile__img no-invert"} src={img} alt={alt} />
          </TopLink>
          <div className="news-tile__title" tabIndex={0}>{title}</div>
          <div className="news-tile__description">{children}</div>
        </>
        )
      ) : (
        <>
          <img className={"news-tile__img no-invert"} src={img} alt={alt} />
          <div className="news-tile__title" tabIndex={0}>{title}</div>
          <div className="news-tile__description">{children}</div>
        </>
      )}
    </div>
  );
};

export default NewsTile;
