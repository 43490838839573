import fontSizeStateTypes from './fontSizeState.types';

const INITIAL_STATE = {
  size: 10,
}

const fontSizeStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case fontSizeStateTypes.PLUS_SIZE_FONTS:
      return {
        ...state,
        size: state.size < 14 ? state.size + 1 : state.size
      }
    case fontSizeStateTypes.MINUS_SIZE_FONTS:
      return {
        ...state,
        size: state.size > 6 ? state.size - 1 : state.size
      }
    default: {
      return state;
    }
  }
}

export default fontSizeStateReducer;