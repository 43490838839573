import React, { useRef, useState } from "react";
import { handleScrollToSection } from "../../tools/handleScrollToSection";
import "./news-tile.scss";

interface Props {
  children: React.ReactNode;
  itemsPerPage?: number;
}

const NewsTileWrapper: React.FC<Props> = (props: Props) => {
  const { children, itemsPerPage } = props;
  let pagesTotalCount = itemsPerPage ? Math.ceil((React.Children.count(children) / itemsPerPage)) : undefined;
  const [currentPage, setcurrentPage] = useState(0)
  const ref = useRef<any>();

  const setcurrentPageHandles = (value: number) => {
    if (currentPage === value) return;
    handleScrollToSection(ref, 220, true);
    setTimeout(() => {
      setcurrentPage(value);
    }, 300);
  }
  return (
    <div className="news-tiles-wrapper" ref={ref}>
      {React.Children.map(children, (child, index: number) => {
        if (itemsPerPage) {
          let minVal = (currentPage * itemsPerPage);
          let maxVal = ((currentPage * itemsPerPage) + itemsPerPage);
          if (index >= minVal && index < maxVal) {
            return (
              child
            );
          }
        }
        else {
          return (
            child
          );
        }
      })}
      {itemsPerPage && itemsPerPage < React.Children.count(children) ?
        <div className="pagination">
          {(new Array(pagesTotalCount).fill(0)).map((value: number, index: number) => {
            return (
              <div key={index} className={`pageBtn${currentPage === index ? ' isActive' : ''}`} onClick={() => setcurrentPageHandles(index)}>
                {index}
              </div>
            )
          })}
        </div>
        : null}
    </div>
  )
}

export default NewsTileWrapper;