import React, { } from "react";
import './../rules-page/rules-page.scss'
import Helmet from 'react-helmet';
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";

const KartaPrawPacjentaPage: React.FC<any> = () => {
  return (

    <PageWrapper className="rules-page">
      <Helmet>
        <title>{'Karta praw pacjenta | SPZOZ Przychodnia Syców'}</title>
      </Helmet>
      <Container>
        <section className="pageWrapper__section">
          <MainHeader>
            Karta praw pacjenta
          </MainHeader>
          <div className="policy">
            <p>Samodzielnym Publicznych Zakładzie Opieki</p>
            <p>Zdrowotnej &#8220;Przychodnia&#8221; w Sycowie</p>
            <p>Podstawa prawna:</p>
            <p>1) konstytucja RP z dnia 2 kwietnia 1997 roku (Dz. U. nr 78, poz. 483),</p>
            <p>2) ustawa z dnia 15 kwietnia 2011 roku o działalności leczniczej: tekst jednolity &#8211; Dz. U. z dnia<br /> 15 lutego 2013 roku, poz. 217,</p>
            <p>3) ustawa z dnia 27 sierpnia 2004 roku o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych (Dz. U. nr 164, poz. 1027 ze zmianami),</p>
            <p>4) ustawa z dnia 6 listopada 2008 roku o prawach pacjenta i Rzeczniku Praw Pacjenta (Dz. U.<br />nr 52, poz. 417 z dnia 31 marca 2009 roku ze zmianami).</p>
            <p>5) ustawa z dnia 5 lipca 1996 roku o zawodach pielęgniarki i położnej (Dz. U. z 2011 roku,<br />nr 174, poz. 1039 ze zmianami),</p>
            <p>6) ustawa z dnia 5 grudnia 1996 roku o zawodzie lekarza (tekst jednolity: Dz. U. z 2011 roku<br />nr 277, poz. 1634 ze zmianami),</p>
            <p>7) ustawa z dnia 29 listopada 2000 roku &#8211; Prawo atomowe: tekst jednolity: Dz. U. z 2012 roku, poz. 264.</p>
            <h3>1. Prawa pacjenta wynikając z Konstytucji RP:</h3>
            <p>1) każdy ma prawo do ochrony zdrowia &#8211; art. 68, ust. 1,</p>
            <p>2) każdy obywatel ma prawo do równego dostępu do świadczeń opieki zdrowotnej finansowanych ze środków publicznych, na warunkach i w zakresie określonych w ustawie &#8211; art. 68 ust. 2.</p>
            <h3>2. Prawa pacjenta w SPZOZ &#8220;Przychodnia&#8221; w Sycowie, o których stanowi ustawa o działalności leczniczej:</h3>
            <p>1) pacjent zgłaszający się do danej jednostki organizacyjnej przychodni ma prawo do natychmiastowego udzielenia mu świadczeń zdrowotnych w przypadku zagrożenia życia lub zdrowia, niezależnie od okoliczności,</p>
            <p>2) pacjent ma prawo do:</p>
            <p>a) świadczeń zdrowotnych odpowiadających wymaganiom wiedzy medycznej, a w sytuacji ograniczonych możliwości udzielenia odpowiednich świadczeń &#8211; do korzystania z rzetelnej, opartej na kryteriach medycznych procedury ustalającej kolejność dostępu do świadczeń,</p>
            <p>b) udzielenia mu świadczeń zdrowotnych przez osoby uprawnione do ich udzielania,<br />w pomieszczeniach i przy zastosowaniu urządzeń odpowiadających określonym wymaganiom fachowym i sanitarnym,</p>
            <p>c) informacji o swoim stanie zdrowia,</p>
            <p>d) wyrażenia zgody albo odmowy na udzielenie mu określonych świadczeń zdrowotnych, po uzyskaniu odpowiedniej informacji,</p>
            <p>e) intymności i poszanowania godności w czasie udzielania świadczeń zdrowotnych,</p>
            <p>f) udostępniania mu dokumentacji medycznej lub wskazania innej osoby, której dokumentacja ta może być udostępniana,</p>
            <p>g) zapewnienia ochrony danych zawartych w dokumentacji medycznej dotyczącej jego osoby,</p>
            <p>h) dostępu do informacji o prawach pacjenta.</p>
            <h3>3. Prawa pacjenta określone w ustawie o zawodzie lekarza:</h3>
            <p>Pacjent ma prawo do:</p>
            <p>1) udzielania mu świadczeń przez lekarza, zgodnie ze wskazaniami aktualnej wiedzy medycznej, dostępnymi lekarzowi metodami i środkami zapobiegania, rozpoznawania leczenia chorób, zgodnie z zasadami etyki zawodowej oraz należytą starannością,</p>
            <p>2) uzyskania od lekarza pomocy lekarskiej w każdym przypadku, gdy zwłoka w jej udzieleniu mogłaby spowodować niebezpieczeństwo utraty życia, ciężkiego uszkodzenia ciała lub ciężkiego rozstroju zdrowia oraz w innych przypadkach nie cierpiących zwłoki,</p>
            <p>3) uzyskania od lekarza przystępnej informacji o swoim stanie zdrowia, rozpoznaniu, proponowanych oraz możliwych metodach diagnostycznych, leczniczych, dających się przewidzieć następstwach ich zastosowania albo zaniechania, wynikach leczenia oraz rokowaniu,</p>
            <p>4) zażądania od lekarza informacji o swoim stanie zdrowia i o rokowaniu wówczas, gdy sądzi, że lekarz ogranicza mu informację z uwagi na jego dobro,</p>
            <p>5) decydowania o osobach, którym lekarz może udzielać informacji, o których mowa w pkt. 3,</p>
            <p>6) wyrażenia żądania, aby lekarz nie udzielał mu informacji, o których mowa w pkt.3,</p>
            <p>7) nie wyrażenia zgody na przeprowadzenie przez lekarza badania lub udzielenia mu innego świadczenia zdrowotnego,</p>
            <p>8) wyrażenia zgody albo odmowy na wykonanie mu zabiegu operacyjnego albo zastosowania wobec niego metody leczenia lub diagnostyki stwarzającej podwyższone ryzyko, w celu podjęcia przez pacjenta decyzji, lekarz musi udzielić mu informacji, o której mowa w pkt. 3,</p>
            <p>9) uzyskania od lekarza informacji, jeżeli w trakcie wykonywania zabiegu, bądź stosowania określonej metody leczenia lub diagnostyki, w związku z wystąpieniem okoliczności stanowiących zagrożenie dla życia lub zdrowia pacjenta, lekarz dokonał zmian w ich zakresie,</p>
            <p>10) poszanowania przez lekarza jego intymności i godności osobistej,</p>
            <p>11) wyrażenia zgody albo odmowy na uczestniczenie przy udzielaniu mu świadczeń zdrowotnych osób innych niż personel medyczny niezbędny ze względu na rodzaj świadczeń,</p>
            <p>12) wnioskowania do lekarza o zasięgnięcie przez niego opinii właściwego lekarza specjalisty,</p>
            <p>13) dostatecznie wcześniejszego uprzedzenia go przez lekarza o zamiarze odstąpienia od jego leczenia i wskazania mu realnych możliwości uzyskania określonego świadczenia zdrowotnego u innego lekarza lub w zakładzie opieki zdrowotnej,</p>
            <p>14) zachowania w tajemnicy informacji związanych z nim, a uzyskanych przez lekarza w związku z wykonywaniem zawodu,</p>
            <p>15) uzyskania od lekarza informacji o niekorzystnych dla niego skutkach, w przypadku wyrażenia zgody na ujawnienie dotyczących go informacji, będących w posiadaniu lekarza,</p>
            <p>16) wyrażenia zgody lub odmowy na podawanie przez lekarza do publicznej wiadomości danych umożliwiających identyfikacje pacjenta,</p>
            <p>17) wyrażenia zgody lub odmowy na udział w eksperymencie medycznym po uprzednim poinformowaniu go przez lekarza o celach, sposobach i warunkach przeprowadzenia eksperymentu, spodziewanych korzyściach leczniczych lub poznawczych, ryzyku oraz o możliwościach cofnięcia zgody i odstąpienia od udziału w eksperymencie w każdym jego stadium,</p>
            <p>18) poinformowania go przez lekarza o niebezpieczeństwie dla zdrowia i życia jakie może spowodować natychmiastowe przerwanie eksperymentu,</p>
            <p>19) cofnięcia zgody na eksperyment medyczny w każdym stadium eksperymentu.</p>
            <h3>4. Prawa pacjenta określone w ustawie o zawodzie pielęgniarki i położnej:</h3>
            <p>Pacjent ma prawo do:</p>
            <p>1) udzielania mu świadczeń zdrowotnych przez pielęgniarkę, położną, posiadającą prawo wykonywania zawodu, zgodnie z aktualną wiedzą medyczną, dostępnymi metodami i środkami, zgodnie z zasadami etyki zawodowej oraz ze szczególną starannością,</p>
            <p>2) udzielenia mu pomocy przez pielęgniarkę, położną, zgodnie z posiadanymi przez nią kwalifikacjami, w każdym przypadku niebezpieczeństwa utraty przez niego życia lub poważnego uszczerbku na jego zdrowiu,</p>
            <p>3) uzyskania od pielęgniarki, położnej informacji o jego prawach,</p>
            <p>4) uzyskania od pielęgniarki, położnej informacji o swoim stanie zdrowia, w zakresie realizowanym przez pielęgniarkę, lub położną,</p>
            <p>5) zachowania w tajemnicy informacji związanych z nim, a uzyskanych przez pielęgniarkę, położną w związku z wykonywaniem zawodu,</p>
            <p>6) wyrażania zgody lub odmowy na podawanie przez pielęgniarkę, położną do publicznej widomości danych umożliwiających identyfikację pacjenta.</p>
            <h3>5. Prawa pacjenta określone w ustawie o ochronie zdrowia psychicznego:</h3>
            <p>Pacjent z zaburzeniami psychicznymi, chory psychicznie lub upośledzony umysłowo ma prawo do:</p>
            <p>1) bezpłatnych świadczeń zdrowotnych udzielanych mu przez publiczne podmioty psychiatrycznej opieki zdrowotnej,</p>
            <p>2) zapewnienia mu rodzaju metod postępowania leczniczego właściwych nie tylko z uwagi na cele zdrowotne, ale także ze względu na interesy oraz inne jego dobro osobiste i dążenie do jego poprawy zdrowia w sposób najmniej dla pacjenta uciążliwy,</p>
            <p>3) uprzedzenie go o zamiarze zastosowania wobec niego przymusu bezpośredniego, przy czym dopuszczenie zastosowania tego środka wobec pacjenta musi wynikać z ustawy,</p>
            <p>4) uprzedzenia o zamiarze przeprowadzenia badania psychiatrycznego bez jego zgody oraz podania przyczyn takiej decyzji, badanie takie może być przeprowadzone, jeżeli zachowanie pacjenta wskazuje na to, że z powodu zaburzeń psychicznych może zagrażać bezpośrednio własnemu życiu, albo życiu lub zdrowiu innych osób, bądź nie jest zdolny do zaspokojenia podstawowych potrzeb życiowych.</p>
            <h3>6. Prawa pacjenta określone w ustawie o pobieraniu i przeszczepianiu komórek, tkanek i narządów:</h3>
            <p>Pacjent ma prawo do:</p>
            <p>1) wyrażenia i cofnięcia w każdym czasie sprzeciwu na pobranie po jego śmierci komórek, tkanek lub narządów,</p>
            <p>2) dobrowolnego wyrażenia przed lekarzem zgody na pobieranie od niego za życia komórek, tkanek lub narządów w celu ich przeszczepienia określonemu biorcy, jeżeli ma pełna zdolność do czynności prawnych,</p>
            <p>3) ochrony danych osobowych dotyczących dawcy / biorcy przeszczepu i objęcia ich tajemnica,</p>
            <p>4) pacjent mający być biorcą ma prawo do umieszczenia go na liście osób oczekujących na przeszczepienie komórek, tkanek i narządów i być wybrany jako biorca w oparciu o kryteria medyczne.</p>
            <h3>7. Prawa pacjenta określone w ustawie o prawach pacjenta i Rzeczniku Praw Pacjenta:</h3>
            <p>1.Pacjent ma prawo do:</p>
            <p>1) tajemnicy informacji z nim związanych,</p>
            <p>2) wyrażenia zgody na udzielanie świadczeń zdrowotnych,</p>
            <p>3) poszanowania intymności i godności pacjenta,</p>
            <p>4) do dokumentacji medycznej,</p>
            <p>5) zgłoszenia sprzeciwu wobec opinii albo orzeczenia lekarza,</p>
            <p>6) poszanowania życia prywatnego i rodzinnego.</p>
            <p>2. Dokumentacja medyczna jest udostępniana pacjentowi:</p>
            <p>1) do wglądu w siedzibie jednostki organizacyjnej przychodni, w której udzielono świadczenia zdrowotnego,</p>
            <p>2) poprzez sporządzenie jej wyciągów, odpisów lub kopii za odpłatnością zgodnie z cennikiem przychodni, opracowanym na podstawie art. 28 ustawy z dnia 6 listopada 2008 roku o prawach pacjenta i Rzeczniku Praw Pacjenta: Dz. U. z 2009 roku, nr 52, poz. 417.</p>
            <br/>
            <br/>
            <p><em><span>* Wyrok TK w sprawie zakresu świadczeń zdrowotnych określonych w Konstytucji RP &#8211; </span></em></p>
            <p><em><span>„Nie jest rolą Konstytucji RP dokładne określenie zakresu świadczeń opieki zdrowotnej, jakie należą się wszystkim obywatelom. O tym decyduje ustawa.”</span></em></p>
            <p><span>&#8211; <em>Zgodnie z wyrokiem TK, zakład opieki zdrowotnej świadczy usługi tylko do poziomu finansowania (liczby porad) o którym mowa jest w umowach na świadczenia specjalistyczne, zdrowia psychicznego, stomatologiczne i profilaktyczne.. </em></span></p>
            <p><em><span>Ponad limity można realizować świadczenia zdrowotne tylko w przypadku zagrożenia życia.</span></em></p>
          </div>
        </section>
      </Container>
    </PageWrapper>
  )
}

export default KartaPrawPacjentaPage;