import React from "react";
import "./home-page.scss";
import Helmet from "react-helmet";
import { RoutePath } from "../../../route-paths";
import HeroImg from "../../assets/zdjecie-przychodnia.jpg";
import ExampleIcon from "../../assets/exampleIcon.svg";
import DoctorsIcon from "../../assets/home-info/ico_lekarzePOZ.svg";
import ClinicIcon from "../../assets/home-info/ico_poradnie_specjalistyczne.svg";
import PrivateOfficeIcon from "../../assets/home-info/ico_gabinety_prywatne.svg";
import TreatmentRoomsIcon from "../../assets/home-info/ico_gabinet_zabiegowy.svg";
//import InfectionRoomIcon from '../../assets/home-info/ico_gabinet_infekcyjny.svg';
import CovidIcon from "../../assets/home-info/ico_koronawirus.svg";
import NightCareIcon from "../../assets/home-info/ico_nocna_swiateczna_OZ.svg";
import PharmaciesIcon from "../../assets/home-info/ico_dyzur_aptek.svg";
import PriceListIcon from "../../assets/home-info/ico_cennik_uslug.svg";
import TendersIcon from "../../assets/home-info/ico_przetargi.svg";
import AvailabilityIcon from "../../assets/home-info/ico_dostepnosc.svg";
import DataProtectionIcon from "../../assets/home-info/ico_ochrona_danych.svg";
import StradomiaIcon from "../../assets/home-info/ico_Stradomia.svg";
import Container from "../../components/container/container";
import ShortInfoLink from "../../components/short-info-link/shortInfoLink";
import NewsTile from "../../components/news-tile/news-tile";
import NewsTileWrapper from "../../components/news-tile/news-tile-wrapper";
import LinkBtn from "../../components/link-btn/link-btn";
import PageWrapper from "../../components/page-wrapper/page-wrapper";

import { news_data } from "../news-page/news-data";
import PatientInfo from "../../components/patient-info/patient-info";
import PatientInfoCopy from "../../components/patient-info copy/patient-info-copy";
import Col from "../../components/grid/col";
import Row from "../../components/grid/row";
import Modal from "../../components/modal/modal";

const HomePage: React.FC<any> = () => {
  return (
    <PageWrapper className="home-page">
      <Helmet>
        <title>{"SPZOZ Przychodnia Syców"}</title>
      </Helmet>
      <section className="hero__section">
        <div className="hero__content">
          <h1 className="hero__title">PRZYCHODNIA W&nbsp;SYCOWIE</h1>
          <p className="hero__description">Samodzielny Publiczny Zakład Opieki Zdrowotnej</p>
        </div>
        <div className="hero__img no-invert" style={{ backgroundImage: `url("${HeroImg}")` }}></div>
      </section>
      <section className="patientInfoCopy__section">
        <Container>
          <PatientInfoCopy title="Informacja dla osób niedosłyszących" icon={ExampleIcon} to={RoutePath.INFORMACJEDLAPACJENTAPAGE} />
        </Container>
      </section>
      <section className={"shortLinks__section"}>
        <Container>
          <div className="row">
            <ShortInfoLink title="Lekarze POZ" desciption="Godziny przyjęć Internistów i Pediatrów" icon={DoctorsIcon} to={RoutePath.BASICHEALTHCARE} />
            <ShortInfoLink title="Poradnie specjalistyczne" desciption="Aktualne dni oraz godziny przyjęć pacjentów" icon={ClinicIcon} to={RoutePath.SPECIALISTCLINICS} />
            <ShortInfoLink title="Gabinety prywatne" desciption="Dni i godziny przyjęć gabinetów prywatnych" icon={PrivateOfficeIcon} to={RoutePath.PRIVOFFICES} />
            <ShortInfoLink title="Gabinet zabiegowy" desciption="Dni i godziny funkcjonowania gabinetu zabiegowego" icon={TreatmentRoomsIcon} to={RoutePath.TREATMENTOFFICE} />
            <ShortInfoLink title="Stradomia Wierzchnia" desciption="Dane kontaktowe Przychodni" icon={StradomiaIcon} to={RoutePath.STRADOMIAWIERZCHNIA} />
            {/* <ShortInfoLink title="Gabinet infekcyjny" desciption="Wyspecjalizowany gabinet infekcyjny" icon={InfectionRoomIcon} to={RoutePath.GABINETINFEKCYJNY} /> */}
            <ShortInfoLink title="Koronawirus" desciption="Nowe informacje o wirusie Sars-Cov-2" icon={CovidIcon} to={RoutePath.COVIDINFO} />
            <ShortInfoLink title="Nocna opieka zdrowotna" desciption="Nocna i Świąteczna opieka zdrowotna" icon={NightCareIcon} to={RoutePath.NOCNAOPIEKA} />
            <ShortInfoLink title="Dyżury aptek" desciption="Aktualny harmonogram aptek w naszej okolicy" icon={PharmaciesIcon} to={RoutePath.DYZURYAPTEK} />
            <ShortInfoLink title="Cennik usług" desciption="Aktualny cennik usług w Przychodni" icon={PriceListIcon} to={RoutePath.PRINCING} />
          </div>
        </Container>
      </section>
      <section className="patientInfo__section">
        <Container>
          <PatientInfo title="Informacje dla pacjenta" icon={ExampleIcon} to={RoutePath.INFORMACJEDLAPACJENTAPAGE} />
        </Container>
      </section>
      <section className="actionBar__section">
        <Container>
          <Row align="center" gap={32}>
            <Col size={[6, 6, 12, 12]} offset={[0, 0]}>
              <h2 className="actionBarTitle" tabIndex={0}>
                Wniosek o przepisanie leków stałych
              </h2>
              <LinkBtn to={RoutePath.PRESCRIPTIONS} color={"white"}>
                Złóż wniosek
              </LinkBtn>
            </Col>
            <Col size={[6, 6, 12, 12]} offset={[0, 0, 0]}>
              <h2 className="actionBarTitle" tabIndex={0}>
                Wniosek o skierowanie do specjalisty
              </h2>
              <LinkBtn to={RoutePath.SKIEROWANIENABADANIA} color={"white"}>
                Złóż wniosek
              </LinkBtn>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="news__section">
        <Container>
          <h2 className="news__section__subtitle" tabIndex={0}>
            Najnowsze aktualności
          </h2>
          <NewsTileWrapper>
            {news_data.slice(0, 6).map((x, index) => {
              return (
                <NewsTile lang={x.lang} key={index} title={x.title} img={x.img} isClassicLink={x.isClassicLink} alt={x.alt ? x.alt : ""} to={x.to}>
                  {x.content}
                </NewsTile>
              );
            })}
          </NewsTileWrapper>
          <div className="btnRow">
            <LinkBtn to={RoutePath.NEWS}>Więcej aktualności</LinkBtn>
          </div>
        </Container>
      </section>
      <section className="shortLinks__section__others">
        <Container>
          <div className="row">
            <ShortInfoLink title="Przetargi" desciption="Oferty oraz zapytania publiczne" icon={TendersIcon} to={RoutePath.PUBLICTENDERS} />
            <ShortInfoLink title="Dostępność" desciption="Karta praw pacjenta oraz skargi i wnioski" icon={AvailabilityIcon} to={RoutePath.LAWINFO} />
            <ShortInfoLink title="Ochrona danych" desciption="Informacje prawne o przetwarzaniu danych" icon={DataProtectionIcon} to={RoutePath.OCHRONADANYCHOSOBOWYCHPAGE} />
          </div>
        </Container>
      </section>
    </PageWrapper>
  );
};

export default HomePage;
