import { ToastsStore } from "react-toasts";
import { callApi } from "../api-call-service";
import { ApiMethodDto } from "../api-method-dto";
import { ApiPath } from "../api-path";
import { PrescriptionRequestDto } from "../../framework/pages/prescriptions-page/prescriptions-form-tools";

export class RecipeController {
  static async send(request: PrescriptionRequestDto): Promise<boolean> {
    return new Promise(async (resolve) => {
      let formData = new FormData();

      formData.append("firstName", request.customer.firstName);
      formData.append("lastName", request.customer.lastName);
      formData.append("pesel", request.customer.pesel);
      formData.append("street", request.customer.street);
      formData.append("city", request.customer.city);
      formData.append("postalCode", request.customer.postalCode);

      let listaLekow = `Nazwy leków z dawkowaniem: 
      `;
      for (let i = 0; i < request.prescriptions.length; i++) {
        const prescription = request.prescriptions[i];
        listaLekow =
          listaLekow +
          `
          Lek ${i + 1}, nazwa leku: ${prescription.name}, dawka: ${prescription.dose}, liczba tabletek w opakowaniu: ${prescription.pillsCountInPackage}, liczba opakowań: ${prescription.packagesCount}.

        `;
      }

      formData.append("message", listaLekow);

      formData.append("phone", request.customer.phone ? request.customer.phone : "brak");
      formData.append("email", request.customer.email ? request.customer.email : "brak");
      formData.append("isAcceptedNoProblems", request.customer.isAcceptedNoProblems ? "TAK" : "NIE");
      formData.append("isAcceptedRodo", request.customer.isAcceptedRodo ? "TAK" : "NIE");
      formData.append("isAcceptedSmsRecive", request.customer.isAcceptedSmsRecive ? "TAK" : "NIE");
      formData.append("isAcceptedSelfRecive", request.customer.isAcceptedSelfRecive ? "TAK" : "NIE");

      const options = {
        // body: {mail: email, message: message},
        body: formData,
        // headers: { "Access-Control-Allow-Origin": "*" },
        datatype: "FormData",
        method: "POST",
        expectedStatus: 200,
      } as ApiMethodDto;

      // alert(JSON.stringify(options));
      await callApi(options.method, ApiPath.send_recipe, options)
        .then((res) => {
          console.log(res);
          if (res.details.status === options.expectedStatus) {
            ToastsStore.success("Twój wniosek został wysłany.");
            resolve(true);
          } else {
            ToastsStore.error("Twój wniosek nie został wysłany.");
            resolve(false);
          }
        })
        .catch((error) => resolve(false));
      // });
    });
  }
  static async sendSkierowanie(request: PrescriptionRequestDto): Promise<boolean> {
    return new Promise(async (resolve) => {
      let formData = new FormData();

      formData.append("firstName", request.customer.firstName);
      formData.append("lastName", request.customer.lastName);
      formData.append("pesel", request.customer.pesel);
      formData.append("street", request.customer.street);
      formData.append("city", request.customer.city);
      formData.append("postalCode", request.customer.postalCode);
      formData.append("message", `Skierowanie do specjalisty: 
      ${request.message}`);

      formData.append("phone", request.customer.phone ? request.customer.phone : "brak");
      formData.append("email", request.customer.email ? request.customer.email : "brak");
      formData.append("isAcceptedNoProblems", request.customer.isAcceptedNoProblems ? "TAK" : "NIE");
      formData.append("isAcceptedRodo", request.customer.isAcceptedRodo ? "TAK" : "NIE");
      formData.append("isAcceptedSmsRecive", request.customer.isAcceptedSmsRecive ? "TAK" : "NIE");
      formData.append("isAcceptedSelfRecive", request.customer.isAcceptedSelfRecive ? "TAK" : "NIE");

      const options = {
        // body: {mail: email, message: message},
        body: formData,
        // headers: { "Access-Control-Allow-Origin": "*" },
        datatype: "FormData",
        method: "POST",
        expectedStatus: 200,
      } as ApiMethodDto;

      // alert(JSON.stringify(options));
      await callApi(options.method, ApiPath.send_recipe, options)
        .then((res) => {
          console.log(res);
          if (res.details.status === options.expectedStatus) {
            ToastsStore.success("Twój wniosek został wysłany.");
            resolve(true);
          } else {
            ToastsStore.error("Twój wniosek nie został wysłany.");
            resolve(false);
          }
        })
        .catch((error) => resolve(false));
      // });
    });
  }
}
