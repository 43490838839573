import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./navbar.scss";
import LogoImg from "./../../assets/logo.svg";
import { RoutePath } from "../../../route-paths";
import TopLink from "../top-link/top-link";
import { select_is_navbarBarActive, select_is_navbarOpenActive } from "../../redux/callbacksState/callbacksState.selectors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setNavbarOpen } from "../../redux/callbacksState/callbacksState.actions";
import MenuBtnIcon from "../../assets/menuBtn.svg";
import CloseBtnIcon from "../../assets/closeBtn.svg";
import SearchBar from "../search-bar/search-bar";
import { hideCookiePolicyPopup } from "../../redux/cookiePolicyPopup/cookiePolicyPopup.actions";
import FontSizeChanger from "./font-size-changer/font-size-changer";
import ContrastChanger from "./contrast-changer/contrast-changer";
import PjmLogo from "./pjm-logo/pjm-logo";
import SkipLink from "../skip-link/skip-link";

interface Props {
  isSubNavHidden?: boolean;
  children?: ReactNode;
}

const Navbar: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const firstLink = useRef<any>();

  const is_navbarBarActive = useSelector(select_is_navbarBarActive, shallowEqual);
  const is_navbarOpenActive = useSelector(select_is_navbarOpenActive, shallowEqual);

  const setNavExpanded = (isOpen: boolean) => {
    dispatch(setNavbarOpen(isOpen));
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);

    if (window.pageYOffset > 10) {
    } else {
    }
  };
  

  const handleKeypress = (e: KeyboardEvent) => {
    if (e.altKey && e.code === "KeyO") {
      if (is_navbarOpenActive === false) {
        setNavExpanded(true);
        firstLink.current!.children[0].focus();
      } else {      
        setNavExpanded(false);    
        firstLink.current!.children[0].blur();

      }
    }
    if (e.altKey && e.code === "KeyC") {
      dispatch(hideCookiePolicyPopup())
    }
    if(e.code === "Tab" && is_navbarOpenActive === true ){
      if(firstLink.current.lastElementChild === document.activeElement) {
        setNavExpanded(false) 
      }   
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeypress, { passive: true });

    return () => {    
      window.removeEventListener("keydown", handleKeypress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_navbarOpenActive]);


  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    // window.addEventListener('keypress', handleKeypress, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      // window.removeEventListener('keypress', handleKeypress);
    };
  }, []);

  return (
    <>
      <nav className={`navbarBarWrapper`}>
        {/*
        <div className={`yellowInfoBar${scrollPosition >= 40 ? " isHidden" : ""}`}>
          <div className="title">KORONAWIRUS: Szczepienie przeciw COVID-19</div>
          <TopLink to={RoutePath.SZCZEPIONKAINFO}>więcej&nbsp;informacji</TopLink>
        </div>
      */}
      <SkipLink id='navContent'> Przejdź do menu</SkipLink>
      <SkipLink id='mainContent'>Przejdź do treści</SkipLink>
      <SkipLink id='footerContent'>Przejdź do stopki</SkipLink>

        <div className={`navbarBar`}>
          <button title="Otwórz menu" className={`menuBtn${is_navbarOpenActive ? " isActive" : ""}${is_navbarBarActive ? " navbarbarActive" : ""}`} onClick={() =>{
              setNavExpanded(!is_navbarOpenActive)
              firstLink.current.firstElementChild.focus();
          }}>
            <>{is_navbarOpenActive ? <img src={CloseBtnIcon} className="closeBtn" alt="Zamknij menu" /> : <img src={MenuBtnIcon} alt="Otwórz menu" />} </>
          </button>
          <div className={`navbarLogo`} onClick={() => setNavExpanded(false)}>
            <TopLink to={RoutePath.HOME}>
              <img src={LogoImg} alt="Logo przychodni, link do strony głównej" />
            </TopLink>
          </div>
          <SearchBar />
          <div className="nav-tools">
            <FontSizeChanger />
            <ContrastChanger />
            <PjmLogo />
          </div>
        </div>
        <div className="mobileMenuWrapper" >
          <div className={`mobileSideMenuOverlay ${is_navbarOpenActive ? "isActive" : ""}`} onClick={() => setNavExpanded(false)}></div>
          <div className={`mobileSideMenu ${is_navbarOpenActive ? "isActive" : ""}`}>
        <div className={`mobileSideMenuInsetWrapper${scrollPosition >= 40 ? " isScrolled" : ""}`} onClick={() => setNavExpanded(false)} ref={firstLink}>
              <TopLink id='navContent' to={RoutePath.NEWS} menu={true}>
                <div className="linkLarge">Aktualności</div>
              </TopLink>
              <hr />
              <TopLink to={RoutePath.PRESCRIPTIONS} menu={true}>
                <div className="linkLarge">Recepty na leki stałe</div>
              </TopLink>
              <TopLink to={RoutePath.SKIEROWANIENABADANIA} menu={true}>
                <div className="linkLarge">Skierowanie do specjalisty</div>
              </TopLink>
              <hr />
              <TopLink to={RoutePath.BASICHEALTHCARE} menu={true}>
                <div className="linkLarge">Lekarze POZ</div>
              </TopLink>
              <TopLink to={RoutePath.SPECIALISTCLINICS} menu={true}>
                <div className="linkLarge">Poradnie specjalistyczne</div>
              </TopLink>
              <TopLink to={RoutePath.PRIVOFFICES} menu={true}>
                <div className="linkLarge">Gabinety prywatne</div>
              </TopLink>
              <TopLink to={RoutePath.TREATMENTOFFICE} menu={true}>
                <div className="linkLarge">Gabinet zabiegowy</div>
              </TopLink>
              {/* <TopLink to={RoutePath.GABINETINFEKCYJNY} menu={true}>
                <div className="linkLarge">Gabinet infekcyjny</div>
              </TopLink>  */}
              {/* <TopLink to={RoutePath.COVIDINFO} menu={true}>
                <div className="linkLarge">Koronawirus COVID-19 - informacje</div>
              </TopLink> */}
              <TopLink to={RoutePath.SZCZEPIONKAINFO} menu={true}>
                <div className="linkLarge">Szczepionka COVID-19 - informacje</div>
              </TopLink>
              <TopLink to={RoutePath.NOCNAOPIEKA} menu={true}>
                <div className="linkLarge">Nocna i Świąteczna Opieka Zdrowotna</div>
              </TopLink>
              <TopLink to={RoutePath.DYZURYAPTEK} menu={true}>
                <div className="linkLarge">Dyżury aptek</div>
              </TopLink>
              <TopLink to={RoutePath.PRINCING} menu={true}>
                <div className="linkLarge">Cennik usług</div>
              </TopLink>
              <hr />
              <TopLink to={RoutePath.INFORMACJEDLAPACJENTAPAGE} menu={true}>
                <div className="linkLarge">Informacje dla pacjenta</div>
              </TopLink>
              <TopLink to={RoutePath.STANDARDYOCHRONYMALOLETNICHAPAGE} menu={true}>
                <div className="linkLarge">Standardy Ochrony Małoletnich</div>
              </TopLink>
              <hr />
              <TopLink to={RoutePath.STRADOMIAWIERZCHNIA} menu={true}>
                <div className="linkLarge">Stradomia Wierzchnia</div>
              </TopLink>
              <TopLink to={RoutePath.PUBLICTENDERS} menu={true}>
                <div className="linkLarge">Przetargi publiczne</div>
              </TopLink>
              {/* <TopLink to={RoutePath.DEKLARACJADOSTEPNOSCI} menu={true}>
                <div className="linkLarge">Deklaracja dostępności</div>
              </TopLink> */}
              <a href="https://www.spzozprzychodnia.pl/deklaracja-dostepnosci.html" className="topLinkWrapper" tabIndex={0}>
                <div className="linkLarge">Deklaracja dostępności</div>
                </a>
              <TopLink to={RoutePath.JEZYKMIGOWYPAGE} menu={true}>
                <div className="linkLarge">Obsługa języka migowego</div>
              </TopLink>
              <TopLink to={RoutePath.OCHRONADANYCHOSOBOWYCHPAGE} menu={true}>
                <div className="linkLarge">Ochrona danych osobowych</div>
              </TopLink>
              <TopLink to={RoutePath.CONTACT} menu={true}>
                <div className="linkLarge">Kontakt</div>
              </TopLink>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
