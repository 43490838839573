import React from "react";
import "./patient-info-copy2.scss";
interface Props {
  title: string;
  icon: string;
  to: string;
}

const PatientInfoCopies = (props: Props) => {

  return (
    <div className="patientInfoCopy youtube-embed">
      <div className="content__wrapper">
        <div className="links__wrapper">
          <div className="links__wrapper_column">
            <iframe src="https://drive.google.com/file/d/1Mt7X3A5E8-7Xkf0cPGTzPE1IDxdPZfvx/preview" title="SPZOZ Przychodnia Syców - informacja dla pacjentów niedosłyszących" frameBorder="0" allow="accelerometer; autoplay;" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientInfoCopies;
