
export const oculist = [
    {
        name: 'Lilia Giełzak', 
        specializations: [{
          name: 'Poradnia okulistyczna',
          workingDays: ['Poniedziałek: 07:30 - 14:30', 'Wtorek: 11:30 - 18:30', 'Środa: 07:30 - 14:30', 'Czwartek: 11:30-18:30', 'Piątek: 07:30-14:30'],
          officeNumber: 'parter'
        }]
      },
]

export const orthodontist = [
    {
        name: 'Beata Szwarc-Rogowska', 
        specializations: [{
          name: 'Poradnia ortodontyczna',
          workingDays: ['Poniedziałek: 15:00 - 19:00', 'Wtorek: 07:45 - 13:45', 'Środa: 15:00 - 20:00', 'Czwartek: 08:00 - 12:00', 'Piątek: 08:00 - 14:00']
        }]
      },
]

export const gynecologist = [
      {
        name: 'Ryszard Rusiecki', 
        specializations: [{
          name: 'Poradnia ginekologiczno-położnicza',
          workingDays: ['Poniedziałek: 08:00 - 13:00', 'Czwartek: 13:00 - 18:00']
        }]
      },
      {
        name: 'Waldemar Milewski', 
        specializations: [{
          name: 'Poradnia ginekologiczno-położnicza',
          workingDays: ['Piątek: 09:00 - 13:00', 'Wtorek: 11:00 - 18:00']
        }]
      },
]

export const neurologist = [
    {
        name: 'Zbigniew Pietrzak', 
        specializations: [{
          name: 'Poradnia neurologiczna',
          workingDays: ['Wtorek: 08:00 - 11:00']
        }]
      },
      {
        name: 'Anna Jasięga', 
        specializations: [{
          name: 'Poradnia neurologiczna',
          workingDays: ['Poniedziałek: 08:00 - 12:30']
        }]
      },
    
]

export const dentist = [
    {
        name: 'Dominika Górecka-Rusajczyk', 
        specializations: [{
          name: 'Poradnia stomatologiczna',
          workingDays: ['Poniedziałek: 07:30-13:30','Wtorek: 07:30-13:30', 'Środa: 13:00-19:00', 'Czwartek: 12:00-18:00', 'Piątek: 07:30-13:00']
        }]
      }
    
]

export const psychologist = [
    {
        name: 'Wojciech Kulaszewski', 
        specializations: [{
          name: 'Poradnia zdrowia psychicznego',
          workingDays: ['Środa: 10:00 - 17:00, 17:30 - 20:00', 'Piątek: 11:00 - 20:00']
        }]
      },
      {
        name: 'Agnieszka Augustyniak-Siuba', 
        specializations: [{
          name: 'Poradnia zdrowia psychicznego',
          workingDays: ['Wtorek: 08:00 - 11:30, 12:00 - 16:30', 'Czwartek: 08:00 - 11:30, 12:00 - 20:00']
        }]
      },
      {
        name: 'Aneta Pawłowska-Okowiak', 
        specializations: [{
          name: 'Poradnia zdrowia psychicznego',
          workingDays: ['Środa: 16:00 - 20:00', 'Piątek: 09:00 - 17:00']
        }]
      },
    
]


export const rheumatologist = [
    {
        name: 'Grażyna Raczkowska-Czajka', 
        specializations: [{
          name: 'Poradnia reumatologiczna',
          workingDays: ['Wtorek: 12:00 - 16:00','Czwartek: 14:00 - 18:00', 'Piątek: 08:00 - 12:00']
        }]
      },
]

export const dermatologist = [
    {
        name: 'Ewa Ilska', 
        specializations: [{
          name: 'Poradnia dermatologiczna',
          workingDays: ['Poniedziałek: 13:00 - 18:00','Wtorek: 07:30 - 13:30', 'Czwartek: 07:30 - 14:30']
        }]
      },
    {
        name: 'Rejestracja Poradni Dermatologicznej', 
        specializations: [{
          name: 'Poradnia dermatologiczna',
          workingDays: ['Poniedziałek: 13:00 - 16:00','Wtorek: 07:30 - 13:30', 'Czwartek: 07:30 - 13:30']
        }]
      },
]
