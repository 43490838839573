import React from 'react'
import "./patient-info.scss";
import { RoutePath } from "../../../route-paths";
import { Link } from 'react-router-dom';
import PatientIcon from '../../assets/ico_infodlapacjenta.svg'
import ArrowIcon from '../../assets/Ico_arrow.svg';
interface Props {
    title: string;
    icon: string;
    to: string;
}

 const PatientInfo = (props: Props) => {
    const { title } = props;


    return (<div className='patientInfo'>
            <div className="img__wrapper">
                <img aria-hidden="true" className="no-invert" src={PatientIcon} alt={""} />
            </div>
            <div className="content__wrapper">
                <Link to={RoutePath.INFORMACJEDLAPACJENTAPAGE} className="title__wrapper">
                    <h2 className="title">{title}</h2>
                    <img aria-hidden="true" className="no-invert" src={ArrowIcon} alt="" /></Link>
                <div className="links__wrapper">
                    <ul className='links__wrapper_column'>
                        <li><Link to={{pathname: RoutePath.INFORMACJEDLAPACJENTAPAGE, hash:"dokumentacja-medyczna"}} className='infoLink external-link'><span aria-hidden={true}>&#8226; </span>informacje dotyczące dokumentacji medycznej</Link></li>
                        <li><Link to={{pathname: RoutePath.INFORMACJEDLAPACJENTAPAGE, hash:"rejestracja"}} className='infoLink external-link'><span aria-hidden={true}>&#8226; </span>rejestracja</Link> </li>
                        <li><Link  to={{pathname: RoutePath.INFORMACJEDLAPACJENTAPAGE, hash:"info-poz-w-dni-wolne"}} className='infoLink external-link'><span aria-hidden={true}>&#8226; </span>informacje o świadczeniach w zakresie POZ w nocy, w dni wolne oraz niedziele i święta </Link> </li>
                        <li><Link to={{pathname: RoutePath.INFORMACJEDLAPACJENTAPAGE, hash:"badania-usg"}} className='infoLink external-link'><span aria-hidden={true}>&#8226; </span>przygotowanie do badania USG</Link></li> 
                        <li><Link  to={{pathname: RoutePath.INFORMACJEDLAPACJENTAPAGE, hash:"pliki-do-pobrania"}} className='infoLink external-link'><span aria-hidden={true}>&#8226; </span>przydatne pliki do pobrania</Link></li>
                        <li><Link  to={{pathname: RoutePath.INFORMACJEDLAPACJENTAPAGE, hash:"punkt-pobran-materialow"}} className='infoLink external-link'><span aria-hidden={true}>&#8226; </span>punkty pobrań materiałów do badań laboratoryjnych</Link></li>
                        <li><Link  to={{pathname: RoutePath.INFORMACJEDLAPACJENTAPAGE, hash:"informacje-o-wynikach-badan"}} className='infoLink external-link'><span aria-hidden={true}>&#8226; </span>informacje dotyczące wydawania wyników badań</Link></li>
                        <li><Link  to={{pathname: RoutePath.INFORMACJEDLAPACJENTAPAGE, hash:"badania-ktg"}} className='infoLink external-link'><span aria-hidden={true}>&#8226; </span>badania KTG</Link></li>
                        <li><Link  to={{pathname: RoutePath.INFORMACJEDLAPACJENTAPAGE, hash:"upowaznienia-do-dokumentacji-medycznej"}} className='infoLink external-link'><span aria-hidden={true}>&#8226; </span>upoważnienie do uzyskania dokumentacji medycznej</Link></li>
                        <li><Link  to={{pathname: RoutePath.INFORMACJEDLAPACJENTAPAGE, hash:"przydatne-linki"}} className='infoLink external-link'><span aria-hidden={true}>&#8226; </span>przydatne linki</Link></li>
                        <li><Link  to={{pathname: RoutePath.INFORMACJEDLAPACJENTAPAGE, hash:"karta-praw-pacjenta"}} className='infoLink external-link'><span aria-hidden={true}>&#8226; </span>karta praw pacjenta</Link></li>
                        <li><Link  to={{pathname: RoutePath.INFORMACJEDLAPACJENTAPAGE, hash:"skargi-i-wnioski"}} className='infoLink external-link'><span aria-hidden={true}>&#8226; </span>skargi i wnioski</Link></li>
                    </ul>
                    <div className='links__wrapper_column'>
                       
                    </div>
                </div>
            </div>
    </div>)
}

export default PatientInfo;