import FontSizeStateTypes from "./fontSizeState.types";

export const plusSizeFonts = () => {
  return {
    type: FontSizeStateTypes.PLUS_SIZE_FONTS,
  };
};

export const minusSizeFonts = () => {
  return {
    type: FontSizeStateTypes.MINUS_SIZE_FONTS,
  };
};