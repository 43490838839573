import React from 'react';
import './row-info.scss';

interface Props {
    pricesData: {name:string, products: [{name:string, price: string}]},
  }

  
const PricesRow: React.FC<any> = ({pricesData }:Props) => {
    const {name, products} = pricesData;
  return (
    <div className='row-info prices'>
        <div tabIndex={0} className='row-info__name'>
          {name}
        </div>
        <div className='row-info__description'>
            {products.map(item=><p> <span>{item.name}</span><span>{item.price}</span></p>)}
        </div>
    </div>
       
  )
}

export default PricesRow