import React from "react";
import "./szczepionka-info-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import CollapsibleBlock from "../../components/collapsible-block/collapsible-block";
import CollapsibleBlocksWrapper from "../../components/collapsible-block/collapsible-blocks-wrapper";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import PageWrapper from "../../components/page-wrapper/page-wrapper";

const SzczepionkaInfoPage: React.FC<any> = () => {

  return (
    <PageWrapper className="szczepionka-info-page">
      <Helmet>
        <title>{'Koronawirus COVID-19 | SPZOZ Przychodnia Syców'}</title>
      </Helmet>

      <section className="pageWrapper__section">
        <Container>
          <MainHeader>SZCZEPIONKA COVID - INFORMACJE BIEŻĄCE</MainHeader>
          <StandardTextBlock>
          <h2>Informacje dotyczące szczepień COVID-19 można uzyskać pod numerem <a aria-label="Pod numerem telefonu 574 368 425" href="tel:574368425">☎ 574 368 425</a></h2>
          </StandardTextBlock>
          <br />
          <CollapsibleBlocksWrapper>

          <CollapsibleBlock
              title="Informacje dotyczące szczepienia dzieci od lat 5"
              isSplited={true}
            >
              <StandardTextBlock>
                <p>
                  <h2><strong>Informacje o szczepieniu dzieci od lat 5</strong></h2>
                </p>
                <br />
                <ol>
                  <li>Szczepienia odbywają się w Punkcie szczepień w Poradni Dziecięcej na parterze, gabinet 34. Kontakt do Punktu szczepień <a aria-label="Pod numerem telefonu 62 606 69 81" href="tel:626066981">62 606 69 81.</a></li>
                  <li>Do punktu szczepień covid wchodzi tylko jeden opiekun dziecka.</li>
                  <li>Prosimy, jeśli jest taka możliwość przychodzić z wypełnionym kwestionariuszem do szczepienia - do pobrania <strong><u><a href="./do-pobrania/covid/kwestionariusz-logo-grupa-5-11 lat_91221.pdf">tutaj.</a></u></strong> Przyspieszy to czas oczekiwania na szczepienie.</li>
                </ol>
              </StandardTextBlock>
            </CollapsibleBlock>

            <CollapsibleBlock
              title="KWESTIONARIUSZ SZCZEPIENIA WRAZ Z OŚWIADCZENIEM"
              isSplited={true}
            >
              <StandardTextBlock>
                    <p><a aria-label="Kwestionariusz szczepienia wraz z oświadczeniem PDF 587KB" href="./do-pobrania/covid/kwestionariusz_szczepienia_z_oswiadczeniem.pdf">Kwestionariusz szczepienia wraz z oświadczeniem (PDF 587KB)</a></p>
                    <p><a aria-label="Kwestionariusz wstępnego wywiadu przesiewowego przed szczepieniem dziecka w wieku 5-11 lat przeciw COVID-19 PDF 215KB" href="./do-pobrania/covid/kwestionariusz-logo-grupa-5-11 lat_91221.pdf">Kwestionariusz wstępnego wywiadu przesiewowego przed szczepieniem dziecka w wieku 5-11 lat przeciw COVID-19 (PDF 215KB)</a></p>
                    <p><a aria-label="Kwestionariusz wstępnego wywiadu przesiewowego przed szczepieniem osoby niepełnoletniej przeciw COVID-19 PDF 214KB" href="./do-pobrania/covid/Kwestionariusz_wstepnego_wywiadu_p rzesiewowego_przed_szczepieniem_osoby_niepelnoletniej_przeciw_COVID-19.pdf">Kwestionariusz wstępnego wywiadu przesiewowego przed szczepieniem osoby niepełnoletniej przeciw COVID-19 (PDF 214KB)</a></p>
              </StandardTextBlock>
            </CollapsibleBlock>

          <CollapsibleBlock
              title="SZCZEPIENIE PRZECIW COVID-19"
              isSplited={true}
            >
              <StandardTextBlock>
                <p><a aria-label="Charakterystyka produktu leczniczego PDF 1227KB" href="./do-pobrania/covid/charakterystyka-produktu-leczniczego.pdf">Charakterystyka produktu leczniczego (PDF 1227KB)</a></p>
                    <p><a aria-label="Ulotka dla pacjenta 1 PDF 360KB" href="./do-pobrania/covid/ulotka-dla-pacjenta.pdf">Ulotka dla pacjenta 1 (PDF 340KB)</a></p>
                    <p><a aria-label="Karta przypominająca dla pacjenta PDF 691KB" href="./do-pobrania/covid/karta-przyominajaca-dla-pacjenta.pdf">Karta przypominająca dla pacjenta (PDF 691KB)</a></p>
                    <p><a aria-label="Kwestionariusz szczepienia wraz z oświadczeniem PDF 587KB" href="./do-pobrania/covid/kwestionariusz_szczepienia_z_oswiadczeniem.pdf">Kwestionariusz szczepienia wraz z oświadczeniem (PDF 587KB)</a></p>
                    <p><a aria-label="Ulotka dla pacjenta 2 PDF 11.9MB" href="./do-pobrania/covid/Ulotka_dla_pacjenta.pdf">Ulotka dla pacjenta 2 (PDF 11,9MB)</a></p>
              </StandardTextBlock>
            </CollapsibleBlock>

            <CollapsibleBlock
              title="NARODOWY PROGRAM SZCZEPIEŃ"
              isSplited={true}
            >
              <StandardTextBlock>
              <p>
              Zapisy na szczepienie odbywać się będą za pośrednictwem:
                </p>
                <br />
                <p>
                  <ol>
                    <li>Infolinii Narodowego Programu Szczepień Przeciw COVID-19 pod nr telefonu 989
                    Dzwoniąc na infolinię, otrzymasz najbardziej aktualne i wiarygodne informacje na temat
                    szczepień przeciwko koronawirusowi. W drugim etapie, po 15 stycznia, przez infolinię będzie
                    można zapisywać się na szczepienie. Blisko 500 konsultantów przez całą dobę, siedem dni w
                    tygodniu, odpowiada na pytania dotyczące zarówno organizacji szczepień, jak i kwestii
                    związanych z samym podaniem szczepionek, między innymi:</li>
                  
                  <ul>
                    <li>kto i kiedy może się zaszczepić?</li>
                    <li>w jaki sposób i gdzie będzie można zapisać się na szczepienie?</li>
                    <li>czy można zaszczepić się po przebyciu Covid-19?</li>
                    <li>jak przygotować się do szczepienia?</li>
                    <li>czy szczepionka jest refundowana?</li>
                  </ul>
                  </ol> 
                </p>
                <br />
                <p>Informacja będzie udzielana w czterech językach:
                  <ul>
                    <li>polskim</li>
                    <li>angielskim</li>
                    <li>rosyjskim</li>
                    <li>ukraińskim</li>
                  </ul>
                </p>
                <br />
                <p>
                Połączenie z infolinią Narodowego Programu Szczepień przeciw COVID-19 jest bezpłatne, a z
                konsultantami można połączyć się przez całą dobę, siedem dni w tygodniu.
                Numer dla osób dzwoniących z Polski: 989. Dla osób telefonujących z zagranicy, oraz dla
                abonentów operatorów nie obsługujących numerów specjalnych: <a aria-label="Pod numerem telefonu 22 62 989" href="tel:226262989">(22) 62 62 989. </a> 
                Dla zainteresowanych, w dni robocze od godziny <strong>8.00 do 16.00</strong>, dostępny będzie również
                wideoczat na stronie <a aria-label="link zewnętrzny gov.pl/szczepimysie - kliknięcie spowoduje otwarcie w nowej karcie" href="https://gov.pl/szczepimysie">gov.pl/szczepimysie</a>.  Pytania można również przesłać na
                adres: <a aria-label="Otwarcie klienta pocztowego" href="mailto:szczepionki@nfz.gov.pl">szczepionki@nfz.gov.pl</a>.
                </p>
                <br />
                <p>
                  <ol>
                    <li>Online przez Internetowe Konto Pacjenta (IKP) na <a aria-label="link zewnętrzny pacjent.gov.pl - kliknięcie spowoduje otwarcie w nowej karcie" href="https://pacjent.gov.pl">pacjent.gov.pl</a>,</li>
                    <li>Bezpośrednio w Przychodni od poniedziałku do piątku w godz 8-17.</li>
                  </ol>
                </p>
                <br />
                <p>
                <strong>Punkt szczepień w Przychodni</strong> – pod numerem telefonu <a aria-label="Pod numerem telefonu 626 066 981" href="tel:626066981">626 066 981</a> oraz w rejestracji ogólnej- pod numerem telefonu <a aria-label="Pod numerem telefonu 503 622 361" href="tel:503622361">503 622 361</a>
                </p>
                <br />
                <p>
                <strong>Szczepienia są dobrowolne oraz bezpłatne.</strong>
                </p>
                <br />
                <p>Podczas rejestracji należy podać dane osobowe oraz koniecznie nr telefonu. Po dokonaniu rezerwacji terminu   wizyty otrzymasz SMS z informacją o wybranym miejscu i terminie szczepienia.  O terminie drugiej wizyty także przypomina SMS. Dodatkowo pracownicy SP ZOZ „Przychodnia”  w Sycowie wykonają do Państwa telefon powiadamiający i przypominający o terminie, miejscu i godzinie szczepienia. Przyjęcie szczepionki będzie  potwierdzone przez specjalny system, który umożliwi weryfikację zaszczepienia (m.in. kod QR, który pozwoli szybko potwierdzić odbyte szczepienie).</p>
                <p>Na szczepienie zgłaszamy się z wypełnionym "Kwestionariuszem wstępnego wywiadu przesiewowego przed szczepieniem osoby dorosłej przeciw COVID- 19" - do pobrania ze strony Przychodni lub bezpośrednio z punktu szczepień lub rejestracji.</p>
                <p>Szczepienia odbywać będą się codziennie w gabinecie infekcyjnym w godzinach: 8.00-13.30, 14.00-18.00. </p>
                <p>Budynek przychodni zostanie odpowiednio oznaczony grafikami pozwalającymi na dotarcie do gabinetu infekcyjnego.</p>
                <br />
                <p><strong>Terminy rejestracji na szczepienia:</strong></p>
                <p>
                <ul>
                  <li>5.01.2020 - 21.01.2020 -  rejestracja na szczepienia dla osób, które ukończyły 80 lat. Planowane rozpoczęcie  szczepienia - 25.01.2020</li>
                  <li>od 22.01. 2020r -  rejestracja na szczepienia dla osób, które ukończyły 70 lat</li>
                </ul>
                </p>
                <p>Osoby, które będą chciały zaszczepić się przeciw COVID-19, ale mają trudność z dotarciem do punktu szczepień, będą mogły skorzystać ze specjalnie zorganizowanego transportu. Powyższy należy zgłosić podczas rejestracji.</p>
              </StandardTextBlock>
            </CollapsibleBlock>
            
            
          </CollapsibleBlocksWrapper>
        </Container>
      </section>
    </PageWrapper>
  )
}

export default SzczepionkaInfoPage;