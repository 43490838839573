import React from "react";
import "./treatment-office-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import {treatmentData} from './treatment-office-data';
import TreatmentRow from "../../components/row-info/treatment-row";

const TreatmentOfficePage: React.FC<any> = () => {

  return (
    <PageWrapper className="treatment-office-page">
      <Helmet>
        <title>{'Gabinet zabiegowy | SPZOZ Przychodnia Syców'}</title>
      </Helmet>

      <section className="pageWrapper__section">
        <Container>
          <MainHeader>GABINET ZABIEGOWY</MainHeader>
          {treatmentData.map((item, index)=><TreatmentRow key={index} treatmentData={item}/>)}        
        </Container>
      </section>
    </PageWrapper>
  )
}

export default TreatmentOfficePage;