import React from "react";
import Helmet from "react-helmet";
import "./jezyk-migowy-page.scss";
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import MigowyImg from "./../../assets/pjm-logo2.png"


const JezykMigowyPage: React.FC<any> = () => {

  return (
    <PageWrapper className="deklaracje-dostepnosci-page">
      <Helmet>
        <title>{'Obsługa w Języku Migowym | SPZOZ Przychodnia Syców'}</title>
      </Helmet>
      <section className="pageWrapper__section" style={{ maxWidth: 1120 }}>
        <Container>
          <MainHeader>OBSŁUGA W JĘZYKU MIGOWYM</MainHeader>
          <StandardTextBlock>
            <p>Stosując się do ustawy z dnia 9 lipca 2019 r. o zapewnianiu dostępności osobom ze szczególnymi potrzebami, SPZOZ „Przychodnia” w Sycowie zapewnia tłumacza polskiego języka migowego (PJM) on-line dla osób z dysfunkcją słuchu. </p>
            <br />
            <p>Tłumacz umożliwia swobodną i bezpośrednią komunikację osoby niesłyszącej z pracownikami Przychodni w czasie rzeczywistym, przy pomocy kamery. Językiem porozumiewania się jest polski język migowy (PJM) lub system języka migowego (SJM).</p>
            <br />
            <p>Usługa dostępna jest w godzinach pracy Przychodni, tj. <strong>od poniedziałku do piątku od 8.00 do 18.00.</strong></p>
            <p>Kliknij ikonkę i połącz się z tłumaczem.</p>
            <br />
            <a  aria-label="Tłumacz języka migowego strona otworzy się w nowej stronie" target="_blank" rel="noopener noreferrer" href="https://tlumacz.migam.org/spzoz_sycow"><img src={MigowyImg} alt="Język Migowy" className="size-migowy"/></a>
            
          </StandardTextBlock>
        </Container>
      </section>
    </PageWrapper>
  )
}

export default JezykMigowyPage;