/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import Helmet from "react-helmet";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import "./prescriptions-page.scss";
import SkierowanieForm from "./skierowanie-form";
import WniosekWrapper from "./wniosek-wrapper";

const SkierowaniaPage: React.FC<any> = () => {
  return (
    <WniosekWrapper title="Skierowanie do specjalisty">
      <Helmet>
        <title>{"Wniosek o skierowanie do specjalisty | SPZOZ Przychodnia Syców"}</title>
      </Helmet>
      <StandardTextBlock>
        <p>
          Samodzielny Publiczny Zakład Opieki Zdrowotnej “Przychodnia” w Sycowie informuje, iż w celu ograniczenia kontaktu między pacjentami w Przychodni{" "}
          <strong>
            <span style={{ color: "#B30000" }}>KOD WYSTAWIONEGO SKIEROWANIA</span>
          </strong>{" "}
          można uzyskac telefonicznie pod numerem telefonu:
        </p>
        <br />
        <p>
          <strong>
            <a aria-label="Pod numerem telefonu 62 785 51 96" href="tel:627855196">
              62 785 51 96
            </a>{" "}
            do godziny 12:00
          </strong>
        </p>
        <p>
          <strong>
            <a aria-label="Pod numerem telefonu 62 785 51 92" href="tel:627855192">
              62 785 51 95
            </a>{" "}
            po godzinie 12:00
          </strong>
        </p>
        <br />
        <br />
        <br />
        <h2 tabIndex={0} className="title">
          WNIOSEK O SKIEROWANIE DO SPECJALISTY
        </h2>
        <div className="prescriptions-info">We wniosku nie wpisujemy zapotrzebowania na badania laboratoryjne. Pacjent musi zgłosić się na wizytę lekarską lub teleporadę.</div>
        <br />
        <SkierowanieForm />
      </StandardTextBlock>
    </WniosekWrapper>
  );
};

export default SkierowaniaPage;
