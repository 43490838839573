import React from "react";
import "./dyzury-aptek-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import CollapsibleBlock from "../../components/collapsible-block/collapsible-block";
import CollapsibleBlocksWrapper from "../../components/collapsible-block/collapsible-blocks-wrapper";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import {januaryPharmacy, februaryPharmacy, marchPharmacy, aprilPharmacy, mayPharmacy, junePharmacy, julyPharmacy, augustPharmacy, octoberPharmacy, novemberPharmacy, decemberPharmacy, septemberPharmacy} from './dyzury-aptek-data';
import PharmacyRow from "../../components/row-info/pharmacy-row";

const DyzuryAptekPage: React.FC<any> = () => {

  return (
    <PageWrapper className="dyzury-aptek-page">
      <Helmet>
        <title>{'Dyżury aptek | SPZOZ Przychodnia Syców'}</title>
      </Helmet>

      <section className="pageWrapper__section">
        <Container>
          <MainHeader>Dyżury Aptek</MainHeader>
          <br/>
          <StandardTextBlock>
            <p><strong>Harmonogram dyżurów aptek w niedziele i święta w 2023 r. w Sycowie</strong></p>
          </StandardTextBlock>
          <br/>
          <br/>
          <br/>
          <CollapsibleBlocksWrapper>
            <CollapsibleBlock isSplited={true} title="STYCZEŃ">
              {januaryPharmacy.map((item,index)=><PharmacyRow key={index} pharmacyData={item}/>)} 
            </CollapsibleBlock>
            <CollapsibleBlock
              title="LUTY" isSplited={true}>
               {februaryPharmacy.map((item,index)=><PharmacyRow key={index} pharmacyData={item}/>)} 
            </CollapsibleBlock>
            <CollapsibleBlock
              title="MARZEC" isSplited={true} >
               {marchPharmacy.map((item,index)=><PharmacyRow key={index} pharmacyData={item}/>)} 
            </CollapsibleBlock>
            <CollapsibleBlock
              title="KWIECIEŃ" isSplited={true}>
              {aprilPharmacy.map((item,index)=><PharmacyRow key={index} pharmacyData={item}/>)} 
            </CollapsibleBlock>
            <CollapsibleBlock
              title="MAJ" isSplited={true}>
              {mayPharmacy.map((item,index)=><PharmacyRow key={index} pharmacyData={item}/>)} 
            </CollapsibleBlock>
            <CollapsibleBlock
              title="CZERWIEC" isSplited={true}>
              {junePharmacy.map((item,index)=><PharmacyRow key={index} pharmacyData={item}/>)} 
            </CollapsibleBlock>
            <CollapsibleBlock
              title="LIPIEC" isSplited={true}>
             {julyPharmacy.map((item,index)=><PharmacyRow key={index} pharmacyData={item}/>)} 
            </CollapsibleBlock>
            <CollapsibleBlock
              title="SIERPIEŃ" isSplited={true}>
              {augustPharmacy.map((item,index)=><PharmacyRow key={index} pharmacyData={item}/>)} 
            </CollapsibleBlock>
            <CollapsibleBlock
              title="WRZESIEŃ" isSplited={true}>
              {septemberPharmacy.map((item,index)=><PharmacyRow key={index} pharmacyData={item}/>)} 
            </CollapsibleBlock>
            <CollapsibleBlock
              title="PAŹDZIERNIK" isSplited={true}>
               {octoberPharmacy.map((item,index)=><PharmacyRow key={index} pharmacyData={item}/>)} 
            </CollapsibleBlock>
            <CollapsibleBlock
              title="LISTOPAD" isSplited={true}>
                {novemberPharmacy.map((item,index)=><PharmacyRow key={index} pharmacyData={item}/>)}
            </CollapsibleBlock>
            <CollapsibleBlock
              title="GRUDZIEŃ" isSplited={true}>
                {decemberPharmacy.map((item,index)=><PharmacyRow key={index} pharmacyData={item}/>)}
            </CollapsibleBlock>
          </CollapsibleBlocksWrapper>
          <br />
          <br />
        </Container >
      </section >
    </PageWrapper >
  ) 
}

export default DyzuryAptekPage;