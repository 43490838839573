import React from "react";
import "./news-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import NewsTile from "../../components/news-tile/news-tile";
import NewsTileWrapper from "../../components/news-tile/news-tile-wrapper";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import { news_data } from "./news-data";

const NewsPage: React.FC<any> = () => {
  return (
    <PageWrapper className="news-page">
      <Helmet>
        <title>{"Aktualności | SPZOZ Przychodnia Syców"}</title>
      </Helmet>

      <section className="pageWrapper__section">
        <Container>
          <MainHeader>Aktualności</MainHeader>
          <NewsTileWrapper itemsPerPage={6}>
            {news_data.map((x, index) => {
              return (
                <NewsTile lang={x.lang} key={index} title={x.title} img={x.img} isClassicLink={x.isClassicLink} alt={x.alt ? x.alt : ""} to={x.to}>
                  {x.content}
                </NewsTile>
              );
            })}
          </NewsTileWrapper>
        </Container>
      </section>
    </PageWrapper>
  );
};

export default NewsPage;
