import React, { ReactElement, useEffect, useState } from "react";
import "./modal.scss";
import { ModalContext } from "./modalContext";

interface ModalProps {
  title: string | React.ReactNode;
  openTrigger?: boolean;
  button?: {
    hidden?: boolean;
    disabled?: boolean;
    customButton?: React.ReactNode;
    customButtonId?: string;
    title?: string;
  } & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
  disableStandardButtons?: boolean;
  confirm?: (data: any) => void;
  children: React.ReactNode;
  maxWidth?: number;
  className?: string;
}

const Modal: React.FC<ModalProps> = (props) => {
  const { button = {}, className = "", openTrigger, title, children, disableStandardButtons, confirm } = props;
  const [isOpen, setIsOpen] = useState<boolean>();
  const [data, setData] = useState<any>(null);

  const handleOpen = (e: React.MouseEvent<any, MouseEvent>) => {
    setIsOpen(true);
  };
  const preventClose = (e: React.MouseEvent<any, MouseEvent>) => {
    e.stopPropagation();
  };
  const handleClose = (e: React.MouseEvent<any, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(false);
  };
  const handleOK = (e: React.MouseEvent<any, MouseEvent>) => {
    e.stopPropagation();
    handleConfirm();
  };
  const handleConfirm = () => {
    setIsOpen(false);
    if (confirm) confirm(data);
    setData(null);
  };

  const handleEsc = (event: any) => {
    if (event?.key === "Escape") setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  useEffect(() => {
    if (openTrigger === true) setIsOpen(true);
  }, [openTrigger]);

  return (
    <ModalContext.Provider value={{ data: data, setData: setData, handleConfirm: handleConfirm }}>
      {button.hidden !== true ? (
        button.customButton ? (
            React.cloneElement(button.customButton as ReactElement, { onClick: handleOpen })
        ) : (
          <button className="btn-material-nav open-modal-button" disabled={button.disabled} onClick={handleOpen}>
            {button.title ?? "modal"}
          </button>
        )
      ) : null}
      {isOpen ? (
        <div className={`ModalWrapper ${className} ${isOpen ? " --isOpen" : ""}`} onClick={preventClose} onMouseDown={handleClose}>
          <div className="Modal" onClick={preventClose} onMouseDown={preventClose} style={{ maxWidth: props.maxWidth ? props.maxWidth + "px" : undefined }}>
            <div className="modal-header">
              <div className="Modal_title">{title}</div>
              <div className="cancel-icon" onClick={handleClose} />
            </div>
            <div className="modal-body">{isOpen ? <>{children}</> : null}</div>
            {disableStandardButtons ? null : (
              <div className="modal-footer-wrapper">
                <div className="modal-footer">
                  <button className="btn-material-nav" onClick={handleClose} style={{ background: "white", border: "1px solid #1256BC", color: "#1256BC" }}>
                    Anuluj
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn-material-nav "
                    disabled={button.disabled}
                    onClick={handleOK}
                    style={{
                      border: "1px solid #1256BC",
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </ModalContext.Provider>
  );
};

export default Modal;
