export class ApiRootPath {
  // static rootPath: string = 'https://www.codeebo.com/';
  static rootPath: string = window.location.hostname;
}

export class ApiPath {
  static send_recipe: string = './spzoz_recipe.php';
  // static send_recipe: string = 'http://localhost:3000/public/spzoz_recipe.php';

}


// export class ApiRootPath {
//   static rootPath: string = 'https://www.codeebo.com/';
// }

// export class ApiPath {
//   static send_recipe: string = ApiRootPath.rootPath + 'spzoz_recipe.php';
// }