import React from 'react';
import './row-info.scss';

interface Props {
    treatmentData: {name:string, workingDays:[{day:string, hours:string}]};
  }

  
const TreatmentRow: React.FC<any> = ({treatmentData }:Props) => {
    const {name,workingDays} = treatmentData;
  return (
    <div className='row-info treatment'>
        <div tabIndex={0} className='row-info__name'>
          {name}
          </div>
          <div className='row-info__description'>
          <div className="left">
              {workingDays.map((item, index)=><p className={`left_detail`} key={index}>{item.day}: {item.hours}</p>)}
          </div>
        </div>
    </div>
  )
}

export default TreatmentRow