export const januaryPharmacy = [
    {
        name: "Apteka – Św. Krzysztofa",
        day: "1 stycznia 2024 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Nowa",
        day: "6 stycznia 2024 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Pod Wagą",
        day: "7 stycznia 2024 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Eliksir",
        day: "14 stycznia 2024 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Rodzinna",
        day: "21 stycznia 2024 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Tanie Leki",
        day: "28 stycznia 2024 r.",
        hours: '18:00 – 20:00'
    },
]

export const februaryPharmacy = [
    {
        name: "Apteka – Na zdrowie",
        day: "4 luty 2024 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Św. Krzysztofa",
        day: "11 luty 2024 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Nowa",
        day: "18 luty 2024 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Pod Wagą",
        day: "25 luty 2024 r.",
        hours: '18:00 – 20:00'
    }
]

export const marchPharmacy = [
    {
        name: "Apteka – Eliksir",
        day: "3 marca 2024 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Rodzinna",
        day: "10 marca 2024 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Tanie Leki",
        day: "17 marca 2024 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Na Zdrowie",
        day: "31 marca 2024 r.",
        hours: '18:00 – 20:00'
    }
]

export const aprilPharmacy = [
    {
        name: "Apteka – Św. Krzysztofa",
        day: "2 kwietnia 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Nowa",
        day: "9 kwietnia 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Pod Wagą",
        day: "10 kwietnia 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Eliksir",
        day: "16 kwietnia 2021 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Rodzinna",
        day: "23 kwietnia 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Tanie Leki",
        day: "30 kwietnia 2023 r.",
        hours: '18:00 – 20:00'
    }
]

export const mayPharmacy = [
    {
        name: "Apteka – Na Zdrowie",
        day: "1 maja 2021 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Św. Krzysztofa",
        day: "3 maja 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Nowa",
        day: "7 maja 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Pod Wagą",
        day: "14 maja 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Eliksir",
        day: "21 maja 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Rodzinna",
        day: "28 maja 2023 r.",
        hours: '18:00 – 20:00'
    }

]

export const junePharmacy = [
    {
        name: "Apteka – Tanie Leki",
        day: "4 czerwca 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Na Zdrowie",
        day: "8 czerwca 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Św. Krzysztofa",
        day: "11 czerwca 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Nowa",
        day: "18 czerwca 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Pod Wagą",
        day: "25 czerwca 2023 r.",
        hours: '18:00 – 20:00'
    }
]

export const julyPharmacy = [
    {
        name: "Apteka – Eliksir",
        day: "2 lipca 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Rodzinna",
        day: "9 lipca 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Tanie Leki",
        day: "16 lipca 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Na Zdrowie",
        day: "23 lipca 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Św. Krzysztofa",
        day: "30 lipca 2023 r.",
        hours: '18:00 – 20:00'
    }
]

export const augustPharmacy = [
    {
        name: "Apteka – Nowa",
        day: "6 sierpnia 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Pod Wagą",
        day: "13 sierpnia 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Eliksir",
        day: "15 sierpnia 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Rodzinna",
        day: "20 sierpnia 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Tanie Leki",
        day: "27 sierpnia 2023 r.",
        hours: '18:00 – 20:00'
    }
]

export const septemberPharmacy = [
    {
        name: "Apteka – Na Zdrowie",
        day: "3 września 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Św. Krzysztofa",
        day: "10 września 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Nowa",
        day: "17 września 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Pod Wagą",
        day: "24 września 2023 r.",
        hours: '18:00 – 20:00'
    }
]

export const octoberPharmacy = [
    {
        name: "Apteka – Eliksir",
        day: "1 października 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Rodzinna",
        day: "8 października 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Tanie Leki",
        day: "15 paźdiernika 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Na Zdrowie",
        day: "22 października 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Św. Krzysztofa",
        day: "29 października 2023 r.",
        hours: '18:00 – 20:00'
    }
]

export const novemberPharmacy = [
    {
        name: "Apteka – Nowa",
        day: "1 listopada 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Pod Wagą",
        day: "5 listopada 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Eliksir",
        day: "11 listopada 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Rodzinna",
        day: "12 listopada 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Tanie Leki",
        day: "19 listopada 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Na Zdrowie",
        day: "26 listopada 2023 r.",
        hours: '18:00 – 20:00'
    }
]

export const decemberPharmacy = [
    {
        name: "Apteka – Św. Krzysztofa",
        day: "3 grudnia 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Nowa",
        day: "10 grudnia 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Pod Wagą",
        day: "17 grudnia 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Eliksir",
        day: "24 grudnia 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Rodzinna",
        day: "25 grudnia 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Tanie Leki",
        day: "26 grudnia 2023 r.",
        hours: '18:00 – 20:00'
    },
    {
        name: "Apteka – Na Zdrowie",
        day: "31 grudnia 2023 r.",
        hours: '18:00 – 20:00'
    }
]


