import CallbacksStateTypes from './callbacksState.types';

const INITIAL_STATE = {
  is_globalToastFailedToFetchActive: false as boolean,
  is_slideOverlayActive: {
    isActive: false as boolean,
    redirectTo: ''
  },
  is_navbarbarActive: false as boolean,
  is_navbarOpenActive: false as boolean,
  searchPhrase: '' as string
}

const callbacksStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CallbacksStateTypes.TOAST_FAILEDTOFETCH_ACTIVATED:
      return {
        ...state,
        is_globalToastFailedToFetchActive: action.payload
      }
    case CallbacksStateTypes.SHOW_SLIDE_OVERLAY_SUCCESS:
      return {
        ...state,
        is_slideOverlayActive: {
          isActive: true,
          redirectTo: action.payload
        }
      }
    case CallbacksStateTypes.HIDE_SLIDE_OVERLAY_SUCCESS:
      return {
        ...state,
        is_slideOverlayActive: {
          isActive: false,
          redirectTo: ''
        }
      }
    case CallbacksStateTypes.SET_NAVBARBAR:
      return {
        ...state,
        is_navbarbarActive: action.payload
      }
    case CallbacksStateTypes.SET_NAVBAROPEN:
      return {
        ...state,
        is_navbarOpenActive: action.payload
      }
    case CallbacksStateTypes.SET_SEARCHPHRASE:
      return {
        ...state,
        searchPhrase: action.payload
      }
    default: {
      return state;
    }
  }
}

export default callbacksStateReducer;