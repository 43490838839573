/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useRef, useState, KeyboardEvent, useEffect  } from "react";
import "./collapsible-block.scss";
import { UnmountClosed } from 'react-collapse';
import ArrowDownIcon from './../../assets/arrowDownIcon.svg';
import { useLocation } from "react-router-dom";

interface Props {
  title: string,
  children: React.ReactNode;
  isOpenInit?: boolean;
  isSplited?: boolean
  id?: string
}

const CollapsibleBlock: React.FC<Props> = (props: Props) => {
  const [isOpen, setIsOpen] = useState(props.isOpenInit ? true : false)
  const { title, children, isSplited, id } = props;
  
  const ref = useRef<any>();

  const handleClickTitle = () => {
    setIsOpen(!isOpen);
  } 
  const location = useLocation();
  const patientHash = location.hash; 
  
  useEffect(()=>{
    if(patientHash){
      `#${id}` === patientHash && setIsOpen(true);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleKeyDown = (e:KeyboardEvent<HTMLDivElement>) =>{
    switch(e.key){
      case "Enter":
        setIsOpen(!isOpen)
        break;
    }
  }

  return (    
    <div ref={ref} id={id} className="collapsible-block" onKeyDown={handleKeyDown} tabIndex={0}>
      <div className={`collapsible-block__title${isSplited ? ' isSplited' : ''}`} onClick={() => handleClickTitle()} >
        <img className={`imgIcon${isOpen ? " isOpen" : ""}`} src={ArrowDownIcon} alt={isOpen? 'Zwiń element' : 'Rozwiń element' } />
        <h2 className="title">
          {title}
        </h2>
      </div>
      <UnmountClosed isOpened={isOpen} aria-expanded={isOpen}>
        <div aria-hidden="true" className={`collapsible-block__content${isSplited ? ' isSplited' : ''}`}>
          {children}
        </div>
      </UnmountClosed>
    </div>
  )
}

export default CollapsibleBlock;