import React from "react";
import "./top-link.scss";
import { useDispatch, useSelector } from "react-redux";
import { showSlideOverlay } from "../../redux/callbacksState/callbacksState.actions";
import { select_is_navbarOpenActive } from "../../redux/callbacksState/callbacksState.selectors";

interface Props {
  to: string,
  children: React.ReactNode | string,
  ref?: any,
  menu?: boolean,
  id?: string,
}

const TopLink: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const is_navbarOpenActive = useSelector(select_is_navbarOpenActive);

  const slideAndScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(showSlideOverlay(props.to as string))
    // setTimeout(() => {
    //   window.scrollTo(0, 0)
    // }, 200);
  }

  return (
    <a id={props.id} href={props.to} onClick={(e)=>slideAndScroll(e)} className="topLinkWrapper" tabIndex={props.menu  && !is_navbarOpenActive ? -1 : 0}>{props.children}</a>
  )
}

export default TopLink;
