import React from "react";
import './law-info-page.scss'
import Helmet from 'react-helmet';
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";

const LawInfoPage: React.FC<any> = (props: any) => {

  return (
    <PageWrapper className="law-info-page">
      <Helmet>
        <title>{'Informacje prawne | SPZOZ Przychodnia Syców'}</title>
      </Helmet>
      <Container>
        <section className="pageWrapper__section">
          <MainHeader>
            INFORMACJE PRAWNE
          </MainHeader>
          <br/>
          <StandardTextBlock>
            <TopLink to={RoutePath.KARTAPRAWPACJENTA}>Karta praw pacjenta</TopLink>
            <br/>
            <br/>
            <TopLink to={RoutePath.SKARGIIWNIOSKI}>Skargi i wnioski</TopLink>
          </StandardTextBlock>
        </section>
      </Container>
    </PageWrapper>
  )
}

export default LawInfoPage;