export const treatmentData = [
    {
        name: 'Gabinet zabiegowy',
        workingDays:[ {
            day: 'Poniedziałek-Piątek',
            hours: '07:30-18:00'
        },
        {
            day: 'Sobota',
            hours: 'nieczynne'
        },
        {
            day: 'Niedziela',
            hours: 'nieczynne'
        },
        ]
    }

]