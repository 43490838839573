import React from "react";
import TopLink from "../top-link/top-link";
import "./link-btn.scss";

interface Props {
  to?: string;
  color?: 'dark' | 'blue' | 'white'
  children: React.ReactNode;
}

const LinkBtn: React.FC<Props> = (props: Props) => {
  const { children, to, color } = props;
  return to ? (
    <TopLink to={to}>
      <div className={`link-btn${color === 'blue' ? ' link-btn--blue' : ''}${color === 'dark' ? ' link-btn--dark' : ''}${color === 'white' ? ' link-btn--white' : ''}`}>
        {children}
      </div>
    </TopLink>
  ) : <div className={`link-btn${color === 'blue' ? ' link-btn--blue' : ''}${color === 'dark' ? ' link-btn--dark' : ''}${color === 'white' ? ' link-btn--white' : ''}`}>
      {children}
    </div>
}

export default LinkBtn;