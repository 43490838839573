import contrastStateTypes from './contrastState.types';

const INITIAL_STATE = {
  theme: 'light',
}

const contrastStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case contrastStateTypes.TOGGLE_CONTRAST:
      return {
        ...state,
        theme: state.theme === 'light' ? 'dark' : 'light'
      }
    default: {
      return state;
    }
  }
}

export default contrastStateReducer;