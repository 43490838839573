import React from "react";
import Helmet from "react-helmet";
import { RoutePath } from "../../../route-paths";
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import TopLink from "../../components/top-link/top-link";

const DeklaracjaDostepnosciPage: React.FC<any> = () => {

  return (
    <PageWrapper className="deklaracja-dostepnosci-page">
      <Helmet>
        <title>{'Deklaracja dostępności | SPZOZ Przychodnia Syców'}</title>
      </Helmet>
      <section className="pageWrapper__section" style={{ maxWidth: 1120 }}>
        <Container>
          <MainHeader tabindex={0} id={"a11y-deklaracja"}>OŚWIADCZENIE O DOSTĘPNOŚCI CYFROWEJ</MainHeader>
          <StandardTextBlock>
            <p id="a11y-wstep"><span id="a11y-podmiot">Samodzielny Publiczny Zespół Opieki Zdrowotnej</span> w Sycowie zobowiązuje się zapewnić dostępność swojej strony internetowej zgodnie z Ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych. Wykonano audyt zgodności ze standardem WCAG. Strona spełnia standard WCAG 2.1 na poziomie AA. Oświadczenie w sprawie dostępności ma zastosowanie do strony internetowej:  <TopLink to={RoutePath.HOME}>Samodzielny Publiczny Zakład Opieki Zdrowotnej Przychodnia w Sycowie.&nbsp;</TopLink></p>
            <br />
            <h2 tabIndex={0}>Data publikacji i aktualizacji</h2>
            <br />
            <ul>
              <li id="a11y-data-publikacja" >Data publikacji strony internetowej: 2000-11-07,</li>
              <li id="a11y-data-aktualizacja" >Data ostatniej istotnej aktualizacji: 2020-09-21</li>
            </ul>
            <br />
            <h3 tabIndex={0}>Status pod względem zgodności z ustawą</h3>
            <br />
            <p id="a11y-status">Strona internetowa jest częściowo zgodna z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych. Niektóre dokumenty mogą nie posiadać możliwości odczytu za pomocą technologii asystujących. Niektóre zdjęcia mogą nie posiadać opisów alternatywnych.</p>
            <br />
            <p>Strona internetowa jest dostosowana do obsługi za pomocą technologii asystujących.</p>
            <br />
            <h3 tabIndex={0}>Data sporządzenia Deklaracji i metoda oceny dostępności cyfrowej</h3>
            <br />
            <ul>
              <li id="a11y-data-sporzadzenie">Deklarację sporządzono dnia: 2020-11-09,</li>
              <li>Deklarację zaktualizowano dnia: 2022-08-01</li>
            </ul>
            <br />
            <p>Deklarację sporządzono na podstawie samooceny przeprowadzonej przez administratora strony internetowej.</p>
            <br />
            <h3 tabIndex={0}>Dostępność architektoniczna Przychodni:</h3>
            <br />
            <p><strong>SPZOZ „Przychodnia” w Sycowie, ul. Wrocławska 2, 56-500 Syców</strong></p>
            <p>Budynek Przychodni jest dostosowany do osób z niepełnosprawnościami. Do budynku prowadzą trzy wejścia:</p>
            <ul>
              <li>wejście główne od ul. Wrocławskiej - jest dostosowane do potrzeb osób z niepełnosprawnością ruchową, w tym poruszających się na wózku i osób niewidomych lub słabowidzących, posiada rozsuwane automatyczne szerokie drzwi – t<strong>o wejście jest preferowane dla osób z niepełnosprawnością ruchową;</strong></li>
              <li>wejście drugie od ul. Daszyńskiego (parkingu) – jest dostosowane do osób z niepełnosprawnością ruchową, w tym poruszających się na wózku i osób niewidomych lub słabowidzących, posiada samo otwierające (bez potrzeby użycia rąk) się drzwi wejściowe;</li>
              <li>wejście do punktu szczepień Poradni Dziecięcej od ul. Wrocławskiej – jest dostosowane do potrzeb osób z niepełnosprawnością ruchową, w tym poruszających się na wózku i osób niewidomych lub słabowidzących oraz dla matek z wózkami dziecięcymi. Posiada rozsuwane automatyczne szerokie drzwi.</li>
            </ul>
            <p>Do obiektu przychodni można dojechać autem – są wydzielone 2 miejsca parkingowe dostosowane dla osób z niepełnosprawnościami.</p>
            <p>Przy wejściu głównym znajduje się dźwig osobowy, którym można wjechać na I i II piętro przychodni. Osoba z niepełnosprawnościami może uzyskać pomoc w korzystaniu z dźwigu osobowego  -przywołując personel przychodni za pomocą dzwonka. Dźwig nie posiada informacji głosowej. </p>
            <p>Korytarze w przychodni są szerokie i umożliwiają osobom z niepełnosprawnością ruchową poruszającym się na wózkach przemieszczanie się. Są oznaczone strzałkami oznaczającymi kierunek.</p>
            <p>Do budynku można wejść z psem asystującym i psem przewodnikiem.</p>
            <p>Toaleta dla osób z niepełnosprawnościami jest na I piętrze, na lewo od dźwigu osobowego.</p>
            <p>Rejestracja i gabinety Poradni Dziecięcej znajdują się na parterze.</p>
            <p>Rejestracja POZ znajduje się na I piętrze. Gabinety lekarskie są na I i na II piętrze Przychodni.</p>
            <p>W budynku są pętle indukcyjne (rejestracja i gabinet zabiegowy), oraz można skorzystać z tłumacza migowego online.</p>
            <p>Na parterze i I piętrze przychodni są punkty multimedialne wyświetlające informacje w języku migowym oraz głosowym dla osób z niepełnosprawnościami słuchowo-wzrokowym. </p>
            <p>W budynku Przychodni na parterze jest kiosk telemedyczny, w którym można wykonać podstawowe badania:</p>
            <ul>
              <li>Badania, które pacjent może wykonać samodzielnie:</li>
              <ul>
                <li>EKG,</li>
                <li>Pomiar masy ciała,</li>
                <li>Ciśnienie,</li>
                <li>Pomiar temperatury,</li>
                <li>Pomiar saturacji krwi (pulsoksymetr).</li>
              </ul>
              <li>Badania, do których należy wezwać pomoc obsługi ( naciskając  guzik znajdujący się po lewej stronie kiosku telemedycznego):</li>
              <ul>
                <li>Oznaczenie stężenia glukozy we krwi,</li>
                <li>Podstawowe badanie moczu,</li>
                <li>Badania krwi: HDL, cholesterol, trójglicerydy.</li>
              </ul>
            </ul>
            <h3 tabIndex={0}>Skróty klawiaturowe</h3>
            <br />
            <p>Strona internetowa nie jest wyposażona w skróty klawiaturowe, które mogły by wchodzić w konflikt z technologiami asystującymi (np. programy czytające), systemem lub aplikacjami użytkowników.</p>
            <br />
            <h3 tabIndex={0}>Informacje zwrotne i dane kontaktowe</h3>
            <br />
            <p id="a11y-kontakt">W przypadku problemów z dostępnością strony internetowej prosimy o kontakt z sekretariatem: </p>
            <p>
              Telefon: <a id="a11y-telefon" aria-label="Pod numerem telefonu 62 785 51 88" href="tel:627855188">62 785 -51- 88</a>
            </p>
            <p>
              Email: <a id="a11y-email" aria-label="Otwarcie klienta pocztowego" href="mailto:sekretariat@spzozprzychodnia.pl">sekretariat@spzozprzychodnia.pl</a>
            </p>
            <p id="a11y-url">
              Strona internetowa: <TopLink to={RoutePath.HOME}>https://spzozprzychodnia.pl</TopLink>
            </p>
            <p>Tą samą drogą można składać wnioski o udostępnienie informacji niedostępnej oraz składać skargi na brak zapewnienia dostępności.</p>
            <br />
            <h3 tabIndex={0}>Informacje na temat procedury</h3>
            <br />
            <p id="a11y-procedura">Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich elementu. Można także zażądać udostępnienia informacji za pomocą alternatywnego sposobu dostępu, na przykład przez odczytanie niedostępnego cyfrowo dokumentu, opisanie zawartości filmu bez audiodeskrypcji itp. Żądanie powinno zawierać dane osoby zgłaszającej żądanie, wskazanie, o którą stronę internetową lub aplikację mobilną chodzi oraz sposób kontaktu. Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji za pomocą alternatywnego sposobu dostępu, powinna także określić dogodny dla niej sposób przedstawienia tej informacji. Podmiot publiczny powinien zrealizować żądanie niezwłocznie, nie później niż w ciągu 7 dni od dnia wystąpienia z żądaniem. Jeżeli dotrzymanie tego terminu nie jest możliwe, podmiot publiczny niezwłocznie informuje o tym wnoszącego żądanie, kiedy realizacja żądania będzie możliwa, przy czym termin ten nie może być dłuższy niż 2 miesiące od dnia wystąpienia z żądaniem. Jeżeli zapewnienie dostępności cyfrowej nie jest możliwe, podmiot publiczny może zaproponować alternatywny sposób dostępu do informacji. W przypadku, gdy podmiot publiczny odmówi realizacji żądania zapewnienia dostępności lub alternatywnego sposobu dostępu do informacji, wnoszący żądanie może złożyć skargę w sprawie zapewniana dostępności cyfrowej strony internetowej, aplikacji mobilnej lub elementu strony internetowej, lub aplikacji mobilnej. Po wyczerpaniu wskazanej wyżej procedury można także złożyć wniosek do Rzecznika Praw Obywatelskich.</p>
          </StandardTextBlock>
        </Container>
      </section>
    </PageWrapper>
  )
}

export default DeklaracjaDostepnosciPage;