/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { KeyboardEvent  } from 'react';
import { useDispatch } from 'react-redux';
import { setNavbarOpen } from '../../redux/callbacksState/callbacksState.actions';
import './skip-link.scss';


interface Props {
  children: React.ReactNode;
  id: string
}

const SkipLink: React.FC<Props> = (props: Props)=> {
  const { children, id} = props;

  const dispatch = useDispatch();

  const scrollBelowNav = () =>{
    let headerHeight = 0;
    let navHeight = document.querySelector(".navbarBarWrapper");
    if(navHeight) headerHeight = navHeight.clientHeight;
    window.scrollBy(0,-headerHeight)
  }

  const handleOnClick = (e:KeyboardEvent ) =>{
    if(e.key === 'Enter'){

      e.preventDefault();
      const anchor:(HTMLElement | null) = document.querySelector(`#${id}`);
      if(anchor){
        anchor.scrollIntoView();
        anchor.tabIndex = -1;
        anchor.focus();
      }

      switch(id){
        case "mainContent":
          scrollBelowNav();
          break;
        case "navContent":
          dispatch(setNavbarOpen(true))
          break;
        default:
          break;
      }

     

      id === "mainContent" && scrollBelowNav();
      
    }
 
  }

  return (
   <a href='' className='skipLink' aria-hidden={true} onKeyDown={handleOnClick} tabIndex={0}>{children}</a>
  )
}

export default SkipLink