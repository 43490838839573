import React, { useEffect } from "react";
import Helmet from 'react-helmet';
import { useLocation } from "react-router-dom";
import CollapsibleBlock from "../../components/collapsible-block/collapsible-block";
import CollapsibleBlocksWrapper from "../../components/collapsible-block/collapsible-blocks-wrapper";
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import './standardy-chrony-maloletnich.scss';

const StandardyOchronyMaloletnichPage: React.FC<any> = (props: any) => {

  const location = useLocation();
  const patientHash = location.hash; 

  useEffect(() => {
    if(patientHash === ''){
      window.scroll(0,0);
    }else {
      setTimeout(() => {
        const id = patientHash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          let dims = element.getBoundingClientRect();
          let headerHeight = document.querySelector(".navbarBarWrapper")?.clientHeight;
          headerHeight && window.scrollBy(0, dims.top-headerHeight)
        }
      }, 0);
    }
  }, [patientHash]);

  return (
    <PageWrapper className="informacje-dla-pacjenta-page">
      <Helmet>
        <title>{'Standardy Ochrony Małoletnich | SPZOZ Przychodnia Syców'}</title>
      </Helmet>
      <Container>
        <section className="pageWrapper__section">
          <MainHeader>
            STANDARDY OCHRONY MAŁOLETNICH
          </MainHeader>
          <br />
          <CollapsibleBlocksWrapper>
          <CollapsibleBlock
              title="STANDARDY OCHRONY MAŁOLETNICH - załączniki" id='zalaczniki' >
              <StandardTextBlock>
                <p><a aria-label="Standardy Ochrony Małoletnich PDF 854KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/standardy-ochrony-maloletnich/standardy-ochrony-maloletnich.pdf">Standardy Ochrony Małoletnich (854kB)</a></p>
                <p><a aria-label="Karta interwencji - załącznik nr 1 PDF 158KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/standardy-ochrony-maloletnich/karta-interwencji-zalacznik-1.pdf">Karta interwencji - załącznik nr 1 (158kB)</a></p>
                <p><a aria-label="Procedura nr 1 - załącznik nr 2 PDF 196KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/standardy-ochrony-maloletnich/procedura-nr-1-zalacznik-2.pdf">Procedura nr 1 - załącznik nr 2 (196kB)</a></p>
                <p><a aria-label="Procedura nr 2 - załącznik nr 3 PDF 250KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/standardy-ochrony-maloletnich/procedura-nr-2-zalacznik-3.pdf">Procedura nr 2 - załącznik nr 3 (250kB)</a></p>
                <p><a aria-label="Procedura nr 3 - załącznik nr 4 PDF 178KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/standardy-ochrony-maloletnich/procedura-nr-3-zalacznik-4.pdf">Procedura nr 2 - załącznik nr 4 (178kB)</a></p>
                <p><a aria-label="Notatka służbowa - załącznik nr 5 PDF 15KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/standardy-ochrony-maloletnich/notatka sluzbowa-zalacznik-5.pdf">Notatka służbowa - załącznik nr 5 (15kB)</a></p>
                <p><a aria-label="Notatka służbowa - załącznik nr 6 PDF 15KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/standardy-ochrony-maloletnich/notatka sluzbowa-zalacznik-6.pdf">Notatka służbowa - załącznik nr 6 (15kB)</a></p>
                <p><a aria-label="Notatka służbowa - załącznik nr 7 PDF 15KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/standardy-ochrony-maloletnich/notatka sluzbowa-zalacznik-7.pdf">Notatka służbowa - załącznik nr 7 (15kB)</a></p>
                <p><a aria-label="Zawiadomienie o możliwości popełnienia przestępstwa - załącznik nr 8 PDF 21KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/standardy-ochrony-maloletnich/zawiadomienie-o-mozliwosci-popelnienia-przestepstwa-zalacznik-8.pdf">Zawiadomienie o możliwości popełnienia przestępstwa - załącznik nr 8 (21kB)</a></p>
                <p><a aria-label="Wniosek o wgląd w sytuację dziecka - załacznik nr 9 PDF 26KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/standardy-ochrony-maloletnich/wniosek-o-wglad-w-sytuacje-dziecka-zalacznik-9.pdf">Wniosek o wgląd w sytuację dziecka - załacznik nr 9 (26kB)</a></p>
                <p><a aria-label="Niebieska Karta - A - wzór PDF 255KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/standardy-ochrony-maloletnich/niebieska-karta-a-wzor.pdf">Niebieska Karta - A - wzór (255kB)</a></p>
              </StandardTextBlock>
            </CollapsibleBlock>
          </CollapsibleBlocksWrapper>
        </section>
      </Container>
    </PageWrapper>
  )
}

export default StandardyOchronyMaloletnichPage;