import React from "react";
import "./contact-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import Row from "../../components/grid/row";
import Col from "../../components/grid/col";

const ContactPage: React.FC<any> = () => {
  return (
    <PageWrapper className="contact-page">
      <Helmet>
        <title>{"Kontakt | SPZOZ Przychodnia Syców"}</title>
      </Helmet>

      <section className="pageWrapper__section">
        <Container>
          <MainHeader>KONTAKT</MainHeader>
          <br />
          <StandardTextBlock>
            <h2 tabIndex={0}>
              <strong>Dane adresowe:</strong>
            </h2>
            <p>
              Samodzielny Publiczny Zakład Opieki Zdrowotnej “Przychodnia” w Sycowie <br />
              56-500 Syców, ul. Wrocławska 2
            </p>
            <br />
            <p>
              <strong>REGON:</strong> 932952549
            </p>
            <p>
              <strong>NIP:</strong> 911 184 50 99
            </p>
            <p>
              <strong>KRS:</strong> 0000179370
            </p>
            <p>
              <strong>BDO:</strong> 000161066
            </p>
            <p>
              <strong>Nr Rachunku Bankowego:</strong> 61 9584 1076 2007 0700 2607 0001
            </p>
            <br />
            <br />
            <br />
          </StandardTextBlock>
          <Row gap={64}>
            <Col size={[4, 4, 6, 12]}>
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Rejestracja ogólna</strong>
                </h3>
                <ul title="Lista poradni">
                  <li>poradnia ogólna</li>
                  <li>poradnia reumatologiczna</li>
                  <li>poradnia neurologiczna</li>
                  <li>poradnia stomatologiczna</li>
                  <li>poradnia okulistyczna</li>
                </ul>
                <div className="smallBr"></div>
                <a aria-label="Pod numerem telefonu 62 785 51 92" href="tel:627855192">
                  62 785 51 92
                </a>
                <a aria-label="Pod numerem telefonu 62 785 51 45" href="tel:627855145">
                  62 785 51 45
                </a>
                <a aria-label="Pod numerem telefonu 503 622 361" href="tel:503622361">
                  503 622 361
                </a>
                <div className="smallBr"></div>
                <a href="mailto:rejestracjaogolna@spzozprzychodnia.pl">rejestracjaogolna@spzozprzychodnia.pl</a>
              </StandardTextBlock>
              <br />
              <br />
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Poradnia Dziecięca</strong>
                </h3>
                <a aria-label="Pod numerem telefonu 62 785 51 48" href="tel:627855148">
                  62 785 51 48
                </a>
                <a href="mailto:rejestracjadziecieca@spzozprzychodnia.pl">rejestracjadziecieca@spzozprzychodnia.pl</a>
              </StandardTextBlock>
              <br />
              <br />
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Gabinet Zabiegowy</strong>
                </h3>
                <a aria-label="Pod numerem telefonu 62 785 51 95" href="tel:627855195">
                  62 785 51 95
                </a>
                <a href="mailto:zabiegowy@spzozprzychodnia.pl">zabiegowy@spzozprzychodnia.pl</a>
              </StandardTextBlock>
              <br />
              <br />
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Poradnia Dermatologiczna</strong>
                </h3>
                <a aria-label="Pod numerem telefonu 62 785 51 86" href="tel:627855186">
                  62 785 51 86
                </a>
                <a href="mailto:dermatolog@spzozprzychodnia.pl">dermatolog@spzozprzychodnia.pl</a>
              </StandardTextBlock>
              <br />
              <br />
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Poradnia Stomatologiczna</strong>
                </h3>
                <a aria-label="Pod numerem telefonu 62 606 69 82" href="tel:626066982">
                  62 606 69 82
                </a>
              </StandardTextBlock>
            </Col>

            <Col size={[4, 4, 6, 12]}>
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Poradnia Medycyny Pracy</strong>
                </h3>
                <a aria-label="Pod numerem telefonu 519 803 506" href="tel:519803506">
                  519 803 506
                </a>
              </StandardTextBlock>
              <br />
              <br />
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>RTG</strong>
                </h3>
                <a aria-label="Pod numerem telefonu 62 785 51 93" href="tel:627855193">
                  62 785 51 93
                </a>
              </StandardTextBlock>
              <br />
              <br />
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Pielęgniarki Środowiskowo-Rodzinne</strong>
                </h3>
                <a aria-label="Pod numerem telefonu 62 785 51 94" href="tel:627855194">
                  62 785 51 94
                </a>
                <a href="mailto:srodowisko@spzozprzychodnia.pl">srodowisko@spzozprzychodnia.pl</a>
              </StandardTextBlock>
              <br />
              <br />
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Poradnia Ginekologiczno-Położnicza</strong>
                </h3>
                <p>
                  <strong>Położna Środowiskowo-Rodzinna</strong>
                </p>
                <a aria-label="Pod numerem telefonu 62 785 51 47" href="tel:627855147">
                  62 785 51 47
                </a>
                <a href="mailto:pordadniak@spzozprzychodnia.pl">pordadniak@spzozprzychodnia.pl</a>
              </StandardTextBlock>
              <br />
              <br />
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Poradnia Zdrowia Psychicznego</strong>
                </h3>
                <p>
                  <strong>Wydawanie wyników badań i recept</strong>
                </p>
                <a aria-label="Pod numerem telefonu 62 785 51 96" href="tel:627855196">
                  62 785 51 96
                </a>
                <a href="mailto:elaboratorium@spzozprzychodnia.pl">elaboratorium@spzozprzychodnia.pl</a>
              </StandardTextBlock>
              <br />
              <br />
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Poradnia Ortodontyczna</strong>
                </h3>
                <a aria-label="Pod numerem telefonu 62 785 51 90" href="tel:627855190">
                  62 785 51 90
                </a>
                <a href="mailto:ortodoncja@spzozprzychodnia.pl">ortodoncja@spzozprzychodnia.pl</a>
              </StandardTextBlock>
              <br />
              <br />
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Koordynator ds. medycznych</strong>
                </h3>
                <a aria-label="Pod numerem telefonu 531 855 237" href="tel:531855237">
                  531 855 237
                </a>
              </StandardTextBlock>
            </Col>

            <Col size={[4, 4, 6, 12]}>
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Sekretariat</strong>
                </h3>
                <a aria-label="Pod numerem telefonu 62 785 51 88" href="tel:627855188">
                  62 785 51 88
                </a>
                <a href="mailto:sekretariat@spzozprzychodnia.pl">sekretariat@spzozprzychodnia.pl</a>
              </StandardTextBlock>
              <br />
              <br />
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Kadry, Płace</strong>
                </h3>
                <a aria-label="Pod numerem telefonu 62 785 51 87" href="tel:627855187">
                  62 785 51 87
                </a>
                <a href="mailto:jgrenda@spzozprzychodnia.pl">jgrenda@spzozprzychodnia.pl</a>
              </StandardTextBlock>
              <br />
              <br />
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Główna Księgowa</strong>
                </h3>
                <a aria-label="Pod numerem telefonu 62 584 64 16" href="tel:625846416">
                  62 584 64 16
                </a>
                <a href="mailto:epromna@spzozprzychodnia.pl">epromna@spzozprzychodnia.pl</a>
              </StandardTextBlock>
              <br />
              <br />
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>RUM</strong>
                </h3>
                <a aria-label="Pod numerem telefonu 62 785 51 46" href="tel:627855146">
                  62 785 51 46
                </a>
                <a href="mailto:amalecka@spzozprzychodnia.pl">amalecka@spzozprzychodnia.pl</a>
                <a href="mailto:esroka@spzozprzychodnia.pl">esroka@spzozprzychodnia.pl</a>
              </StandardTextBlock>
              <br />
              <br />
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Dyrektor</strong>
                </h3>
                <a aria-label="Pod numerem telefonu 62 785 51 91" href="tel:627855191">
                  62 785 51 91
                </a>
                <a href="mailto:pnogala@spzozprzychodnia.pl">pnogala@spzozprzychodnia.pl</a>
              </StandardTextBlock>
              <br />
              <br />
              <StandardTextBlock>
                <h3 tabIndex={0}>
                  <strong>Wiejski Ośrodek Zdrowia Stradomia Wierzchnia</strong>
                </h3>
                <a aria-label="Pod numerem telefonu 62 785 82 60" href="tel:627858260">
                  62 785 82 60
                </a>
                <a href="mailto:stradomia@spzozprzychodnia.pl">stradomia@spzozprzychodnia.pl</a>
              </StandardTextBlock>
            </Col>
          </Row>
        </Container>
      </section>
    </PageWrapper>
  );
};

export default ContactPage;
