import React from "react";
import "./priv-offices-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import { offices } from "./priv-offices-data";
import OfficeRow from "../../components/row-info/offices-row";

const PrivOfficesPage: React.FC<any> = () => {

  return (
    <PageWrapper className="priv-offices-page">
      <Helmet>
        <title>{'Gabinety prywatne | SPZOZ Przychodnia Syców'}</title>
      </Helmet>

      <section className="pageWrapper__section">
        <Container>
          <MainHeader>GABINETY PRYWATNE</MainHeader>
          {offices.map((office,index)=><OfficeRow key={index} name={office.name} info={office.info}/>)}
        </Container>
      </section>
    </PageWrapper>
  )
}

export default PrivOfficesPage;