import React, { useEffect, useState } from "react";
import './search-page.scss'
import Helmet from 'react-helmet';
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { select_searchPhrase } from "../../redux/callbacksState/callbacksState.selectors";
import { setSearchPhrase } from "../../redux/callbacksState/callbacksState.actions";
import { searchData } from "./search-data";
import TopLink from "../../components/top-link/top-link";


const SearchPage: React.FC<any> = () => {
  const searchPhrase = useSelector(select_searchPhrase, shallowEqual);
  const dispatch = useDispatch();

  // const initialState = [{to:'', title:'', keywords: ''}]
  const [searchResults, setSearchResults] = useState<any>([]);

  useEffect(() => {
    return () => {
      dispatch(setSearchPhrase(''));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
     if(searchPhrase && searchPhrase.length > 0 && searchData.filter((x) => {
      return searchPhrase.split(' ').filter(y => x.keywords.indexOf(y.toLocaleLowerCase()) !== -1).length > 0
    }).length > 0){
      let newData =  searchData.filter((x) => {
        return searchPhrase.split(' ').filter(y => x.keywords.indexOf(y.toLocaleLowerCase()) !== -1).length > 0
      })
      setSearchResults(newData);
    }else setSearchResults([])
  
  }, [searchPhrase]);

  // useEffect(() => {

  // }, [searchPhrase]);

  return (
    <PageWrapper className="rules-page">
      <Helmet>
        <title>{'Wyszukiwarka informacji | SPZOZ Przychodnia Syców'}</title>
      </Helmet>
      <Container>
        <section className="pageWrapper__section">
          <MainHeader tabindex={0} arialabel={`Ilość wyników wyszukiwania: ${searchResults.length}`} >
            Wyniki wyszukiwania
          </MainHeader>
          { searchResults.length > 0 ? searchResults.map((data: { to: string; title: string; keywords: string }, index: React.Key | null | undefined) => {
           return (<div className="searchBlock" key={index}>
             <TopLink to={data.to}>
               <p className="title">{data.title}</p>
             </TopLink>
           </div>)}): <div className="searchBlock no-data"><p>Dla podanej frazy, brak wyników.</p></div>}
        </section>
      </Container>
    </PageWrapper>
  )
}

export default SearchPage;