import { createContext, Dispatch } from "react";

export interface ModalContextDto {
  data?: any;
  setData: Dispatch<any>;
  handleConfirm: () => void;
}

export const ModalContext = createContext<ModalContextDto>({
  data: null,
  setData: () => {},
  handleConfirm: () => {},
});
