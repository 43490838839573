/* eslint-disable jsx-a11y/anchor-is-valid */

import "./prescriptions-page.scss";

export interface CustomerDto {
  firstName: string,
  lastName: string,
  pesel: string,
  street: string,
  city: string,
  postalCode: string,
  phone: string,
  email: string,
  isAcceptedNoProblems: boolean,
  isAcceptedRodo: boolean,
  isAcceptedSmsRecive: boolean,
  isAcceptedSelfRecive: boolean,
}

export interface PrescriptionDto {
  name: string,
  dose: string,
  pillsCountInPackage: string,
  packagesCount: string
}

export interface PrescriptionRequestDto {
  customer: CustomerDto,
  prescriptions: Array<PrescriptionDto>
  message: string,
}


export const initFormValues: PrescriptionRequestDto = {
  customer: {
    firstName: '',
    lastName: '',
    pesel: '',
    street: '',
    city: '',
    postalCode: '',
    phone: '',
    email: '',
    isAcceptedNoProblems: true,
    isAcceptedRodo: true,
    isAcceptedSmsRecive: false,
    isAcceptedSelfRecive: true,
  },
  prescriptions: [],
  message: '',
}

export const isCustomerValid = (customer: CustomerDto, setAlertsActive: React.Dispatch<React.SetStateAction<PrescriptionRequestDto>>) => {
  let isValid = true;
  if ((!customer.firstName) || customer.firstName.length < 1) {
    setAlertsActive(prev => ({ ...prev, firstName: true }))

    isValid = false;
  }
  if ((!customer.lastName) || customer.lastName.length < 1) {
    setAlertsActive(prev => ({ ...prev, lastName: true }))
    isValid = false;

  }
  if ((!customer.pesel) || customer.pesel.length < 1) {
    setAlertsActive(prev => ({ ...prev, pesel: true }))
    isValid = false;
  }
  if ((!customer.street) || customer.street.length < 1) {
    setAlertsActive(prev => ({ ...prev, street: true }))
    isValid = false;;
  }
  if ((!customer.city) || customer.city.length < 1) {
    setAlertsActive(prev => ({ ...prev, city: true }))
    isValid = false;
  }
  if ((!customer.postalCode) || customer.postalCode.length < 1) {
    setAlertsActive(prev => ({ ...prev, postalCode: true }))
    isValid = false;
  }
  if ((!customer.isAcceptedNoProblems) || (!customer.isAcceptedRodo)) {
    isValid = false;
  }
  if (!(customer.isAcceptedSmsRecive || customer.isAcceptedSelfRecive)) {
    isValid = false;
  }
  return isValid;
}