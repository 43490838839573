/* eslint-disable jsx-a11y/anchor-has-content */
import React, { } from "react";
import "./footer.scss";
import Container from "../container/container";

interface Props {
}

const Footer: React.FC<Props> = () => {

  return (
    <>
      <section className="footer">
        <Container>
          <div className="leftCol">© SPZOZ Przychodnia Syców</div>
          <div className="rightCol">Realizacja: <a href="https://ideeas.pl">ideeas</a>&nbsp;{"&"}&nbsp;<a href="https://codeebo.pl">Codeebo</a><p>Dostosowanie do WCAG 2.1: <a href="https://grupaaf.pl/">Grupa AF</a></p> </div>
        </Container>
      </section>
    </>
  )
}

export default Footer;