import React from "react";
import "./pjm-logo.scss";
import PjmLogoImg from "./pjm-icon.svg";

interface Props {

}

const PjmLogo: React.FC<Props> = () => {

  return (
    <div className="pjm-logo">
      <a href="https://tlumacz.migam.org/spzoz_sycow" target="_blank" rel="noopener noreferrer">
        <img src={PjmLogoImg} alt="Tłumacz PJM online - link zewnętrzny - kliknięcie spowoduje otwarcie linku w nowej karcie" />
      </a>
    </div>
  )

}

export default PjmLogo;