export const offices = [
    {
        name: 'Gabinet Lekarski Grażyna Raczkowska-Czajka',
        info: [{
            name: ['Lekarz Medycyny Pracy, Specjalista Reumatolog, Internista'],
            workingDays: ['Wtorek 17:00'],
            contactNumber: [{name:'', number: '603 79 74 76'}],
            officeNumber: '112'
        }]
    },
    {
        name: 'lek.med. Szymon Kucharzak',
        info: [{
            name: ['Internista, Hipertensjolog','USG jamy brzusznej, tarczycy, EKG, pulsoksymetria, holter ciśnieniowy'],
            workingDays: ['Wtorek, Środa od 16:00'],
            contactNumber: [{name:'', number: '726 849 279'}],
            officeNumber: '22'
        }]
    },
    {
        name: 'lek. Ewa Banasik',
        info: [{
            name: ['Endokrynolog'],
            workingDays: ['Raz w miesiącu 9:00-17:00'],
            contactNumber: [{name:'', number: '603 962 033'}],
            officeNumber: '220'
        }]
    },
    {
        name: 'Prywatna Praktyka Lekarska',
        info: [{
            name: ['Dorota Adamczyk – Gajda DIABETOLOG'],
            workingDays: [''],
            contactNumber: [{name:'Zapisy w rejestracji ogólnej', number: ''}],
            officeNumber: '226'
        }]
    },
    {
        name: 'Apteka “Na Zdrowie”',
        info: [{
            name: [''],
            workingDays: ['Poniedziałek-piątek 8:00-18:00'],
            contactNumber: [{name:'', number: '71 707 10 34'}],
            officeNumber: 'parter'
        }]
    },
    {
        name: 'Lek. Wojciech Łapuć',
        info: [{
            name: ['USG, przepływy żylne(DOPPLER)','Umowa na USG: piersi,tarczycy, ślinianek, węzłów chłonnych'],
            workingDays: ['Poniedziałek: od 13:00'],
            contactNumber: [{name:'bez rejestracji', number: ''}],
            officeNumber: '8'
        }]
    },
    {
        name: 'Okulista Jawny Janina',
        info: [{
            name: [''],
            workingDays: ['Środa 14:30'],
            contactNumber: [{name:'', number: '603 139 177'}],
            officeNumber: '226'
        }]
    },
    {
        name: 'RM “Kamil Wawrzyniak”',
        info: [{
            name: [''],
            workingDays: ['Środa 14:30'],
            contactNumber: [{name:'', number: '666 666 230'}],
            officeNumber: 'piwnica'
        }]
    },
    {
        name: 'Prywatny Gabinet Ginekologiczno-Położniczy',
        info: [{
            name: ['lek.med. Wioleta Jadanowska'],
            workingDays: ['Poniedziałek Środa 15:00-19:00'],
            contactNumber: [{name:'', number: '607 238 060'}],
            officeNumber: '203'
        }]
    },
    {
        name: 'Prywatny Gabinet Neurologiczny',
        info: [{
            name: ['Specjalista Neurolog Zbigniew Pietrzak'],
            workingDays: ['Wtorek od 8:00-11:00'],
            contactNumber: [{name:'', number: '505 060 493'}],
            officeNumber: '8'
        }]
    },
    {
        name: 'Prywatny Gabinet Laryngologiczny',
        info: [{
            name: ['dr n. med. Krzysztof Wilczyński'],
            workingDays: ['Rejestracja w poniedziałek – czwartek 17:00 - 19:00'],
            contactNumber: [{name:'', number: '730 296 892'}],
            officeNumber: '204'
        }]
    },
    {
        name: 'Specjalistyczny Gabinet Stomatologiczny',
        info: [{
            name: ['lek.stom. Jan Świtkiewicz'],
            workingDays: ['Poniedziałek, środa 14:00-18:00', 'Piątek 9:00'],
            contactNumber: [{name:'', number: '603 439 015'}],
            officeNumber: '224'
        }]
    },
    {
        name: 'PROMED Gabinet Fizjoterapii',
        info: [{
            name: ['Maciej Wieczorek'],
            workingDays: ['Poniedziałek-piątek 15:30-19:00'],
            contactNumber: [{name:'', number: '669 476 067'}],
            officeNumber: '221'
        }]
    },
    {
        name: 'Gabinet Masażu',
        info: [{
            name: ['technik masażysta Marek Andruschak'],
            workingDays: ['Poniedziałek-piątek 15:00-19:00'],
            contactNumber: [{name:'', number: '721 289 158'}],
            officeNumber: '220'
        }]
    },
    {
        name: 'Prywatny Gabinet Logopedyczny',
        info: [{
            name: ['mgr Małgorzata Ziółkowska'],
            workingDays: ['13:00-19:00'],
            contactNumber: [{name:'', number:  '605 550 360'}],
            officeNumber: '219'
        }]
    },
    {
        name: 'ACS Audika Spółka z o.o.',
        info: [{
            name: [''],
            workingDays: ['Poniedziałek 9:-17:00', 'Środa 8:00-12:30', 'Czwartek 9:00-17:00', 'Piątek 9:00-17:00'],
            contactNumber: [{name:'', number:'71 314 35 97'}],
            officeNumber: '202'
        }]
    },
    {
        name: 'OLMED Aparaty słuchowe',
        info: [{
            name: ['mgr Aleksandra Ostrycharz'],
            workingDays: ['Dwie środy w miesiącu'],
            contactNumber: [{name:'', number: '694 736 056'}],
            officeNumber: '220'
        }]
    },
    {
        name: 'Euro-Med',
        info: [{
            name: ['Rehabilitacja domowa'],
            workingDays: ['Rejestracja telefoniczna'],
            contactNumber: [{name:'', number:'601 817 522'}],
            officeNumber: '8'
        }]
    },
    {
        name: 'Terapia i Edukacja" Specjalistyczna Poradnia dla Dzieci i Młodzieży',
        info: [{
            name: ['mgr Kinga Świderska','Logopeda/neurologopeda','Pedagog specjalny spec. Resocjalizacja','Oligofreno-surdo-tyflopedagog','Terapeuta ASD','Terapeuta pedagogiczny','Terapeuta SI','Neuroterapeuta Biofeedback','Trener WWR i TU'],
            workingDays: ['Piątek 17:00 - 20:00'],
            contactNumber: [{name:'Komórka: ', number:'500 851 802'}],
            officeNumber: ''
        }]
    },
    {
        name: 'Prywatny gabinet okulistyczny',
        info: [{
            name: ['dr n. med. Karolina Karasińska','Rejestracja Telefoniczna'],
            workingDays: ['Poniedziałek 16:00 - 20:00'],
            contactNumber: [{name:'Komórka: ', number:'534 150 190'}],
            officeNumber: '222'
        }]
    },
    {
        name: 'SOVO Aparaty Słuchowe',
        info: [{
            name: ['SOVO Aparaty Słuchowe'],
            workingDays: ['Wtorek 8:00 - 16:00'],
            contactNumber: [{name:'Tel.: ', number:'734 733 130'}],
            officeNumber: 'Gabinet 15 (parter)'
        }]
    },
    {
        name: 'Zakład Kominiarski “FLORIAN”',
        info: [{
            name: ['Zbigniew Weszka'],
            workingDays: ['Poniedziałek-piątek 8:00-15:00', 'Sobota 9:00-12:00'],
            contactNumber: [{name:'Biuro: ', number:' 62 785 20 42'}, {name: 'Dom: ', number:"62 785 67 98"}, {name: "Komórka: ", number:'603 747 048'}],
            officeNumber: '01 Piwnica'
        }]
    },
]