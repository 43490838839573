import React from 'react';
import './row-info.scss';

interface Props {
    name: string;
    info: [{ name: [string], workingDays: [string], contactNumber: [{name:string, number:string}], officeNumber: string }];
}


const OfficeRow: React.FC<any> = ({ name, info }: Props) => {
    return (
        <div className='row-info offices'>
            <div tabIndex={0} className='row-info__name'>
                {name}
            </div>
            {info.map((data, index) => (<div className='row-info__description' key={index}>
                <div className="left">
                    <p>{data.name.map((item) =><span>{item}</span>)}</p>
                    <p>{data.contactNumber.map(i=><span className='standard-text-block'>{i.name} <a href={`tel:${i.number.replace(/\s/g, '')}`} aria-label={`Pod numerem telefonu ${i.number}`}>{i.number}</a></span>)}</p>
                  
                    <p>{data.workingDays.map((item) =><span>{item}</span>)}</p>
                </div>
                <p className='right'>
                    Gabinet: {data.officeNumber}
                </p>
            </div>))}


        </div>
    )
}

export default OfficeRow