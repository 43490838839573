/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import Col from "../../components/grid/col";
import Row from "../../components/grid/row";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import "./public-tenders-page.scss";

const PublicTendersPage: React.FC<any> = () => {
  return (
    <PageWrapper className="public-tenders-page">
      <Helmet>
        <title>{"Przetargi | SPZOZ Przychodnia Syców"}</title>
      </Helmet>

      <section className="pageWrapper__section">
        <Container>
          <MainHeader>PRZETARGI PUBLICZNE / ZAPYTANIA OFERTOWE</MainHeader>
          <Row>
            <Col size={[6, 6, 12, 12]}>

            <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zimowe utrzymanie terenu przy SPZOZ „Przychodnia” w Sycowie (4.10.2024 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe o zimowe utrzymanie teenu przy SPZOZ Przychodnia w Sycowie PDF, 346 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/zimowe-utrzymanie-terenu/zapytanie-ofertowe.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 346 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Mapa terenu przy SPZOZ Przychodnia w Sycowie PDF, 645 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/zimowe-utrzymanie-terenu/plan-spzoz-przychodnia-sycow.pdf" target="_blank">
                      Mapka terenu (PDF, 645 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór umowy PDF, 787 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/zimowe-utrzymanie-terenu/wzor-umowy.pdf" target="_blank">
                      Wzór umowy (PDF, 787 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna DOCX, 18 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/zimowe-utrzymanie-terenu/klauzula-informacyjna.docx" target="_blank">
                      Klauzula informacyjna (DOCX, 18 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Formularz oferty z załącznikami DOC, 89 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/zimowe-utrzymanie-terenu/formularz-ofert-zalaczniki.doc" target="_blank">
                      Formularz oferty z załącznikami (DOC, 89 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p><strong>Ogłoszenie o wyborze oferty</strong></p>
                  <ul>
                    <li> <a aria-label="Ogłoszenie o wyborze oferty - Zimowe utrzymanie terenu przy SPZOZ „Przychodnia” w Sycowie  PDF, 106 KB" href="./do-pobrania/zapytania-ofertowe/2024/zimowe-utrzymanie-terenu/wybor-oferty.pdf" target="_blank">Informacja o wyborze oferty (PDF, 106 KB)</a></li>
                  </ul>
              </StandardTextBlock>

              <hr />

            <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Wykonanie robót budowlanych polegających na remoncie powierzchni dachu budynku SPZOZ „Przychodnia” w Sycowie przy ul. Wrocławskiej 2”. (29.04.2024 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Wykonanie robót budowlanych polegających na remoncie powierzchni dachu budynku SPZOZ „Przychodnia” w Sycowie PDF, 976 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/remont-powierzchni-dachu/Zapytanie-ofertowe-remont-dachu-SPZOZ-Przychodnia-w-Sycowie.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 976 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór umowy Wykonanie robót budowlanych polegających na remoncie powierzchni dachu budynku SPZOZ „Przychodnia” w Sycowie PDF, 274 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/remont-powierzchni-dachu/Wzor-umowy-zalacznik-nr-3.pdf" target="_blank">
                      Wzór umowy (PDF, 274 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa Wykonanie robót budowlanych polegających na remoncie powierzchni dachu budynku SPZOZ „Przychodnia” w Sycowie DOC, 33 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/remont-powierzchni-dachu/FORMULARZ-OFERTOWY.doc" target="_blank">
                      Oferta cenowa (DOC, 33 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna Wykonanie robót budowlanych polegających na remoncie powierzchni dachu budynku SPZOZ „Przychodnia” w Sycowie PDF, 396 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/remont-powierzchni-dachu/Klauzula-informacyjna-RODO-zalacznik-nr-4.pdf" target="_blank">
                      Klauzula informacyjna (PDF, 396 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Kosztorys Wykonanie robót budowlanych polegających na remoncie powierzchni dachu budynku SPZOZ „Przychodnia” w Sycowie PDF, 349 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/remont-powierzchni-dachu/Kosztorys-remont-dachu.pdf" target="_blank">
                      Kosztorys (PDF, 349 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Wykonanie robót budowlanych polegających na remoncie powierzchni dachu budynku SPZOZ „Przychodnia” w Sycowie  PDF, 116 KB" href="./do-pobrania/zapytania-ofertowe/2024/remont-powierzchni-dachu/SPZOZ Przychodnia_20240521_104941.pdf" target="_blank">
                      Wybór oferty (PDF, 116 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

            <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Utrzymanie terenu zielonego przy SPZOZ „Przychodnia” w Sycowie (15.02.2024 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe Utrzymanie terenu zielonego przySPZOZ „Przychodnia” w Sycowie PDF, 285 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/utrzymanie-terenu-zielonego/Zapytanie ofertowe - utrzymanie terenu zielonego 2024.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 285 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór umowy Utrzymanie terenu zielonego przy SPZOZ „Przychodnia” w Sycowie PDF, 808 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/utrzymanie-terenu-zielonego/umowa - wzor 2024.pdf" target="_blank">
                      Wzór umowy (PDF, 808 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa Utrzymanie terenu zielonego przy SPZOZ „Przychodnia” w Sycowie DOC, 85 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/utrzymanie-terenu-zielonego/Formularz oferty cenowej-zalaczniki-2024.doc" target="_blank">
                      Oferta cenowa (DOC, 85 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna Utrzymanie terenu zielonego przy SPZOZ „Przychodnia” w Sycowie DOCX, 18 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/utrzymanie-terenu-zielonego/Klauzula-informacyjna-Zapytanie-ofertowe.docx" target="_blank">
                      Klauzula informacyjna (DOCX, 18 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Załącznik nr 1 Utrzymanie terenu zielonego przy SPZOZ „Przychodnia” w Sycowie DOC, 69 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/utrzymanie-terenu-zielonego/zalacznik nr 1 do oferty 2024.doc" target="_blank">
                      Załącznik nr 1 (DOC, 69 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Mapa terenu zielonego Utrzymanie terenu zielonego przy SPZOZ „Przychodnia” w Sycowie PDF, 616 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/utrzymanie-terenu-zielonego/mapa-terenu-zielonego-SPZOZ.pdf" target="_blank">
                    Mapa terenu zielonego (PDF, 616 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Utrzymanie terenu zielonego przy SPZOZ „Przychodnia” w Sycowie  PDF, 113 KB" href="./do-pobrania/zapytania-ofertowe/2024/utrzymanie-terenu-zielonego/protokol-wyboru-oferty.pdf" target="_blank">
                      Wybór oferty (PDF, 113 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

            <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Konserwacja klimatyzacji zamontowanych w SPZOZ „Przychodnia” w Sycowie. (19.01.2023 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe Konserwacja klimatyzacji zamontowanych w SPZOZ „Przychodnia” w Sycowie PDF, 364 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/konserwacja-klimatyzacji/Zapytanie-ofertowe-2024.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 364 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa Konserwacja klimatyzacji zamontowanych w SPZOZ „Przychodnia” w Sycowie DOCX, 18 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/konserwacja-klimatyzacji/Oferta-cenowa-konserwacja-klimatyzacji-2024.docx" target="_blank">
                      Oferta cenowa (DOCX, 18 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna Konserwacja klimatyzacji zamontowanych w SPZOZ „Przychodnia” w Sycowie DOCX, 18 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/konserwacja-klimatyzacji/Klauzula-informacyjna-Zapytanie-ofertowe.docx" target="_blank">
                      Klauzula informacyjna (DOCX, 18 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Konserwacja klimatyzacji zamontowanych w SPZOZ „Przychodnia” w Sycowie  PDF, 159 KB" href="./do-pobrania/zapytania-ofertowe/2024/konserwacja-klimatyzacji/SPZOZ-Przychodnia_20240129_121641.pdf" target="_blank">
                      Wybór oferty (PDF, 159 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

            <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Wykonanie remontu pomieszczeń w SPZOZ „Przychodnia” w Sycowie (10.01.2023 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe Wykonanie remontu pomieszczeń w SPZOZ „Przychodnia” w Sycowie PDF, 321 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/remont-pomieszczen/Zapytanie-ofertowe.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 321 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa Wykonanie remontu pomieszczeń w SPZOZ „Przychodnia” w Sycowie DOC, 34 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/remont-pomieszczen/oferta_wykonanie-szatnia.doc" target="_blank">
                      Oferta (DOC, 34 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Umowa wzór Wykonanie remontu pomieszczeń w SPZOZ „Przychodnia” w Sycowie PDF, 512 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/remont-pomieszczen/Umowa- wzor.pdf" target="_blank">
                      Umowa wzór (PDF, 512 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Kosztorys budowlany nakładczy Wykonanie remontu pomieszczeń w SPZOZ „Przychodnia” w Sycowie PDF, 298 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/remont-pomieszczen/Kosztorys-budowlany-nakladczy.pdf" target="_blank">
                    Kosztorys budowlany nakładczy (PDF, 298 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna Wykonanie remontu pomieszczeń w SPZOZ „Przychodnia” w Sycowie DOCX, 18 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2024/remont-pomieszczen/Klauzula-informacyjna-Zapytanie-ofertowe.docx" target="_blank">
                    Klauzula informacyjna (DOCX, 18 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Wykonanie remontu pomieszczeń w SPZOZ „Przychodnia” w Sycowie  PDF, 124 KB" href="./do-pobrania/zapytania-ofertowe/2024/remont-pomieszczen/SPZOZ-Przychodnia_20240126_093401.pdf" target="_blank">
                      Wybór oferty (PDF, 124 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

            <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Wdrożenie i zainstalowanie bota głosowego w SPZOZ „Przychodnia” w Sycowie</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe Wdrożenie i zainstalowanie bota głosowego w SPZOZ „Przychodnia” w Sycowie PDF, 561 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/bot-glosowy/Zapytanie-ofertowe.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 561 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa Wdrożenie i zainstalowanie bota głosowego w SPZOZ „Przychodnia” w Sycowie PDF, 794 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/bot-glosowy/Wzor-umowy.pdf" target="_blank">
                      Wzór umowy (PDF, 794 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa Wdrożenie i zainstalowanie bota głosowego w SPZOZ „Przychodnia” w Sycowie PDF, 381 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/bot-glosowy/Klauzula-informacyjna.pdf" target="_blank">
                      Klauzula informacyjna (PDF, 381 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Wdrożenie i zainstalowanie bota głosowego w SPZOZ „Przychodnia” w Sycowie  PDF, 122 KB" href="./do-pobrania/zapytania-ofertowe/2023/bot-glosowy/protokol-wdrozenia-bota-glosowego.pdf" target="_blank">
                      Wybór oferty (PDF, 122 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />
              
            <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zamontowanie 2 kurtyn powietrznych z funkcją grzania nad drzwiami wyjściowymi w SPZOZ „Przychodnia” w Sycowie</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe Zamontowanie 2 kurtyn powietrznych z funkcją grzania nad drzwiami wyjściowymi w SPZOZ „Przychodnia” w Sycowie PDF, 218 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/kurtyny-powietrzne/Zapytanie-ofertowe.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 218 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa Zamontowanie 2 kurtyn powietrznych z funkcją grzania nad drzwiami wyjściowymi w SPZOZ „Przychodnia” w Sycowie DOC, 33 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/kurtyny-powietrzne/oferta_2023.doc" target="_blank">
                      Oferta cenowa (DOC, 83 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                  <a aria-label="Wybór oferty - Zamontowanie 2 kurtyn powietrznych z funkcją grzania nad drzwiami wyjściowymi w SPZOZ „Przychodnia” w Sycowie PDF, 132 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/kurtyny-powietrzne/protokol-zamontowania-dwoch-kurtyn.pdf" target="_blank">
                      Wybór oferty (PDF, 132 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zimowe utrzymanie terenu przy SPZOZ „Przychodnia” w Sycowie (30.10.2023 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="apytanie ofertowe o zimowe utrzymanie teenu przy SPZOZ Przychodnia w Sycowie PDF, 337 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/zimowa-pielegnacja/zapytanie ofertowe 30.10.2023 - zimowe utrzymanie terenu 2023.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 337 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Mapa terenu przy SPZOZ Przychodnia w Sycowie PDF, 520 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/zimowa-pielegnacja/Mapa-terenu-SPZOZ-Przychodnia-w-Sycowie.pdf" target="_blank">
                      Mapka terenu (PDF, 520 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór umowy PDF, 454 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/zimowa-pielegnacja/Wzor-umowy-zimowe-utrzymanie-2023.pdf" target="_blank">
                      Wzór umowy (PDF, 454 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna DOCX, 18 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/zimowa-pielegnacja/Klauzula-informacyjna-Zapytanie-ofertowe-zima-2023.docx" target="_blank">
                      Klauzula informacyjna (DOC, 90 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Formularz oferty z załącznikami DOC, 18 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/zimowa-pielegnacja/Formularz-oferty-zalaczniki-2023.doc" target="_blank">
                      Formularz oferty z załącznikami (DOC, 90 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p><strong>Ogłoszenie o nierozstrzygnięciu</strong></p>
                  <ul>
                    <li> <a aria-label="Ogłoszenie o nierozstrzygnięciu- Zimowe utrzymanie terenu przy SPZOZ „Przychodnia” w Sycowie  PDF, 134 KB" href="./do-pobrania/zapytania-ofertowe/2023/zimowa-pielegnacja/Zawiadomienie o nierozstrzygnięciu.pdf" target="_blank">Informacja o nierozstrzygnięciu (PDF, 134 KB)</a></li>
                  </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zimowe utrzymanie terenu przy SPZOZ „Przychodnia” w Sycowie (17.10.2023 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="apytanie ofertowe o zimowe utrzymanie teenu przy SPZOZ Przychodnia w Sycowie PDF, 328 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/zimowa-pielegnacja/Zapytanie-ofertowe-zimowe-utrzymanie-terenu-2023.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 328 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Mapa terenu przy SPZOZ Przychodnia w Sycowie PDF, 520 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/zimowa-pielegnacja/Mapa-terenu-SPZOZ-Przychodnia-w-Sycowie.pdf" target="_blank">
                      Mapka terenu (PDF, 520 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór umowy PDF, 454 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/zimowa-pielegnacja/Wzor-umowy-zimowe-utrzymanie-2023.pdf" target="_blank">
                      Wzór umowy (PDF, 454 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna DOCX, 18 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/zimowa-pielegnacja/Klauzula-informacyjna-Zapytanie-ofertowe-zima-2023.docx" target="_blank">
                      Klauzula informacyjna (DOC, 90 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Formularz oferty z załącznikami DOC, 18 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/zimowa-pielegnacja/Formularz-oferty-zalaczniki-2023.doc" target="_blank">
                      Formularz oferty z załącznikami (DOC, 90 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o nierozstrzygnięciu</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Ogłoszenie o nierozstrzygnięciu- Zimowe utrzymanie terenu przy SPZOZ „Przychodnia” w Sycowie  PDF, 135 KB" href="./do-pobrania/zapytania-ofertowe/2023/zimowa-pielegnacja/SPZOZ Przychodnia_20231030_133348.pdf" target="_blank">
                      Informacja o nierozstrzygnięciu (PDF, 135 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Modernizacja wejścia do Poradni Dermatologicznej w SPZOZ „Przychodnia” w Sycowie. (22.03.2023 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Specyfikacja Modernizacja wejścia do Poradni DermatologicznejSPZOZ Przychodnia w Sycowie PDF, 2.2 MB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/roboty-budowlane/SIWZ - Roboty budowlane.pdf" target="_blank">
                      Specyfikacja (PDF, 2,2MB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Kosztorys budowlany Modernizacja wejścia do Poradni Dermatologicznej SPZOZ Przychodnia w Sycowie PDF, 243 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/roboty-budowlane/Kosztorys budowlany.pdf" target="_blank">
                      Kosztorys budowlany (PDF, 243 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór umowy PDF, 267 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/roboty-budowlane/umowa-wzor.pdf" target="_blank">
                      Wzór umowy (PDF, 267 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Modernizacja wejścia do Poradni Dermatologicznej w SPZOZ „Przychodnia” w Sycowie  PDF, 133 KB" href="./do-pobrania/zapytania-ofertowe/2023/roboty-budowlane/SPZOZ-Przychodnia_20230411_075835.pdf" target="_blank">
                      Wybór oferty (PDF, 133 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe: Dostawa i montaż paneli fotowoltaicznych na budynku SPZOZ „Przychodnia” w Sycowie (16.03.2023 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe Dostawa i montaż paneli fotowoltaicznych na budynku SPZOZ Przychodnia w Sycowie PDF, 840 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/montaz-fotowoltaiki/zapytanie-ofertowe.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 840 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa Dostawa i montaż paneli fotowoltaicznych na budynku SPZOZ Przychodnia w Sycowie DOCX, 36 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/montaz-fotowoltaiki/zalacznik-1-2-3-do-oferty.docx" target="_blank">
                      Oferta cenowa (DOCX, 36 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór umowy PDF, 571 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/montaz-fotowoltaiki/wzor-umowy-zalacznik-4-do-oferty.pdf" target="_blank">
                      Wzór umowy (PDF, 571 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Dostawa i montaż paneli fotowoltaicznych na budynku SPZOZ „Przychodnia” w Sycowie  PDF, 129 KB" href="./do-pobrania/zapytania-ofertowe/2023/montaz-fotowoltaiki/SPZOZ Przychodnia_20230331_074548.pdf" target="_blank">
                      Wybór oferty (PDF, 129 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Utrzymanie terenu zielonego przy budynku SPZOZ „Przychodnia” w Sycowie ( 6.02.2023 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe Utrzymanie terenu zielonego przy budynku SPZOZ Przychodnia w Sycowie PDF, 276 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/utrzymanie-terenu-zielonego/Zapytanie ofertowe utrzymanie zieleni 2023.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 276 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa utrzymanie terenu zielonego przy budynku SPZOZ Przychodnia w Sycowie DOC, 83 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/utrzymanie-terenu-zielonego/Formularz oferty cenowe zalaczniki.doc" target="_blank">
                      Oferta cenowa (DOC, 83 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór umowy PDF, 756 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/utrzymanie-terenu-zielonego/Wzor umowy.pdf" target="_blank">
                      Wzór umowy (PDF, 756 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Kaluzula informacyjna utrzymanie terenu zielonego przy budynku SPZOZ Przychodnia w Sycowie DOCX, 18 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/utrzymanie-terenu-zielonego/Klauzula-informacyjna-Zapytanie-ofertowe.docx" target="_blank">
                      Klauzula Informacyjna (DOCX, 18 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Załącznik nr 1 utrzymanie terenu zielonego przy budynku SPZOZ Przychodnia w Sycowie DOC, 70 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/utrzymanie-terenu-zielonego/Zalacznik nr 1 do oferty.doc" target="_blank">
                      Załącznik nr 1 (DOC, 70 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Mapa Terenu przy SPZOZ Przychodnia w Sycowie PDF, 582 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/utrzymanie-terenu-zielonego/SPZOZ Przychodnia_20230209_094257.pdf" target="_blank">
                      Mapa terenu (PDF, 582 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Utrzymanie terenu zielonego przy budynku SPZOZ „Przychodnia” w Sycowie  PDF, 115 KB" href="./do-pobrania/zapytania-ofertowe/2023/utrzymanie-terenu-zielonego/protokol-z-wyboru-oferty.pdf" target="_blank">
                      Wybór oferty (PDF, 115 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe na konserwację klimatyzacji zamontowanych w SPZOZ „Przychodnia” w Sycowie (3.02.2023)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe konserwacja klimatyzacji w SPZOZ Przychodnia w Sycowie PDF, 308 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/konserwacja-klimatyzacji/Zapytanie ofertowe.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 308 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa konserwacja klimatyzacji w SPZOZ Przychodnia w Sycowie DOCX, 17 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/konserwacja-klimatyzacji/Oferta cenowa - konserwacja klimatyzacji 2023.docx" target="_blank">
                      Oferta cenowa (DOCX, 17 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna DOCX, 18 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/konserwacja-klimatyzacji/Klauzula-informacyjna-Zapytanie-ofertowe.docx" target="_blank">
                      Klauzula informacyjna (DOCX, 18 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Konkurs ofert na konserwację klomatyzacji zamontowanych w SPZOZ „Przychodnia” w Sycowie  PDF, 163 KB" href="./do-pobrania/zapytania-ofertowe/2023/konserwacja-klimatyzacji/SPZOZ Przychodnia_20230213_131007.pdf" target="_blank">
                      Wybór oferty (PDF, 163 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>UWAGA! Zamówienie nierozstrzygnięte! - Zapytanie ofertowe: Malowanie pomieszczeń w SPZOZ „Przychodnia” w Sycowie ( 03.01.2023 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe malowanie pomieszczeń w SPZOZ Przychodnia w Sycowie PDF, 133 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/malowanie-pomieszczen/SPZOZ Przychodnia_20230118_140216.pdf" target="_blank">
                      Zawiadomienie o nierozstrzygnięciu (PDF, 133 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe malowanie pomieszczeń w SPZOZ Przychodnia w Sycowie DOC, 40 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/malowanie-pomieszczen/Ogłoszenie malowanie 2023.doc" target="_blank">
                      Zapytanie ofertowe (DOC, 40 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa malowanie pomieszczeń w SPZOZ Przychodnia w Sycowie DOC, 34 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/malowanie-pomieszczen/oferta_wykonanie malowania 2023 (002).doc" target="_blank">
                      Oferta cenowa (DOC, 34 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna DOCX, 18 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2023/malowanie-pomieszczen/Klauzula-informacyjna-Zapytanie-ofertowe.docx" target="_blank">
                      Klauzula informacyjna (DOCX, 18 KB)
                    </a>
                  </li>
                </ul>
                <br />
                {/* <p><strong>Ogłoszenie o wyborze oferty</strong></p>
                  <ul>
                    <li><a aria-label="Wybór oferty - Konkurs ofert na udzielenie świadczeń zdrowotnych w zakresie badań laboratoryjnych w SPZOZ „Przychodnia” w Sycowie  PDF, 108 KB" href="./do-pobrania/zapytania-ofertowe/2022/konkurs/SPZOZ Przychodnia_20221201_080202.pdf" target="_blank">Wybór oferty (PDF, 108 KB)</a></li>
                  </ul> */}
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Konkurs ofert na udzielenie świadczeń zdrowotnych w zakresie badań laboratoryjnych w SPZOZ „Przychodnia” w Sycowie ( 14.11.2022 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Regulamin konkursu ofert PDF, 517 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/konkurs/Regulamin konkursu ofert.pdf" target="_blank">
                      Regulamin konkursu ofert (PDF, 517 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Szczegółowe warunki konkursu PDF, 475 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/konkurs/Szczegolowe warunki konkursu.pdf" target="_blank">
                      Szczegółowe warunki konkursu (PDF, 475 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Dane oferenta PDF, 29 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/konkurs/Dane oferenta.pdf" target="_blank">
                      Dane oferenta (PDF, 29 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa PDF, 500 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/konkurs/Oferta cenowa.pdf" target="_blank">
                      Oferta cenowa (PDF, 500 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oświadczenie PDF, 27 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/konkurs/Oswiadczenie.pdf" target="_blank">
                      Oświadczenie (PDF, 27 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna PDF, 57 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/konkurs/Klauzula informacyjna.pdf" target="_blank">
                      Klauzula informacyjna (PDF, 57 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór umowy na wykonywanie badan laboratoryjnych PDF, 138 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/konkurs/Wzor umowy na wykonywanie badan laboratoryjnych.pdf" target="_blank">
                      Wzór umowy na wykonywanie badań laboratoryjnych (PDF, 138 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór umowy najmu lokalu PDF, 130 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/konkurs/Wzor umowy najmu lokalu.pdf" target="_blank">
                      Wzór umowy najmu lokalu (PDF, 130 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Konkurs ofert na udzielenie świadczeń zdrowotnych w zakresie badań laboratoryjnych w SPZOZ „Przychodnia” w Sycowie  PDF, 108 KB" href="./do-pobrania/zapytania-ofertowe/2022/konkurs/SPZOZ Przychodnia_20221201_080202.pdf" target="_blank">
                      Wybór oferty (PDF, 108 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zimowe utrzymanie terenu przy SPZOZ „Przychodnia” w Sycowie (11.10.2022 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe na: Zimowe utrzymanie terenu zielonego przy SPZOZ Przychodnia w Sycowie PDF, 322 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/zimowe-utrzymanie/zapytanie-ofertowe-zimowe-utrzymanie-terenu.pdf" target="_blank">
                      Zapytanie ofertowe na: Zimowe utrzymanie terenu zielonego przy SPZOZ "Przychodnia" w Sycowie (PDF, 322 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Plan terenu zielonego do utrzymania PDF, 645 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/zimowe-utrzymanie/Plan-SPZOZ-Przychodnia-w-Sycowie.pdf" target="_blank">
                      Plan terenu zielonego przy SPZOZ "Przychodnia" w Sycowie (PDF, 645 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór umowy PDF, 441 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/zimowe-utrzymanie/wzor-umowy-zapytanie-ofertowe.pdf" target="_blank">
                      Wzór umowy (PDF, 441 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Formularz oferty z załącznikami DOC, 90 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/zimowe-utrzymanie/formularz-ofert-plus-zalaczniki.doc" target="_blank">
                      Formularz oferty wraz z załącznikami (DOC, 90 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna PDF, 560 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/zimowe-utrzymanie/Klauzula-informacyjna-Zapytanie-ofertowe.pdf" target="_blank">
                      Klauzula informacyjna (PDF, 560 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Zimowe utrzymanie terenu zielonego przy SPZOZ Przychodnia w Sycowie  PDF, 135 KB" href="./do-pobrania/zapytania-ofertowe/2022/zimowe-utrzymanie/SPZOZ Przychodnia_20221103_135516.pdf" target="_blank">
                      Wybór oferty (PDF, 135 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe na: wymianę opraw oświetleniowych wewnętrznych starego typu na oprawy led w SPZOZ „Przychodnia” w Sycowie przy ul. Wrocławskiej 2 ( 17.06.2022 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe na: wymianę opraw oświetleniowych wewnętrznych starego typu na oprawy led PDF, 610 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/wymiana-opraw-oswietleniowych/Zapytanie ofertowe(2).pdf" target="_blank">
                      Zapytanie ofertowe na: wymianę opraw oświetleniowych wewnętrznych starego typu na oprawy led w SPZOZ „Przychodnia” w Sycowie (PDF, 610 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta Cenowa DOC, 37 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/wymiana-opraw-oswietleniowych/oferta-zalacznik-1.doc" target="_blank">
                      Oferta cenowa (DOC, 37 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Umowa na wymianę opraw oświtlenia PDF, 463 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/wymiana-opraw-oswietleniowych/Umowa oswietlenie.pdf" target="_blank">
                      Umowa na wymianę opraw oświetlenia (PDF, 463 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna PDF, 560 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/wymiana-opraw-oswietleniowych/Klauzula-informacyjna-Zapytanie-ofertowe (1).pdf" target="_blank">
                      Klauzula informacyjna (PDF, 560 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - wymianę opraw oświetleniowych wewnętrznych starego typu na oprawy led PDF, 139 KB" href="./do-pobrania/zapytania-ofertowe/2022/wymiana-opraw-oswietleniowych/SPZOZ Przychodnia_20220630_124716.pdf" target="_blank">
                      Wybór oferty (PDF, 139 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe na wykonanie montażu drzwi ewakuacyjnych w wejściu głównym SPZOZ „Przychodnia” w Sycowie (13.06.2022 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe motaż drzwi ewakuacyjnych PDF, 214 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/drzwi-ewakuacyjne/Zapytanie ofertowe - montaz drzwi ew akuacyjnych w SPZOZ Przychodania.pdf" target="_blank">
                      Zapytanie ofertowe montaż drzwi ewakuacyjnych w SPZOZ Przychodnia w Sycowie (PDF, 214 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta Cenowa Wykonanie Drzwi DOC, 33 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/drzwi-ewakuacyjne/oferta_wykonanie drzwi 2022 (002).doc" target="_blank">
                      Oferta cenowa na wykonanie drzwi (DOC, 33 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Kosztorys budowlany nakładczy PDF, 287 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/drzwi-ewakuacyjne/Kosztorys budowalany nakladczy.pdf" target="_blank">
                      Kosztorys budowlany nakładczy (PDF, 287 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna PDF, 560 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/drzwi-ewakuacyjne/Klauzula-informacyjna-Zapytanie-ofertowe.pdf" target="_blank">
                      Klauzula informacyjna (PDF, 560 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Protokół z wyboru oferty PDF, 145 KB" href="./do-pobrania/zapytania-ofertowe/2022/drzwi-ewakuacyjne/Protokol(3).pdf" target="_blank">
                      Protokół z wyboru oferty (PDF, 145 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Wymiana drzwi zewnętrznych na drzwi automatyczne/balansowe w SPZOZ „Przychodnia” w Sycowie od ul. Daszyńskiego (24.03.2022 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe PDF, 636 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/zapytanie-ofertowe-drzwi-rzesuwne/zapytanie-ofertowe-wymiana-drzwi.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 636 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Zapytanie ofertowe - wersja gotowa do odczytu maszynowego PDF, 176 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/zapytanie-ofertowe-drzwi-rzesuwne/zapytanie-ofertowe-wymiana-drzwi-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Zapytanie ofertowe - wersja gotowa do odczytu maszynowego (PDF, 176 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna PDF, 547 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/zapytanie-ofertowe-drzwi-rzesuwne/Klauzula-informacyjna-Zapytanie-ofertowe.pdf" target="_blank">
                      Klauzula informacyjna (PDF, 547 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Wymiana drzwi zewnętrznych na drzwi automatyczne/balansowe w SPZOZ „Przychodnia” w Sycowie PDF, 107 KB" href="./do-pobrania/zapytania-ofertowe/2022/zapytanie-ofertowe-drzwi-rzesuwne/wybor-oferty.pdf" target="_blank">
                      Protokół z wyboru oferty (PDF, 107 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe na wykonanie i zamontowanie mebli dla SPZOZ "Przychodnia” w Sycowie” (11.02.2022 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe DOC, 43 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/wykonanie-montaz-mebli/zapytanie-ofertowe-2022.doc" target="_blank">
                      Zapytanie ofertowe (DOC, 43 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa (DOC, 33 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/wykonanie-montaz-mebli/ogloszenie-meble-oferta.doc" target="_blank">
                      Oferta cenowa (DOC, 33 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna PDF, 547 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/wykonanie-montaz-mebli/klauzula-informacyjna.pdf" target="_blank">
                      Klauzula informacyjna (PDF, 547 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Wykonanie i zamontowanie mebli dla SPZOZ Przychodnia w Sycowie PDF, 133 KB" href="./do-pobrania/zapytania-ofertowe/2022/wykonanie-montaz-mebli/Protokol-Wykonanie-i-montaz-mebli-do-rejestracji-i-poczekalni-w-SPZOZ-Przychodnia-w-Sycowie.pdf">
                      Wybór oferty - Wykonanie i zamontowanie mebli dla SPZOZ "Przychodnia” w Sycowie” (PDF, 133 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wybór oferty - Wykonanie i zamontowanie mebli dla SPZOZ Przychodnia w Sycowie - wersja gotowa do odczytu maszynowego PDF, 129 KB" href="./do-pobrania/zapytania-ofertowe/2022/wykonanie-montaz-mebli/Protokol-Wykonanie-i-montaz-mebli-do-rejestracji-i-poczekalni-w-SPZOZ-Przychodnia-w-Sycowie-wersja gotowa do odczytu maszynowego.pdf">
                      Wybór oferty - Wykonanie i zamontowanie mebli dla SPZOZ "Przychodnia” w Sycowie” - wersja gotowa do odczytu maszynowego (PDF, 129 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe na Utrzymanie terenu zielonego przy SPZOZ 'Przychodnia" w Sycowie (9.02.2022 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe PDF, 240 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/utrzymanie-terenu-zielonego/zapytanie-ofertowe-na-utrzymanie-terenu-zielonego.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 240 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Zapytanie ofertowe - wersja gotowa do odczytu maszynowego PDF, 153 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/utrzymanie-terenu-zielonego/zapytanie-ofertowe-na-utrzymanie-terenu-zielonego-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Zapytanie ofertowe - wersja gotowa do odczytu maszynowego (PDF, 153 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Załącznik nr 1 do Oferty DOC, 68,5 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/utrzymanie-terenu-zielonego/zalacznik-nr-1.doc" target="_blank">
                      Załącznik nr 1 do Oferty (DOC, 68,5 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Formularz oferty cenowej wraz z załącznikami DOC, 82 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/utrzymanie-terenu-zielonego/formularz-oferty-zalaczniki.doc" target="_blank">
                      Formularz oferty cenowej wraz z załącznikami (DOC, 82 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna PDF, 547 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/utrzymanie-terenu-zielonego/klauzula-informacyjna-utrzymanie-zieleni.pdf" target="_blank">
                      Klauzula informacyjna (PDF, 547 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Umowa na Utrzymanie terenu zielonego PDF, 232 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/utrzymanie-terenu-zielonego/umowa-na-utrzymanie-terenu-zielonego.pdf" target="_blank">
                      Umowa na Utrzymanie terenu zielonego (PDF, 232 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Umowa na Utrzymanie terenu zielonego - wersja gotowa do odczytu maszynowego PDF, 191 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/utrzymanie-terenu-zielonego/umowa-na-utrzymanie-terenu-zielonego-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Umowa na Utrzymanie terenu zielonego - wersja gotowa do odczytu maszynowego (PDF, 191 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty Utrzymanie terenu zielonego przy SPZOZ Przychodnia w Sycowie PDF, 120 KB" href="./do-pobrania/zapytania-ofertowe/2022/utrzymanie-terenu-zielonego/Protokol-utrzymanie-terenu-zielone-go-w-SPZOZ-Przychodnia-w-Sycowie.pdf">
                      Wybór oferty - Utrzymanie terenu zielonego przy SPZOZ 'Przychodnia" w Sycowie (PDF, 120 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wybór oferty Utrzymanie terenu zielonego przy SPZOZ Przychodnia w Sycowie - wersja gotowa do odczytu maszynowego PDF, 152 KB" href="./do-pobrania/zapytania-ofertowe/2022/utrzymanie-terenu-zielonego/Protokol-utrzymanie-terenu-zielone-go-w-SPZOZ-Przychodnia-w-Sycowie-wersja gotowa do odczytu maszynowego.pdf">
                      Wybór oferty - Utrzymanie terenu zielonego przy SPZOZ 'Przychodnia" w Sycowie - wersja gotowa do odczytu maszynowego (PDF, 152 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe na zakup i zamontowanie 2 klimatyzatorów w gabinetach w SP ZOZ „Przychodnia” w Sycowie (31.01.2022)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe PDF, 136 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/zakup-klimatyzatorow/Klimatyzatory-2-szt.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 136 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa DOC, 32,5 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/zakup-klimatyzatorow/oferta-konserwacja-klimatyzacji.doc" target="_blank">
                      Oferta cenowa (DOC, 32,5 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula Informacyjna PDF, 547 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/zakup-klimatyzatorow/Klauzula-informacyjna-Zapytanie-ofertowe.pdf" target="_blank">
                      Klauzula Informacyjna (PDF, 547 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Zakup i zamontowanie 2 klimatyzatorów w gabinetach w SP ZOZ Przychodnia w Sycowie PDF, 115 KB" href="./do-pobrania/zapytania-ofertowe/2022/zakup-klimatyzatorow/Protokol-Dostawa-i-montaz-2-ch-klimatyzatorow-w-SPZOZ-Przychodnia-w-Sycowie.pdf">
                      Wybór oferty - Zakup i zamontowanie 2 klimatyzatorów w gabinetach w SP ZOZ „Przychodnia” w Sycowie (PDF, 115 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe na konserwację klimatyzacji zamontowanych w SP ZOZ „Przychodnia” w Sycowie (31.01.2021)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe PDF, 179 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/konserwacja-klimatyzacji/Konserwacja-klimatyzatorow.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 179 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Zapytanie ofertowe gotowe do odczytu maszynowego PDF, 122 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/konserwacja-klimatyzacji/Konserwacja-klimatyzatorow-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 122 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa DOC, 44 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/konserwacja-klimatyzacji/oferta konserwacja klimatyzacji.doc" target="_blank">
                      Oferta cenowa (DOC, 44 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula Informacyjna PDF, 547 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/2022/konserwacja-klimatyzacji/Klauzula-informacyjna-Zapytanie-ofertowe.pdf" target="_blank">
                      Klauzula Informacyjna (PDF, 547 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Konserwacja klimatyzacji zamontowanych w budynku SPZOZ Przychodnia w Sycowie PDF, 110 KB" href="./do-pobrania/zapytania-ofertowe/2022/konserwacja-klimatyzacji/protokol-konserwacja-klimatyzacji.pdf">
                      Wybór oferty - Konserwacja klimatyzacji zamontowanych w budynku SPZOZ "Przychodnia" w Sycowie (PDF, 110 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wybór oferty - Konserwacja klimatyzacji zamontowanych w budynku SPZOZ Przychodnia w Sycowie - wersja gotowa do odczytu maszynowego PDF, 130 KB" href="./do-pobrania/zapytania-ofertowe/2022/konserwacja-klimatyzacji/protokol-konserwacja-klimatyzacji-wersja gotowa do odczytu maszynowego.pdf">
                      Wybór oferty - Konserwacja klimatyzacji zamontowanych w budynku SPZOZ "Przychodnia" w Sycowie - wersja gotowa do odczytu maszynowego (PDF, 130 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe na Przebudowę rejestracji ogólnej w budynku SPZOZ „Przychodnia” w Sycowie (30.12.2021)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe PDF, 353 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/grudzien/przebudowa/ogloszenie-o-zamowieniu-przebudowa-rejestracji-ogolnej.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 353 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Zapytanie ofertowe - wersja gotowa do odczytu maszynowego PDF, 353 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/grudzien/przebudowa/ogloszenie-o-zamowieniu-przebudowa-rejestracji-ogolnej-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Zapytanie ofertowe - wersja gotowa do odczytu maszynowego (PDF, 191 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Przedmiar inwestycji PDF, 150 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/grudzien/przebudowa/przedmiar-przebudowa-rejestracji-ogolnej.pdf" target="_blank">
                      Przedmiar inwestycji (PDF, 150 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Przedmiar inwestycji - wersja gotowa do odczytu maszynowego PDF, 462 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/grudzien/przebudowa/przedmiar-przebudowa-rejestracji-ogolnej-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Przedmiar inwestycji - wersja gotowa do odczytu maszynowego (PDF, 462 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Specyfikacja SIWZ PDF, 882 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/grudzien/przebudowa/Specyfikacja-SIWZ.pdf" target="_blank">
                      Specyfikacja SIWZ (PDF, 882 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Specyfikacja SIWZ - wersja gotowa do odczytu maszynowego PDF, 1.8 MB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/grudzien/przebudowa/Specyfikacja-SIWZ-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Specyfikacja SIWZ - wersja gotowa do odczytu maszynowego (PDF, 1.8 MB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór umowy PDF, 400 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/grudzien/przebudowa/Umowa-wzor.pdf" target="_blank">
                      Wzór umowy (PDF, 400 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór umowy - wersja gotowa do odczytu maszynowego PDF, 382 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/grudzien/przebudowa/Umowa-wzor-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Wzór umowy - wersja gotowa do odczytu maszynowego (PDF, 382 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wizualizacja PDF, 251 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/grudzien/przebudowa/wizualizacja.pdf" target="_blank">
                      Wizualizacja (PDF, 251 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wizualizacja - wersja gotowa do odczytu maszynowego PDF, 872 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/grudzien/przebudowa/wizualizacja-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Wizualizacja - wersja gotowa do odczytu maszynowego (PDF, 872 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Przebudowa Rejestracji Ogólnej w budynku SPZOZ Przychodnia w Sycowie PDF, 152 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/przebudowa/protokol-z-wyboru-oferty.pdf">
                      Wybór oferty - Przebudowa Rejestracji Ogólnej w budynku SPZOZ "Przychodnia" w Sycowie (PDF, 152 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wybór oferty - Przebudowa Rejestracji Ogólnej w budynku SPZOZ Przychodnia w Sycowie - wersja gotowa do odczytu maszynowego PDF, 164 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/przebudowa/protokol-z-wyboru-oferty (1)-wersja gotowa do odczytu maszynowego.pdf">
                      Wybór oferty - Przebudowa Rejestracji Ogólnej w budynku SPZOZ "Przychodnia" w Sycowie - wersja gotowa do odczytu maszynowego (PDF, 164 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zakup sprzętu medycznego Przenośnego USG z 1 głowicą. (23.11.2021 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe PDF, 272 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/listopad/Zapytanie-ofertowe-USG.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 272 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Zapytanie ofertowe PDF - wersja gotowa do odczytu maszynowego, 264 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/listopad/Zapytanie-ofertowe-USG-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Zapytanie ofertowe - wersja gotowa do odczytu maszynowego (PDF, 264 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Specyfikacja techniczna PDF, 1 486 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/listopad/Specyfikacja-SIWZ-USG.pdf" target="_blank">
                      Specyfikacja techniczna (PDF, 1 486 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Specyfikacja techniczna - wersja gotowa do odczytu maszynowego PDF, 1 339 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/listopad/Specyfikacja-SIWZ-USG-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Specyfikacja techniczna -wersja gotowa do odczytu maszynowego (PDF, 1 339 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - zakup przenośnego USG z 1 głowicą PDF, 144 KB" href="./do-pobrania/zapytania-ofertowe/listopad/protokol-z-wyboru-oferty.pdf">
                      Wybór oferty - zakup przenośnego USG z 1 głowicą (PDF, 144 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wybór oferty - zakup przenośnego USG z 1 głowicą PDF - wersja gotowa do odczytu maszynowego, 159 KB" href="./do-pobrania/zapytania-ofertowe/listopad/protokol-z-wyboru-oferty.pdf">
                      Wybór oferty - zakup przenośnego USG z 1 głowicą - wersja gotowa do odczytu maszynowego (PDF, 159 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe na zakup Kiosku telemedycznego wraz z wyposażeniem (15.11.2021 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe PDF, 414 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/listopad/Zapytanie-ofertowe.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 414 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Specyfikacja techniczna PDF, 241 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/listopad/Specyfikacja-techniczna.pdf" target="_blank">
                      Specyfikacja techniczna (PDF, 241 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Umowa - wzór PDF, 146 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/listopad/Wzor-umowy.pdf" target="_blank">
                      Umowa - wzór (PDF, 146 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna DOC, 17,9 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/listopad/klauzula-informacyjna.docx" target="_blank">
                      Klauzula informacyjna (DOC, 17,9 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Załącznik nr 1 do Umowy DOC, 74,4 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/listopad/zalacznik-1.docx" target="_blank">
                      Załącznik nr 1 do Umowy (DOC, 74,4 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - zakup Kiosku telemedycznego PDF, 154 KB" href="./do-pobrania/zapytania-ofertowe/listopad/protokol1.pdf">
                      Wybór oferty - zakup Kiosku telemedycznego (PDF, 154 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Oferta cenowa na zakup Unitu stomatologicznego wraz z wyposażeniem (13.10.2021 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe PDF, 446 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/pazdziernik/zapytanie-ofertowe-unit-stomatolog.pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 446 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Zapytanie ofertowe - wersja gotowa do odczytu maszynowego PDF, 411 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/pazdziernik/zapytanie-ofertowe-unit-stomatolog-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Zapytanie ofertowe - wersja gotowa do odczytu maszynowego (PDF, 411 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Specyfikacja PDF, 116 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/pazdziernik/specyfikacja.pdf" target="_blank">
                      Specyfikacja (PDF, 116 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Specyfikacja - wersja gotowa do odczytu maszynowego PDF, 138 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/pazdziernik/specyfikacja unit stomatologiczny-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Specyfikacja - wersja gotowa do odczytu maszynowego (PDF, 138 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Umowa - wzór PDF, 205 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/pazdziernik/wzor-umowy.pdf" target="_blank">
                      Umowa - wzór (PDF, 205 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Umowa - wzór - wersja gotowa do odczytu maszynowego PDF, 214 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/pazdziernik/wzor-umowy unit stomatoligiczny-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Umowa - wzór - wersja gotowa do odczytu maszynowego (PDF, 214 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna DOC, 21,8 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/pazdziernik/klauzula-informacyjna.docx" target="_blank">
                      Klauzula informacyjna (DOC, 21,8 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Załącznik nr 2 do Umowy DOC, 78,8 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/pazdziernik/zalacznik-2-do-oferty.docx" target="_blank">
                      Załącznik nr 2 do Umowy (DOC, 78,8 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - zakup Unitu stomatologicznego PDF, 189 KB" href="./do-pobrania/zapytania-ofertowe/pazdziernik/wybor-oferty.pdf">
                      Wybór oferty - zakup Unitu stomatologicznego (PDF, 189 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wybór oferty - zakup Unitu stomatologicznego - wersja gotowa do odczytu maszynowego PDF, 196 KB" href="./do-pobrania/zapytania-ofertowe/pazdziernik/wybor-oferty-wersja gotowa do odczytu maszynowego.pdf">
                      Wybór oferty - zakup Unitu stomatologicznego - wersja gotowa do odczytu maszynowego (PDF, 196 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe na wykonanie remontu wejścia przychodni od ul. Daszyńskiego w SPZOZ „Przychodnia” w Sycowie, ul. Wrocławska 2 (27.05.2021 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe PDF, 623 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/maj/remont/Zapytanie-ofertowe(1).pdf" target="_blank">
                      Zapytanie ofertowe (PDF, 623 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Zapytanie ofertowe - wersja gotowa do odczytu maszynowego PDF, 565 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/maj/remont/Zapytanie-ofertowe(1)-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Zapytanie ofertowe - wersja gotowa do odczytu maszynowego (PDF, 565 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Specyfikacja PDF, 779 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/maj/remont/Specyfikacja.pdf" target="_blank">
                      Specyfikacja (PDF, 779 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Specyfikacja - wersja gotowa do odczytu maszynowego PDF, 753 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/maj/remont/Specyfikacja-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Specyfikacja -wersja gotowa do odczytu maszynowego (PDF, 753 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Rzut parteru PDF, 23,4 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/maj/remont/Rzutparteru.pdf" target="_blank">
                      Rzut parteru (PDF, 23,4 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Umowa - wzór PDF, 425 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/maj/remont/Wzor-umowy.pdf" target="_blank">
                      Umowa - wzór (PDF, 425 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna DOC, 21,4 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/maj/remont/klauzula-informacyjna-zamowienia-publiczne-Zapytanie-cenowe-27.05.2021.docx" target="_blank">
                      Klauzula informacyjna (DOC, 21,4 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Remont wejścia do przychodni PDF, 170 KB" href="./do-pobrania/zapytania-ofertowe/maj/remont/wybor-oferty-roboty-buowlane.pdf">
                      Wybór oferty - Remont wejścia do przychodni (PDF, 170 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wybór oferty - Remont wejścia do przychodni - wersja gotowa do odczytu maszynowego PDF, 181 KB" href="./do-pobrania/zapytania-ofertowe/maj/remont/wybor-oferty-roboty-buowlane-wersja gotowa do odczytu maszynowego.pdf">
                      Wybór oferty - Remont wejścia do przychodni - wersja gotowa do odczytu maszynowego (PDF, 181 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe zagospodarowanie terenu zielonego przy SPZOZ Przychodnia w Sycowie (08.04.2021 r.)</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="Ogłoszenie o zamówieniu PDF, 342 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/kwiecien/ogloszenie-o-zamowieniu.pdf" target="_blank">
                      Ogłoszenie o zamówieniu (PDF, 342 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Ogłoszenie o zamówieniu wersja gotowa do odczytu maszynowego PDF, 332 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/kwiecien/ogloszenie-o-zamowieniu-wersja gotowa do odczytu maszynowego.pdf" target="_blank">
                      Ogłoszenie o zamówieniu - wersja gotowa do odczytu maszynowego(PDF, 332 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Specyfikacja IWZ PDF, 477 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/kwiecien/Specyfikacja-siwz-wz-272-9-2020-zielen.pdf" target="_blank">
                      Specyfikacja IWZ (PDF, 477 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Zestawienie roślin PDF, 162 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/kwiecien/zestawienieroslin-i-opis-spzoz-zielen.pdf" target="_blank">
                      Zestawienie roślin (PDF, 162 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Umowa - wzór PDF, 141 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/kwiecien/umowa-wzor-zielen-przychodnia.pdf" target="_blank">
                      Umowa - wzór (PDF, 141 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Klauzula informacyjna PDF, 110 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/kwiecien/klauzula-informacyjna-zamowienia-publiczne-przetarg-zielen.pdf" target="_blank">
                      Klauzula informacyjna (PDF, 110 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Kosztorys budowlany ślepy PDF, 143 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/kwiecien/SPZOZ-SYCOW-CHODNIK-I-PLAC-WYPOCZYNKOWY.pdf" target="_blank">
                      Kosztorys budowlany ślepy (PDF, 143 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wizualizacja 1 JPG, 228 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/kwiecien/Wizualizacja1.jpg" target="_blank">
                      Wizualizacja 1 (JPG, 228 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wizualizacja 2 JPG, 226 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/kwiecien/Wizualizacja2.jpg" target="_blank">
                      Wizualizacja 2 (JPG, 226 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wizualizacja 3 JPG, 1 840 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/kwiecien/Wizualizacja3.jpg" target="_blank">
                      Wizualizacja 3 (JPG, 1 840 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Plan zagospodarowania PDF, 237 KB dokument otworzy się w nowym oknie" href="./do-pobrania/zapytania-ofertowe/kwiecien/Plan-zagospodarowania-terenu-zielonego-w-SPZOZ-w-Sycowie.pdf" target="_blank">
                      Plan zagospodarowania (PDF, 237 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - Zagospodarowanie terenu zielonego przy SPZOZ Przychodnia w Sycowie PDF, 174 KB" href="./do-pobrania/zapytania-ofertowe/kwiecien/ogloszenie-o-wyborze-ofert-zielen.pdf">
                      Wybór oferty - Zagospodarowanie terenu zielonego przy SPZOZ Przychodnia w Sycowie (PDF, 174 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wybór oferty - Zagospodarowanie terenu zielonego przy SPZOZ Przychodnia w Sycowie Wersja gotowa do oczytu maszynowegoPDF, 183 KB" href="./do-pobrania/zapytania-ofertowe/kwiecien/ogloszenie-o-wyborze-ofert-zielen-wersja gotowa do odczytu maszynowego.pdf">
                      Wybór oferty - Zagospodarowanie terenu zielonego przy SPZOZ Przychodnia w Sycowie - Wersja gotowa do oczytu maszynowego (PDF, 183 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe - konserwacja klimatyzacji</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="ZAPYTANIE OFERTOWE PDF, 224 KB" href="./do-pobrania/zapytania-ofertowe/styczen/Zapytanie ofertowe-konserwacja-klimatyzacji.pdf">
                      ZAPYTANIE OFERTOWE (PDF, 224 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa DOC, 41 KB" href="./do-pobrania/zapytania-ofertowe/styczen/oferta-klimatyzacja.doc">
                      Oferta cenowa (DOC, 41 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - konserwacja klimatyzacji PDF, 110 KB" href="./do-pobrania/zapytania-ofertowe/styczen/protokol-konserwacja-klimatyzacji.pdf">
                      Wybór oferty - konserwacja klimatyzacji (PDF, 110 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zakup sprzętu medycznego wraz z dostawą i montażem - głowica do USG </p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="ZAPYTANIE OFERTOWE PDF, 286 KB" href="./do-pobrania/zapytania-ofertowe/styczen/Zapytanie-ofertowe-nr-1-2021.pdf">
                      ZAPYTANIE OFERTOWE (PDF, 286 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="ZAPYTANIE OFERTOWE - wersja gotowa do odczytu maszynowego PDF, 274 KB" href="./do-pobrania/zapytania-ofertowe/styczen/Zapytanie-ofertowe-nr-1-2021-wersja gotowa do odczytu maszynowego.pdf">
                      ZAPYTANIE OFERTOWE - wersja gotowa do odczytu maszynowego (PDF, 274 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Zaproszenie do składania ofert PDF, 371 KB" href="./do-pobrania/zapytania-ofertowe/styczen/Zaproszenie-do-skladania-ofert-2021.pdf">
                      Zaproszenie do składania ofert (PDF, 371 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Załącznik nr 1 DOC, 38 KB" href="./do-pobrania/zapytania-ofertowe/styczen/Zalacznik-nr-1-formularz-ofertowy-2021.doc">
                      Załącznik nr 1 (DOC, 38 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Załącznik nr 2 DOC, 30,5 KB" href="./do-pobrania/zapytania-ofertowe/styczen/Zalacznik-nr-2-do-zapytania-ofe rtowego-oswiadczenie.doc">
                      Załącznik nr 2 (DOC, 30,5 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Załącznik nr 2 (asortymentowo-cenowy) DOC, 34,5 KB" href="./do-pobrania/zapytania-ofertowe/styczen/Zalacznik-nr-2-asortymentowo-cenowy.doc">
                      Załącznik nr 2 (asortymentowo-cenowy) (DOC, 34,5 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Załącznik nr 3 PDF, 220 KB" href="./do-pobrania/zapytania-ofertowe/styczen/Zalacznik-nr-3-Umowa.pdf">
                      Załącznik nr 3 (PDF, 220 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Załącznik nr 3 - wersja gotowa do odczytu maszynowego PDF, 225 KB" href="./do-pobrania/zapytania-ofertowe/styczen/Zalacznik-nr-3-Umowa-wersja gotowa do odczytu maszynowego.pdf">
                      Załącznik nr 3 - wersja gotowa do odczytu maszynowego (PDF, 225 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Załącznik nr 4 DOC, 57 KB" href="./do-pobrania/zapytania-ofertowe/styczen/Zalacznik-nr-4-Parametry-glowica-microconvex.doc">
                      Załącznik nr 4 (DOC, 57 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - zakup głowicy USG PDF, 252 KB" href="./do-pobrania/zapytania-ofertowe/styczen/protokol-glowica-usg.pdf">
                      Wybór oferty - zakup głowicy USG (PDF, 252 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wybór oferty - zakup głowicy USG - wersja gotowa do odczytu maszynowego PDF, 254 KB" href="./do-pobrania/zapytania-ofertowe/styczen/protokol-glowica-usg-wersja gotowa do odczytu maszynowego.pdf">
                      Wybór oferty - zakup głowicy USG - wersja gotowa do odczytu maszynowego (PDF, 254 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe dotyczące utrzymania terenu zielonego oraz parkingu przy Przychodni.</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="ZAPYTANIE OFERTOWE PDF, 174 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/utrzymanie-terenu-zielonego/Zapyanie-ofertowe-utrzymanie-terenu-zielonego.pdf">
                      ZAPYTANIE OFERTOWE (PDF, 174 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="ZAPYTANIE OFERTOWE - wersja gotowa do odczytu maszynowego PDF, 190 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/utrzymanie-terenu-zielonego/Zapyanie-ofertowe-utrzymanie-terenu-zielonego-wersja gotowa do odczytu maszynowego.pdf">
                      ZAPYTANIE OFERTOWE - wersja gotowa do odczytu maszynowego (PDF, 190 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór Umowy dotyczącej pielęgnacji terenów zielonych i parkingu DOC, 21,8 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/utrzymanie-terenu-zielonego/wzor-umowa-zielen.docx">
                      Wzór Umowy dotyczącej pielęgnacji terenów zielonych i parkingu (DOC, 21,8 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór oferty cenowej dotyczącej zieleni DOC, 36,5 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/utrzymanie-terenu-zielonego/wzor-oferta-zielen.doc">
                      Wzór oferty cenowej dotyczącej zieleni (DOC, 36,5 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty (04.01.2021)</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - utrzymania terenu zielonego oraz parkingu przy Przychodni. PDF, 242 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/utrzymanie-terenu-zielonego/protokol-z-dnia-04.01.2021.pdf">
                      Wybór oferty - utrzymania terenu zielonego oraz parkingu przy Przychodni. (PDF, 242 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wybór oferty - utrzymania terenu zielonego oraz parkingu przy Przychodni. - wersja gotowa do odczytu maszynowego PDF, 239 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/utrzymanie-terenu-zielonego/protokol-z-dnia-04.01.2021-wersja gotowa do odczytu maszynowego.pdf">
                      Wybór oferty - utrzymania terenu zielonego oraz parkingu przy Przychodni. - wersja gotowa do odczytu maszynowego (PDF, 239 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe dotyczące wykonania malowania pomieszczeń Przychodni ustalonych z zamawiającym - malowanie ścian i sufitów w okresie 04.01.2021 r. - 30.12.2021 r. ( 01.12.2020 r. )</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="ZAPYTANIE OFERTOWE PDF, 224 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/Zapytanie-ofertowe.pdf">
                      ZAPYTANIE OFERTOWE (PDF, 224 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="ZAPYTANIE OFERTOWE - wersja gotowa do odczytu maszynowego PDF, 226 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/Zapytanie-ofertowe-wersja gotowa do odczytu maszynowego.pdf">
                      ZAPYTANIE OFERTOWE - wersja gotowa do odczytu maszynowego (PDF, 226 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór Umowy o roboty budowlane PDF, 342 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/Wzor-Umowa-o-roboty-budowlane.pdf">
                      Wzór Umowy o roboty budowlane (PDF, 342 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór Umowy o roboty budowlane - wersja gotowa do odczytu maszynowego PDF, 333 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/Wzor-Umowa-o-roboty-budowlane-wersja gotowa do odczytu maszynowego.pdf">
                      Wzór Umowy o roboty budowlane - wersja gotowa do odczytu maszynowego (PDF, 333 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór oferty cenowej PDF, 14,8 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/Wzor-oferta-cenowa.pdf">
                      Wzór oferty cenowej (PDF, 14,8 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wzór oferty cenowej - wersja gotowa do odczytu maszynowego PDF, 21 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/Wzor-oferta-cenowa-wersja gotowa do odczytu maszynowego.pdf">
                      Wzór oferty cenowej - wersja gotowa do odczytu maszynowego (PDF, 21 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty (29.12.2020)</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty - malowanie pomieszczeń Przychodni SPZOZ w Sycowie PDF, 178 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/protokol-z-dnia-29.12.2020.pdf">
                      Wybór oferty - malowanie pomieszczeń Przychodni SPZOZ w Sycowie (PDF, 178 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wybór oferty - malowanie pomieszczeń Przychodni SPZOZ w Sycowie - wersja gotowa do odczytu maszynowego PDF, 187 KB" href="./do-pobrania/zapytania-ofertowe/grudzien/protokol-z-dnia-29.12.2020-wersja gotowa do odczytu maszynowego.pdf">
                      Wybór oferty - malowanie pomieszczeń Przychodni SPZOZ w Sycowie - wersja gotowa do odczytu maszynowego (PDF, 187 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Ogłoszenie o zamówieniu nr ZP//2020 – wykonanie remontu pomieszczeń piwnicznych w SPZOZ “Przychodnia” w Sycowie (12.05.2020).</p>
                </div>
                <br />
                <ul>
                  <li>
                    <a aria-label="OGŁOSZENIE O ZAMÓWIENIU PDF, 763 KB" href="./do-pobrania/zapytania-ofertowe/maj/OGLOSZENIE-O-ZAMOWIENIU.pdf">
                      OGŁOSZENIE O ZAMÓWIENIU (PDF, 763 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="OGŁOSZENIE O ZAMÓWIENIU  - wersja gotowa do odczytu maszynowego PDF, 583 KB" href="./do-pobrania/zapytania-ofertowe/maj/OGLOSZENIE-O-ZAMOWIENIU (1)-wersja gotowa do odczytu maszynowego.pdf">
                      OGŁOSZENIE O ZAMÓWIENIU - wersja gotowa do odczytu maszynowego (PDF, 583 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="KLAUZULA 12.05 PDF, 451 KB" href="./do-pobrania/zapytania-ofertowe/maj/KLAUZULA-12.05.2020.pdf">
                      KLAUZULA 12.05 (PDF, 451 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="KLAUZULA-12.05.2020-wersja gotowa do odczytu maszynowego PDF, 336 KB" href="./do-pobrania/zapytania-ofertowe/maj/KLAUZULA-12.05.2020-wersja gotowa do odczytu maszynowego.pdf">
                      KLAUZULA-12.05.2020-wersja gotowa do odczytu maszynowego (PDF, 336 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="OŚWIADCZENIE WYKONAWCY PDF, 181 KB" href="./do-pobrania/zapytania-ofertowe/maj/OSWIADCZENIE-WYKONAWCY.pdf">
                      OŚWIADCZENIE WYKONAWCY (PDF, 181 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="OŚWIADCZENIE WYKONAWCY - wersja gotowa do odczytu maszynowego PDF, 126 KB" href="./do-pobrania/zapytania-ofertowe/maj/OSWIADCZENIE-WYKONAWCY-wersja gotowa do odczytu maszynowego.pdf">
                      OŚWIADCZENIE WYKONAWCY - wersja gotowa do odczytu maszynowego(PDF, 126 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="KOSZTORYS NAKŁADCZY (ŚLEPY) PDF, 215 KB" href="./do-pobrania/zapytania-ofertowe/maj/KOSZTORYS-NAKLADCZY-SLEPY.pdf">
                      KOSZTORYS NAKŁADCZY (ŚLEPY) (PDF, 215 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="SPECYFIKACJA -REMONT POM.PIWNICZNYCH DOC, 188 KB" href="./do-pobrania/zapytania-ofertowe/maj/SPECYFIKACJA-REMONT-POM.PIWNICZNYCH.doc">
                      SPECYFIKACJA -REMONT POM.PIWNICZNYCH (DOC, 188 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="WZÓR UMOWY NA ROBOTY BUDOWLANE DOC, 68 KB" href="./do-pobrania/zapytania-ofertowe/maj/WZOR-UMOWY-NA-ROBOTY-BUDOWLANE.doc">
                      WZÓR UMOWY NA ROBOTY BUDOWLANE (DOC, 68 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty (21.04.2020)</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty – wykonanie remontu pomieszczeń piwnicznych w SPZOZ “Przychodnia” w Sycowie PDF, 129 KB" href="./do-pobrania/zapytania-ofertowe/maj/zalacznik-nr-2.pdf">
                      Wybór oferty – wykonanie remontu pomieszczeń piwnicznych w SPZOZ “Przychodnia” w Sycowie (PDF, 129 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wybór oferty – wykonanie remontu pomieszczeń piwnicznych w SPZOZ “Przychodnia” w Sycowie  -wersja gotowa do odczytu maszynowego PDF, 142 KB" href="./do-pobrania/zapytania-ofertowe/maj/zalacznik-nr-2-wersja gotowa do odczytu maszynowego.pdf">
                      Wybór oferty – wykonanie remontu pomieszczeń piwnicznych w SPZOZ “Przychodnia” w Sycowie - wersja gotowa do odczytu maszynowego (PDF, 142 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>

              <hr />

              <StandardTextBlock>
                <div className="subtitle">
                  <p style={{ fontSize: "2rem", fontWeight: 600 }}>Zapytanie ofertowe dotyczące konserwacji klimatyzacji zamontowanych w przychodni oraz zamontowanie klimatyzacji w poczekalni przychodni (08.01.2020)</p>
                </div>
                <br />
                <p>
                  <strong>Dokumenty do pobrania</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Zapytanie ofertowe – Klimatyzacja PDF, 429 KB" href="./do-pobrania/zapytania-ofertowe/styczen/Zapytanie-ofertowe-Klimatyzacja.pdf">
                      Zapytanie ofertowe – Klimatyzacja (PDF, 429 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Zapytanie ofertowe – Klimatyzacja - wersja gotowa do odczytu maszynowego PDF, 392 KB" href="./do-pobrania/zapytania-ofertowe/styczen/Zapytanie-ofertowe-Klimatyzacja-wersja gotowa do odczytu maszynowego.pdf">
                      Zapytanie ofertowe – Klimatyzacja - wersja gotowa do odczytu maszynowego (PDF, 392 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Oferta cenowa – Klimatyzacja PDF, 88,2 KB" href="./do-pobrania/zapytania-ofertowe/styczen/Oferta-cenowa-Klimatyzacja.pdf">
                      Oferta cenowa – Klimatyzacja (PDF, 88,2 KB)
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Ogłoszenie o wyborze oferty (29.01.2020):</strong>
                </p>
                <ul>
                  <li>
                    <a aria-label="Wybór oferty – klimatyzacja PDF, 175 KB" href="./do-pobrania/zapytania-ofertowe/styczen/Wybor-oferty-klimatyzacja.pdf">
                      Wybór oferty – klimatyzacja (PDF, 175 KB)
                    </a>
                  </li>
                  <li>
                    <a aria-label="Wybór oferty – klimatyzacja  -wersja gotowa do odczytu maszynowego PDF, 182 KB" href="./do-pobrania/zapytania-ofertowe/styczen/Wybor-oferty-klimatyzacja-wersja gotowa do odczytu maszynowego.pdf">
                      Wybór oferty – klimatyzacja - wersja gotowa do odczytu maszynowego (PDF, 182 KB)
                    </a>
                  </li>
                </ul>
              </StandardTextBlock>
            </Col>
          </Row>
        </Container>
      </section>
    </PageWrapper>
  );
};

export default PublicTendersPage;
