export class RoutePath {
  static OCHRONAMALOLETNICH: any;
  [key: string]: any;
  static RULES: string = '/warunki-uzytkowania';
  static NEWS: string = '/aktualnosci';
  static COVIDINFO: string = '/koronawirus-informacje';
  static PRINCING: string = '/cennik-uslug';
  static PRIVOFFICES: string = '/gabinety-prywatne';
  static TREATMENTOFFICE: string = '/gabinet-zabiegowy';
  static STRADOMIAWIERZCHNIA: string = '/stradomia-wierzchnia';
  static SPECIALISTCLINICS: string = '/poradnie-specjalistyczne';
  static PUBLICTENDERS: string = '/przetargi-publiczne';
  static BASICHEALTHCARE: string = '/lekarze-podstawowej-opieki-zdrowotnej';
  static PRESCRIPTIONS: string = '/recepty-na-leki-stale';
  static SKIEROWANIENABADANIA: string = '/skierowanie-na-badania';
  static SEARCH: string = '/wyniki-wyszukiwania';
  static KARTAPRAWPACJENTA: string = '/karta-praw-pacjenta';
  static SKARGIIWNIOSKI: string= '/skargi-i-wnioski';
  static DYZURYAPTEK: string= '/dyzury-aptek';
  static CONTACT: string= '/kontakt';
  static LAWINFO: string= '/informacje-prawne';
  static INFORMACJEDLAPACJENTAPAGE: string= '/informacje-dla-pacjenta';
  static ROZPOZADZENIEMINISTRAZDROWIAPAGE: string= '/rozporzadzenie-ministra-zdrowia';
  static OCHRONADANYCHOSOBOWYCHPAGE: string= '/ochrona-danych-osobowych';
  static DEKLARACJADOSTEPNOSCI: string= '/deklaracja-dostepnosci';
  static SZCZEPIONKAINFO: string = '/szczepionka-informacje';
  static NOCNAOPIEKA: string = '/nocna-swiateczna-opieka-zdrowotna';
  static JEZYKMIGOWYPAGE: string = '/jezyk-migowy';
  static DOSTEPNOSC: string = '/deklaracja-dostepnosci.html';
  static STANDARDYOCHRONYMALOLETNICHAPAGE: string= '/standardy-ochrony-maloletnich';

  static HOME: string = '/';
}