import React from "react";
import "./stradomia-wierzchnia-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";

import MapSection from "../../components/map-section/map-section";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import {doctors} from './stradomia-wierzchnia-data';
import DoctorRow from "../../components/row-info/doctors-row";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";

const StradomiaWierzchniaPpage: React.FC<any> = () => {

  return (
    <PageWrapper className="stradomia-wierzchnia-page">

      <Helmet>
        <title>{'Wiejski Ośrodek Zdrowia Stradomia Wierzchnia | SPZOZ Przychodnia Syców'}</title>
      </Helmet>

      <section className="pageWrapper__section">
        <Container>
          <MainHeader tabindex={0}>WIEJSKI OŚRODEK ZDROWIA STRADOMIA WIERZCHNIA</MainHeader>
          <StandardTextBlock>
            <p><strong>Wiejski Ośrodek Zdrowia jest czynny w dniach: poniedziałek, wtorek, czwartek, piątek od 7:00 do 14:35.Kontakt pod numerem telefonu - <a aria-label="Pod numerem telefonu 62 785 82 60" href="tel:627858260">62 785 82 60</a></strong></p>
          </StandardTextBlock>
          {doctors.map((doctor,index)=><DoctorRow key={index} name={doctor.name} specializations={doctor.specializations}/>)} 
          <MapSection maxWidth={800} />
        </Container>
      </section>
    </PageWrapper>
  )
}

export default StradomiaWierzchniaPpage;