import React, { useEffect, useRef } from "react";
import "./CheckBox.scss";
import { ChangeHandler, RefCallBack } from "react-hook-form";

interface CheckBoxInputProps {
  id?: string;
  label?: string;
  name?: string;
  validation_msg?: string;
  required?: boolean;
  description: string | React.ReactNode;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement> & {
    control?: any;
    ref?: RefCallBack;
    onChange?: ChangeHandler | any;
    onBlur?: ChangeHandler;
    register?: any;
  };
}

const CheckBoxInput = (props: CheckBoxInputProps) => {
  const { id, label, validation_msg, required, description, inputProps = { ref: undefined, register: undefined, disabled: undefined } } = props;
  const checkboxRowRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className={`form-child CheckBoxInput ${inputProps?.disabled ? "CheckBoxInput--disabled" : ""}`}>
      {label ? (
        <label className="label">
          <span>{label + (required ? "*" : "")}</span> <span className={`validation-messages ${validation_msg ? "active" : ""}`}>{validation_msg ? <>({validation_msg})</> : <>&nbsp;</>}</span>
        </label>
      ) : null}
      <div className={`checkboxRow`} ref={checkboxRowRef}>
        <input type="checkbox" id={id} {...inputProps} placeholder={inputProps.placeholder ?? label} tabIndex={0} aria-label={checkboxRowRef?.current && checkboxRowRef?.current?.firstChild instanceof HTMLInputElement ? (checkboxRowRef?.current?.firstChild?.checked ? "Pole wyboru zaznaczone, kliknij aby odznaczyć" : "Pole wyboru odznaczone, kliknij aby zaznaczyć") : "Pole wyboru"} />
        <label className="labelFor" htmlFor={id}>
          {description}
        </label>
      </div>
    </div>
  );
};

export default CheckBoxInput;
