export const internistsDoctors = [

  {
    name: 'Małgorzata Grabowska (Godziny przyjęcia zgodnie z harmonogramem)', 
    specializations: [{
      name: 'Poradnia ogólna',
      workingDays: ['Wtorek (Stradomia Wierzchnia): Rano 08:15', 'Poniedziałek, Środa, czwartek i Piątek (Syców): Rano od 08:15 i południe 12:00']
    }]
  },
  {
    name: 'Magdalena Przybył-Sarnowska (Godziny przyjęcia zgodnie z harmonogramem)', 
    specializations: [{
      name: 'Poradnia ogólna',
      workingDays: ['Rano: od 08:00', 'Południe: od 12:00']
    }]
  },
  {
    name: 'Szymon Kucharzak (Godziny przyjęcia zgodnie z harmonogramem)', 
    specializations: [{
      name: 'Poradnia ogólna (Syców) ',
      workingDays: ['Rano: od 08:00', 'Południe: od 12:00']
    }]
  },
  {
    name: 'Zbigniew Garbiec (Godziny przyjęcia zgodnie z harmonogramem)', 
    specializations: [{
      name: 'Poradnia ogólna',
      workingDays: ['Rano: od 08:00', 'Południe: od 12:00']
    }]
  },
  {
    name: 'Barbara Nogala (Godziny przyjęcia zgodnie z harmonogramem)', 
    specializations: [{
      name: 'Poradnia ogólna',
      workingDays: ['Poniedziałek i Środa: rano 09:00 i południe 13:00 (Syców)', 'Piątek: rano 08:00 (Stradomia)']
    }]
  },
  {
    name: 'Anna Sygit-Bajko (Godziny przyjęcia zgodnie z harmonogramem)', 
    specializations: [{
      name: 'Poradnia ogólna',
      workingDays: ['Wtorek: rano 08:00 i południe 12:00 (Syców)', 'Czwartek: rano 08:00 i południe 12:00 (Syców)', 'Środa: rano 08:00 (Stradomia)' ]
    }]
  },
  {
    name: 'Tetiana Kolesnyk-Koval (Godziny przyjęcia zgodnie z harmonogramem)', 
    specializations: [{
      name: 'Poradnia ogólna',
      workingDays: ['Rano: od 08:00', 'Południe: od 12:00']
    }]
  },
  {
    name: 'Dominika Kopeć', 
    specializations: [{
      name: 'Poradnia ogólna',
      workingDays: ['Poniedziałek: rano 08:00 i południe 11:00', 'Czwartek: rano 08:00 i południe 11:00', 'Piątek: rano 08:00 i południe 11:00' ]
    }]
  },
]

export const pediatrician = [
    {
        name: 'Angelika Ratajczyk-Kaczmarek (Godziny przyjęcia zgodnie z harmonogramem)', 
        specializations: [{
          name: 'Poradnia dziecięca',
          workingDays: ['Rano: od 08:30', 'Południe: od 12:00']
        }]
      },
      {
        name: 'Joanna Sapota (Godziny przyjęcia zgodnie z harmonogramem)', 
        specializations: [{
          name: 'Poradnia dziecięca',
          workingDays: ['Rano: od 08:00', 'Południe: od 12:00']
        }]
      },
      {
        name: 'Paweł Kłys (Godziny przyjęcia zgodnie z harmonogramem)', 
        specializations: [{
          name: 'Poradnia dziecięca',
          workingDays: ['Rano: od 08:00', 'Południe: od 12:00']
        }]
      },
      {
        name: 'Regina Jasik', 
        specializations: [{
          name: 'Poradnia dziecięca',
          workingDays: ['Poniedziałek: 12:00-18:00', 'Wtorek: 08:00-14:00', 'Środa: od 08:00 (Higiena Szkolna)']
        }]
      },
      {
        name: 'Magdalena Mazur-Popińska', 
        specializations: [{
          name: 'Poradnia dziecięca',
          workingDays: ['Rano: od 08:00', 'Południe: od 12:00']
        },
        {
            name: 'Poradnia dziecięca WOZ Stradomia',
            workingDays: ['Poniedziałek: 08:00-11:00']
          }
    ]
      },
]