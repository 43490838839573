export const handleScrollToSection = (section: React.MutableRefObject<any>, navOffset?: number, isSmooth?: boolean) => {
  if(isSmooth){
    let options = {
      left: 0,
      top: section.current!.getBoundingClientRect().top + window.pageYOffset - (navOffset || navOffset === 0 ? navOffset : 65),
      behavior: 'smooth'
    } as ScrollToOptions;
    window.scrollTo(options);
  }
  else{
    window.scrollTo(0, section.current!.getBoundingClientRect().top + window.pageYOffset - (navOffset || navOffset === 0 ? navOffset : 65));
  }
}