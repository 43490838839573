import React from "react";
import "./covid-info-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import CollapsibleBlock from "../../components/collapsible-block/collapsible-block";
import CollapsibleBlocksWrapper from "../../components/collapsible-block/collapsible-blocks-wrapper";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";

const CovidInfoPage: React.FC<any> = () => {

  return (
    <PageWrapper className="covid-info-page">
      <Helmet>
        <title>{'Koronawirus COVID-19 | SPZOZ Przychodnia Syców'}</title>
      </Helmet>

      <section className="pageWrapper__section">
        <Container>
          <MainHeader>KORONAWIRUS (COVID-19) - INFORMACJE BIEŻĄCE</MainHeader>
          <CollapsibleBlocksWrapper>
          <CollapsibleBlock
              title="Kwarantanna - zgłoszenie online - informacje"
              isSplited={true}
            >
              <StandardTextBlock>
              <p>
                  <a aria-label="Informacja od Państwowego Powiatowego Inspektora Sanitarnego w Oleśnicy PDF 254KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/informacja-duza-ilosc-przypadkow-covid19.pdf">Informacja od Państwowego Powiatowego Inspektora Sanitarnego w Oleśnicy (PDF 254KB) </a>
                </p>
                <p>
                  <a aria-label="Kwarantanna - formularz zgłoszenia online PDF 304KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/formularz-online-wzmozona-ilosc-przypadkow-covid19.pdf">Kwarantanna - formularz zgłoszenia online (PDF 304KB)</a>
                </p>
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="INFORMACJA"
              isSplited={true}
            >
              <StandardTextBlock>
                <p>
                  <span className="red">Z dniem 13.03.2020r.</span> Samodzielny Publiczny Zakład Opieki Zdrowotnej “Przychodnia” w Sycowie informuje, że <span className="red">w soboty nie będzie czynna poradnia ogólna i dziecięca aż do odwołania.</span>
                </p>
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="TELEPORADY POZ"
              isSplited={true}
            >
              <StandardTextBlock>
                <p>
                  Samodzielny Publiczny Zakład Opieki Zdrowotnej w Sycowie informuje, iż w celu ograniczenia kontaktu między pacjentami w Przychodni zostały uruchomione numery telefonów do lekarzy, którzy moga przepisać recepty, skierowania, zwolnienia lekarskie lub udzielić informacji bez wizyty w Przychodni.
                </p>
                <br />
                <p>
                  bardzo prosimy o ograniczenie planowania wizyt w Przychodni i stosowania się do zasad podawanych w sprawie koronawirusa.
                </p>
                <br />
                <p>
                  Numery telefonów po odbiór kodu wystawionej e-recepty dostępne są na stronie internetowej przychodni:
                  <TopLink to={RoutePath.PRESCRIPTIONS}> <br />
                  www.{window.location.hostname + RoutePath.PRESCRIPTIONS}
                  </TopLink>
                </p>
                <br />
                <div className="subtitle">
                  PORADNIA OGÓLNA:
              </div>
                <p>
                  <a aria-label="Pod numerem telefonu 62 785 51 45" href="tel:627855145">62 785 51 45</a> - w godzinach 13:30 do 17:30
              </p>
                <p>
                  <a aria-label="Pod numerem telefonu 62 785 51 92" href="tel:627855192">62 785 51 92</a> - w godzinach 08:00 do 13:30
              </p>
              <p>
                  <a aria-label="Pod numerem telefonu 503 622 361" href="tel:503622361">503 622 361</a> - w godzinach 08:00 do 13:30
              </p>
                <br />
                <div className="subtitle">
                  PORADNIA DZIECIĘCA:
              </div>
                <p>
                  <a aria-label="Pod numerem telefonu 62 785 51 48" href="tel:627855148">62 785 51 48</a> - w godzinach 08:00 do 17:30
              </p>
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="NOWE ZASADY FUNKCJONOWANIA PRZYCHODNI"
              isSplited={true}
            >
              <StandardTextBlock>
                <p>
                  Do odwołania w naszej Przychodni obowiązuja następujące zasady:
                </p>
                <ul>
                  <li className="red">pacjenci powinni unikać osobistych wizyt w poradni</li>
                  <li>osoby wymagające konsultacji z lekarzem zostaną umówione na teleporadę przez telefon, podczas której lekarz zadecyduje o konieczności osobistej wizyty w poradni</li>
                  <li className="red">
                    recepty prosimy zamawiać telefonicznie lub przez stronę internetową: www.spzozprzychodnia.pl/recepty
                </li>
                </ul>
              </StandardTextBlock>
            </CollapsibleBlock>
          </CollapsibleBlocksWrapper>
        </Container>
      </section>
    </PageWrapper>
  )
}

export default CovidInfoPage;