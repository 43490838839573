import React from "react";
import "./prefooter.scss";
import { RoutePath } from "../../../route-paths";
import TopLink from "../top-link/top-link";
import Container from "../container/container";
import LogoImg from "./../../assets/logo.svg";
import NFZLogoImg from "./../../assets/NFZ_logo.svg";
import MZLogoImg from "./../../assets/ministerstwo.png";
import DostepnoscLogoImg from "./../../assets/dotepnosc-plus-logo.svg";
import LinkBtn from "../../components/link-btn/link-btn";
import Modal from "../modal/modal";




interface Props {
}

const PreFooter: React.FC<Props> = (props: Props) => {

  return (
    <>
      <section className="prefooter" id='footerContent'>
        <Container>
          <div className="rowInfo">
            <div className="hoursBlock">
              <TopLink to={RoutePath.HOME}>
                <img src={LogoImg} alt="Logo przychodni, link do strony głównej" />
              </TopLink>
              <div className="title" title="Godziny rejestracji pacjentów" tabIndex={0}>GODZINY REJESTRACJI PACJENTÓW</div>
              <p>Poniedziałek - Piątek: w godzinach 7.00 - 17.30</p>
              <p>Sobota - Niedziela: nieczynne</p>
              <br />
              <Modal
          title={`Potwierdzenie wizyty`}
          maxWidth={1200}
          disableStandardButtons={true}
          button={{
            title: "Potwierdzenie wizyty",
          }}
        >
          <iframe src="https://drive.google.com/file/d/1cRVyai-19ZYJuyDPJryhdruzD2sjUYaB/preview" title="SPZOZ Przychodnia Syców - informacja dla pacjentów niedosłyszących" frameBorder="0" allow="accelerometer; autoplay;" allowFullScreen style={{ aspectRatio: "16/9" }}></iframe>
        </Modal>
            </div>
            <div className="contact">
              <div className="contact-group">
                <div className="title" title="Kontakt do sekretariatu" tabIndex={0}>Sekretariat</div>
                <a aria-label="Pod numerem telefonu 62 785 51 88" className="external-link" href="tel:627855188"><p>62 785 51 88</p></a>
                <a href="mailto:sekretariat@spzozprzychodnia.pl" className="external-link"><p>sekretariat@spzozprzychodnia.pl</p></a>
              </div>
              <div className="contact-group">
                <div className="title" title="Pozostałe numery telefonów" tabIndex={0}> Pozostałe numery telefonów</div>
                <LinkBtn to={RoutePath.CONTACT}>Przejdź do listy kontaktów</LinkBtn>
              </div>
            </div>
            
            <div className="logos-wrapper">
              <div className="logos">
                <img className="no-invert-white" src={NFZLogoImg} alt="Logo Narodowego Funduszu Zdrowia" />
                <img src={MZLogoImg} className="size-medium no-invert-white" alt="Logo Ministerstwa Zdrowia" />
              </div>
              <div className="logos">
                <a href="https://www.funduszeeuropejskie.gov.pl/strony/o-funduszach/fundusze-europejskie-bez-barier/dostepnosc-plus/">
                  <img src={DostepnoscLogoImg} alt="Logo Dostępność Plus" className="size-large" />
                </a>
                <a href="https://www.w3.org/WAI/WCAG2AA-Conformance"
                  title="Wyjaśnienie wymogów standardu AA WCAG 2.1">
                  <img className="size-small no-invert-white" src="https://www.w3.org/WAI/WCAG21/wcag2.1AA-v" alt="Wymagania standardu AA WCAG 2.1" />
                </a>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default PreFooter;