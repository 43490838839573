import React from 'react';
import './row-info.scss';

interface Props {
    name: string;
    specializations: [{name: string, workingDays: [string]}];
   
  }

  
const DoctorRow: React.FC<any> = ({name, specializations}:Props) => {
  return (
    <div className='row-info'>
        <div tabIndex={0} className='row-info__name'>
          {name}
          </div>
          {specializations.map((specialization,index)=>(<div className='row-info__description' key={index}>
          <div className="left">
          {specialization.workingDays.map((item, index)=><p className={`left_detail ${index <= specialization.workingDays.length-2  && index!== 2 &&"separate" }`} key={index}>
            {item} 
          </p>)}
          </div>
          <p className='right'>
            {specialization.name}
          </p>
        </div>))}


    </div>
  )
}

export default DoctorRow