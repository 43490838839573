import React, { } from "react";
import './../rules-page/rules-page.scss'
import Helmet from 'react-helmet';
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";

const SkargiIWnioskiPage: React.FC<any> = () => {
  return (

    <PageWrapper className="rules-page">
      <Helmet>
        <title>{'Skargi i Wnioski | SPZOZ Przychodnia Syców'}</title>
      </Helmet>
      <Container>
        <section className="pageWrapper__section">
          <MainHeader>
          TRYB PRZYJMOWANIA SKARG I WNIOSKÓW
          </MainHeader>
          <div className="policy">
            <p>W Samodzielnym Publicznych Zakładzie Opieki</p>
            <p>Zdrowotnej &#8220;Przychodnia&#8221; w Sycowie</p>
            <p>Podstawa prawna: Rozporządzenie Rady Ministrów z dnia 08.01.2002 roku.</p>
            <p>1) Skargi i wnioski mogą być wnoszone pisemnie, telefaksem, pocztą elektroniczną, a także ustnie do protokołu.</p>
            <p>2) W razie zgłoszenia skargi lub wniosku ustnie, przyjmujący zgłoszenie sporządza protokół, który podpisują wnoszący skargę lub wniosek i przyjmujący zgłoszenie.</p>
            <p>3) W protokole zamieszcza się datę przyjęcia skargi lub wniosku, imię i nazwisko oraz adres zgłaszającego i zwięzły opis treści sprawy.</p>
            <p>4) Skargi niezawierające imienia i nazwiska oraz adresu wnoszącego pozostawia się bez rozpoznania.5. Przedmiotem skargi może być w szczególności zaniedbanie lub nienależyte wykonywanie zadań przez SP ZOZ “Przychodnia” w Sycowie lub przez pracowników w nim zatrudnionych, naruszenie praworządności lub interesów skarżących, a także przewlekłe lub biurokratyczne załatwianie spraw.6. Skargę lub wniosek na działalność poradni, pracowni czy rejestracji rozpatruje dyrektor  SP ZOZ “Przychodnia” w Sycowie.</p>
            <p>5) Skargi i wnioski na działalność przychodni i wiejskiego ośrodka zdrowia przyjmuje dyrektor SP ZOZ “Przychodnia” w Sycowie, przy ul. Wrocławskiej 2 w Sycowie we wtorki w godzinach od 8:00 do 9:30         w pok . 214  – tel. 62 785 51 91.</p>
            <p>6) Jeżeli z treści skargi lub wniosku nie można należycie ustalić ich przedmiotu, dyrektor SP ZOZ “Przychodnia” w Sycowie wzywa wnoszącego skargę lub wniosek do złożenia, w terminie 7 dni od dnia otrzymania wezwania, wyjaśnienia lub uzupełnienia, z pouczeniem, że nieusunięcie tych braków spowoduje pozostawienie skargi lub wniosku bez rozpoznania.</p>
            <br/>
            <br/>
            <p>Dyrektor SPZOZ "Przychodnia" w Sycowie</p>
            <p>Piotr Nogala</p>
          </div>
        </section>
      </Container>
    </PageWrapper>
  )
}

export default SkargiIWnioskiPage;