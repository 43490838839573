import React from "react";
import TopLink from "../top-link/top-link";
import "./shortInfoLink.scss";

interface Props {
  title: string;
  desciption: string;
  icon: string;
  to: string;
}

const ShortInfoLink: React.FC<Props> = (props: Props) => {
  const { title, desciption, icon, to } = props;
  return (
    <div className="shortInfoLink">
      <TopLink to={to}>
        <div className="img_wrapper">
          <img aria-hidden="true" className="no-invert" src={icon} alt={""} />
        </div>
        <div className="content__wrapper">
          <div className="title">{title}</div>
          <div className="description">{desciption}</div>
        </div>
      </TopLink>
    </div>
  )
}

export default ShortInfoLink;