import React, { useRef, useEffect } from "react";
import './rules-page.scss'
import { PrivacyPolicy } from './privacy-policy';
import Helmet from 'react-helmet';
import { CookiePolicy } from './cookie-policy';
import { useLocation } from 'react-router-dom';
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";

export const RulesPage: React.FC<any> = (props: any) => {

  const rulesLink = useRef<any>()
  const privacyLink = useRef<any>()

  const { state } = useLocation();

  useEffect(() => {
    if (state && (state as any).scrollTo && (state as any).scrollTo === 'top') {
      window.scrollTo(0, 0);
      window.history.pushState(null, '')
      // window.scrollTo(0, privacyLink.current!.getBoundingClientRect().top + window.pageYOffset);
    }
    else if (state && (state as any).scrollTo && (state as any).scrollTo === 'rules') {
      window.scrollTo(0, rulesLink.current!.getBoundingClientRect().top + window.pageYOffset);
      window.history.pushState(null, '')
    }
    else {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (

    <PageWrapper className="rules-page">
      <Helmet>
        <title>{'SPZOZ Syców'}</title>
      </Helmet>
      <Container>
        <section className="pageWrapper__section">
          <MainHeader>
            POLITYKA STRONY
          </MainHeader>
          <h3 ref={rulesLink}>
            Polityka Cookies
          </h3>
          <CookiePolicy />
          <h3 ref={privacyLink}>
            Polityka Prywatności
          </h3>
          <PrivacyPolicy />
        </section>
      </Container>
    </PageWrapper>
  )
}