import React from 'react';
import './row-info.scss';

interface Props {
    pharmacyData: {name:string, day: string, hours: string},
  }

  
const PharmacyRow: React.FC<any> = ({pharmacyData }:Props) => {
    const {name, day, hours} = pharmacyData;
  return (
    <div className='row-info pharmacy'>
        <div tabIndex={0} className='row-info__name'>
          {name}
          </div>
          <div className='row-info__description'>
          <div className="left">
          <p className={`left_detail`} >
           {day}
          </p>
          <p className={`left_detail`} >
           {hours}
          </p>
          </div>
        </div>


    </div>
  )
}

export default PharmacyRow