/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import PdfIcon from "../../assets/pdfIcon.svg";
import TextIcon from "../../assets/textIcon.svg";
import TrustProfileImg from "../../assets/trustProfileLogo.jpg";
import Container from "../../components/container/container";
import DownloadBlock from "../../components/download-block/download-block";
import Col from "../../components/grid/col";
import Row from "../../components/grid/row";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import "./prescriptions-page.scss";

const WniosekWrapper: React.FC<{ children: React.ReactNode; title: string }> = ({ children, title }) => {
  return (
    <PageWrapper className="prescriptions-page">
      <section className="pageWrapper__section">
        <Container>
          <MainHeader>{title}</MainHeader>
          <Row gap={32}>
            <Col size={[6, 6, 12, 12]}>
              {children}
              <StandardTextBlock>
                <br />
                <br />
                <br />
                <br />
                <h2 tabIndex={0} className="title">
                  WNIOSEK O PRZEPISANIE RECEPTY NA LEKI STAŁE
                </h2>
                <div className="downloadBlocksWrapper">
                  <DownloadBlock label="Pobierz druk zapotrzebowania na leki w wersji papierowej do wydruku .docx 18KB" img={TextIcon} size="18KB" title={"POBIERZ PLIK .DOCX"} to={"./do-pobrania/ZAPOTRZEBOWANIE-NA-RECEPTE-NOWY-DRUK.docx"} />
                  <DownloadBlock label="Pobierz druk zapotrzebowania na leki w wersji papierowej do wydruku .pdf 64KB dokument odtworzy się w nowym oknie" img={PdfIcon} title={"POBIERZ PLIK .PDF"} size="64KB" to={"./do-pobrania/ZAPOTRZEBOWANIE-NA-RECEPTE-NOWY-DRUK.pdf"} />
                </div>
                <h2 tabIndex={0} className="title">
                  WNIOSEK O WYSTAWIENIE SKIEROWANIA DO SPECJALISTY
                </h2>
                <div className="downloadBlocksWrapper">
                  <DownloadBlock label="Pobierz druk wniosku o wystawienie skierowania do specjalisty w wersji papierowej do wydruku .docx 16KB" img={TextIcon} size="16KB" title={"POBIERZ PLIK .DOCX"} to={"./do-pobrania/Druk - wwniosek na skierowanie.docx"} />
                  <DownloadBlock label="Pobierz druk wniosku o wystawienie skierowania do specjalisty w wersji papierowej do wydruku .pdf 25KB dokument odtworzy się w nowym oknie" img={PdfIcon} title={"POBIERZ PLIK .PDF"} size="25KB" to={"./do-pobrania/Skierowanie do specjalisty.pdf"} />
                </div>
                <h2 tabIndex={0} className="title">
                  UPOWAŻNIENIE JEDNORAZOWE DO ODBIORU WYNIKÓW
                </h2>
                <div className="downloadBlocksWrapper">
                  <DownloadBlock label="Pobierz upoważnienie jednorazowe do odbioru wyników .doc 14KB" img={TextIcon} size="14KB" title={"POBIERZ PLIK .DOC"} to={"./do-pobrania/upowaznienie-wyniki-jednorazowe.docx"} />
                  <DownloadBlock label="Pobierz upoważnienie jednorazowe do odbioru wyników .pdf 97KB dokument odtworzy się w nowym oknie" img={PdfIcon} title={"POBIERZ PLIK .PDF"} size="97KB" to={"./do-pobrania/upowaznienie-wyniki-jednorazowe.pdf"} />
                </div>
                <h2 tabIndex={0} className="title">
                  UPOWAŻNIENIE STAŁE DO ODBIORU WYNIKÓW
                </h2>
                <div className="downloadBlocksWrapper">
                  <DownloadBlock label="Pobierz upoważnienie stałe do odbioru wyników .doc 23KB" img={TextIcon} size="23KB" title={"POBIERZ PLIK .DOC"} to={"./do-pobrania/upowaznienie-wyniki-stale-A5.docx"} />
                  <DownloadBlock label="Pobierz upoważnienie stałe do odbioru wyników .pdf 108KB dokument odtworzy się w nowym oknie" img={PdfIcon} title={"POBIERZ PLIK .PDF"} size="108KB" to={"./do-pobrania/upowaznienie-wyniki-stale-A5.pdf"} />
                </div>
              </StandardTextBlock>
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <div className="loginInfoWrapper">
                {/* <div className="grayBox">
                    UWAGA: Profil zaufany i IKP założyć można w Naszej Przychodni w gab. 206 (II piętro) w godzinach od 7:30 do 15:00
                  </div> */}
                <img className={"loginLogo no-invert"} src={TrustProfileImg} alt="Profil zaufany logo" />
                {/* <img className={'loginLogo'} src={IkpLogoImg} alt="internetowe konto pacjenta" /> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </PageWrapper>
  );
};

export default WniosekWrapper;
