/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import Helmet from "react-helmet";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import "./prescriptions-page.scss";
import PrescriptionsForm from "./prescriptions-form";
import WniosekWrapper from "./wniosek-wrapper";
import Modal from "../../components/modal/modal";
import Container from "../../components/container/container";
import PatientInfoCopy from "../../components/patient-info copy/patient-info-copy";
import { RoutePath } from "../../../route-paths";
import ExampleIcon from "../../assets/exampleIcon.svg";
import PatientInfoCopies from "../../components/patient-info copy 2/patient-info-copy2";

const PrescriptionsPage: React.FC<any> = () => {
  return (
    <WniosekWrapper title="Recepta na leki stałe">
      <Helmet>
        <title>{"Wniosek o przepisanie recepty | SPZOZ Przychodnia Syców"}</title>
      </Helmet>
      <StandardTextBlock>
        <p>
          Samodzielny Publiczny Zakład Opieki Zdrowotnej “Przychodnia” w Sycowie informuje, iż w celu ograniczenia kontaktu między pacjentami w Przychodni{" "}
          <strong>
            <span style={{ color: "#B30000" }}>KOD WYSTAWIONEJ RECEPTY</span>
          </strong>{" "}
          można uzyskac telefonicznie pod numerem telefonu:
        </p>
        <br />
        <p>
          <strong>
            <a aria-label="Pod numerem telefonu 62 785 51 96" href="tel:627855196">
              62 785 51 96
            </a>{" "}
            do godziny 12:00
          </strong>
        </p>
        <p>
          <strong>
            <a aria-label="Pod numerem telefonu 62 785 51 92" href="tel:627855192">
              62 785 51 95
            </a>{" "}
            po godzinie 12:00
          </strong>
        </p>
        <br />
        <p><strong>WIRTUALNA REJESTRATORKA</strong></p>
        <br />
        <section className="patientInfoCopy__section">
        <Container>
          <PatientInfoCopies title="Sprawdź jak zamówić receptę telefonicznie" icon={ExampleIcon} to={RoutePath.INFORMACJEDLAPACJENTAPAGE} />
        </Container>
      </section>
        <br />
        <br />
        <br />
        <h2 tabIndex={0} className="title">
          WNIOSEK O PRZEPISANIE LEKÓW STAŁYCH
        </h2>
        <br />
        <PrescriptionsForm />
      </StandardTextBlock>
    </WniosekWrapper>
  );
};

export default PrescriptionsPage;
