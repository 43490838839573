import "./search-bar.scss";
import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "./../../assets/searchIcon.svg";
import { useDispatch } from "react-redux";
import { setSearchPhrase, showSlideOverlay } from "../../redux/callbacksState/callbacksState.actions";
import { RoutePath } from "../../../route-paths";
import history from './../../../history';


interface Props {
}

const SearchBar: React.FC<any> = () => {
  const searchInput = useRef<any>();
  const [search, setSearch] = useState('');
  const [isActive, setIsActive] = useState(false);

  const dispatch = useDispatch();

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      dispatch(setSearchPhrase(search))
      e.target.blur();
      setSearch('');
      if (history.location.pathname !== RoutePath.SEARCH) {
        dispatch(showSlideOverlay(RoutePath.SEARCH as string))
      }
      const mainContainer:(HTMLElement | null) = document.querySelector(".container");
      if(mainContainer){
        mainContainer.tabIndex = -1;
        mainContainer.focus();
      } 
    }
  }

  const handleOnClick = (e: any) => {
      dispatch(setSearchPhrase(search))
      e.target.blur();
      setSearch('');
      if (history.location.pathname !== RoutePath.SEARCH) {
        dispatch(showSlideOverlay(RoutePath.SEARCH as string))
      
      const mainContainer:(HTMLElement | null) = document.querySelector(".container");
      if(mainContainer){
        mainContainer.tabIndex = -1;
        mainContainer.focus();
      } 
    }
  }


  const handleKeypress = (e: KeyboardEvent) => {
    if (e.altKey && e.code === 'KeyS') {
      searchInput.current!.focus();
    }
    if (e.code === 'Escape') {
      searchInput.current!.blur();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeypress, { passive: true });

    return () => {
      window.removeEventListener('keydown', handleKeypress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <div className="search-bar">
      <div className={`inputWrapper${isActive ? ' isActive' : ''}`}>
        <input
          title="Wyszukiwarka"
          ref={searchInput}
          type="text"
          value={search}
          name={'search'}
          placeholder={'Szukaj usług, informacji, wiadomości i inne'}
          id = {'search'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
        />
        <label className="sr-only" htmlFor='search'>Wpisz wyszukiwaną frazę</label>
        <button className="inputIcon" id="btnSearch" onClick={handleOnClick} tabIndex={0} onKeyDown={handleKeyDown} >
        <label className="sr-only" htmlFor='btnSearch'>Wyszukaj</label>
          <img src={SearchIcon} id="searchicon" alt="Wyszukaj" />
        </button>
      </div>
    </div>
  )
}

export default SearchBar;