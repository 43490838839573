import React from "react";
import "./main-header.scss";

interface Props {
  children: React.ReactNode;
  align?: 'left' | 'center' | 'right';
  tabindex?: number;
  arialabel?:string;
  id?: string
}

const MainHeader: React.FC<Props> = (props: Props) => {
  const { children, align, tabindex=-1, arialabel, id } = props;
  return (
    <>
    <h1  className={`main-header ${align ? align : "left"}`} tabIndex={tabindex} aria-label={arialabel} id={id && id}>
      {children}
    </h1 >
    </>
  )
}

export default MainHeader;