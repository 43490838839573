import "./App.scss";
import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { History } from "history";
import CookiePolicyPopup from "./framework/components/cookie-policy-popup/cookie-policy-popup";
import { RoutePath } from "./route-paths";
import HomePage from "./framework/pages/home-page/home-page";
import { compose } from "redux";
import { connect, shallowEqual, useSelector } from "react-redux";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";
import { RulesPage } from "./framework/pages/rules-page/rules-page";
import Navbar from "./framework/components/navbar/navbar";
import { select_is_navbarOpenActive } from "./framework/redux/callbacksState/callbacksState.selectors";
import PreFooter from "./framework/components/prefooter/prefooter";
import SlideOverlay from "./framework/components/slide-overlay/slide-overlay";
import NewsPage from "./framework/pages/news-page/news-page";
import CovidInfoPage from "./framework/pages/covid-info-page/covid-info-page";
import PrincingPage from "./framework/pages/princing-page/princing-page";
import PrivOfficesPage from "./framework/pages/priv-offices-page/priv-offices-page";
import TreatmentOfficePage from "./framework/pages/treatment-office-page/treatment-office-page";
import StradomiaWierzchniaPpage from "./framework/pages/stradomia-wierzchnia-page/stradomia-wierzchnia-page";
import SpecialistClinicsPage from "./framework/pages/specialist-clinics-page/specialist-clinics-page";
import PublicTendersPage from "./framework/pages/public-tenders/public-tenders-page";
import BasicHealthcarePage from "./framework/pages/basic-healthcare-page/basic-healthcare-page";
import PrescriptionsPage from "./framework/pages/prescriptions-page/prescriptions-page";
import SearchPage from "./framework/pages/search-page/search-page";
import DyzuryAptekPage from "./framework/pages/dyzury-aptek-page/dyzury-aptek-aptek";
import KartaPrawPacjentaPage from "./framework/pages/karta-praw-pacjenta-page/karta-praw-pacjenta-page";
import SkargiIWnioskiPage from "./framework/pages/skargi-i-wnioski-page/skargi-i-wnioski-page";
import ContactPage from "./framework/pages/contact-page/contact-page";
import LawInfoPage from "./framework/pages/law-info-page/law-info-page";
import InformacjeDlaPacjentaPage from "./framework/pages/informacje-dla-pacjenta/informacje-dla-pacjenta-page";
import RozpozadzenieMinistraZdrowiaPage from "./framework/pages/rozpozadzenie-ministra-zdrowia-page/rozpozadzenie-ministra-zdrowia-page";
import OchronaDanychOsobowychPage from "./framework/pages/ochrona-danych-osobowych-page/ochrona-danych-osobowych-page";
//import GabinetInfekcyjnyPage from "./framework/pages/gabinet-infekcyjny-page/gabinet-infekcyjny-page";
import DeklaracjaDostepnosciPage from "./framework/pages/deklaracja-dostepnosci-page/deklaracja-dostepnosci-page";
import SzczepionkaInfoPage from "./framework/pages/szczepionka-info-page/szczepionka-info-page";
import NocnaOpiekaPage from "./framework/pages/nocna-opieka-page/nocna-opieka-page";
import JezykMigowyPage from "./framework/pages/jezyk-migowy-page/jezyk-migowy-page";
import Footer from "./framework/components/footer/footer";
import Helmet from "react-helmet";
import SkierowaniaPage from "./framework/pages/prescriptions-page/skierowania-page";
import StandardyOchronyMaloletnichPage from "./framework/pages/standardy-chrony-maloletnich/standardy-chrony-maloletnich";

interface Props {
  match: any;
  history?: History;
}

const App: React.FC<Props> = () => {
  const is_navbarOpenActive = useSelector(select_is_navbarOpenActive, shallowEqual);

  return (
    <>
      <Helmet>
        <meta name="deklaracja-dostępności" content="https://spzozprzychodnia.pl/deklaracja-dostepnosci"></meta>
      </Helmet>
      <SlideOverlay />
      <Navbar />
      <div className={`overlay${is_navbarOpenActive ? " isActive" : ""}`}>
        <Switch>
          <Route exact path={RoutePath.RULES} render={() => <RulesPage />} />
          <Route exact path={RoutePath.NEWS} render={() => <NewsPage />} />
          <Route exact path={RoutePath.COVIDINFO} render={() => <CovidInfoPage />} />
          <Route exact path={RoutePath.PRINCING} render={() => <PrincingPage />} />
          <Route exact path={RoutePath.PRIVOFFICES} render={() => <PrivOfficesPage />} />
          <Route exact path={RoutePath.TREATMENTOFFICE} render={() => <TreatmentOfficePage />} />
          <Route exact path={RoutePath.STRADOMIAWIERZCHNIA} render={() => <StradomiaWierzchniaPpage />} />
          <Route exact path={RoutePath.SPECIALISTCLINICS} render={() => <SpecialistClinicsPage />} />
          <Route exact path={RoutePath.PUBLICTENDERS} render={() => <PublicTendersPage />} />
          <Route exact path={RoutePath.BASICHEALTHCARE} render={() => <BasicHealthcarePage />} />
          <Route exact path={RoutePath.PRESCRIPTIONS} render={() => <PrescriptionsPage />} />
          <Route exact path={RoutePath.SKIEROWANIENABADANIA} render={() => <SkierowaniaPage />} />
          <Route exact path={RoutePath.DYZURYAPTEK} render={() => <DyzuryAptekPage />} />
          <Route exact path={RoutePath.KARTAPRAWPACJENTA} render={() => <KartaPrawPacjentaPage />} />
          <Route exact path={RoutePath.SKARGIIWNIOSKI} render={() => <SkargiIWnioskiPage />} />
          <Route exact path={RoutePath.SEARCH} render={() => <SearchPage />} />
          <Route exact path={RoutePath.CONTACT} render={() => <ContactPage />} />
          <Route exact path={RoutePath.LAWINFO} render={() => <LawInfoPage />} />
          <Route exact path={RoutePath.INFORMACJEDLAPACJENTAPAGE} render={() => <InformacjeDlaPacjentaPage />} />
          <Route exact path={RoutePath.ROZPOZADZENIEMINISTRAZDROWIAPAGE} render={() => <RozpozadzenieMinistraZdrowiaPage />} />
          <Route exact path={RoutePath.OCHRONADANYCHOSOBOWYCHPAGE} render={() => <OchronaDanychOsobowychPage />} />
          <Route exact path={RoutePath.DEKLARACJADOSTEPNOSCI} render={() => <DeklaracjaDostepnosciPage />} />
          <Route exact path={RoutePath.SZCZEPIONKAINFO} render={() => <SzczepionkaInfoPage />} />
          <Route exact path={RoutePath.NOCNAOPIEKA} render={() => <NocnaOpiekaPage />} />
          <Route exact path={RoutePath.JEZYKMIGOWYPAGE} render={() => <JezykMigowyPage />} />
          <Route exact path={RoutePath.HOME} render={() => <HomePage />} />
          <Route exact path={RoutePath.OCHRONAMALOLETNICH} render={() => <StandardyOchronyMaloletnichPage />} />
        </Switch>

        <footer>
          <PreFooter />
          <Footer />
        </footer>
      </div>
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      <CookiePolicyPopup />
    </>
  );
};

export default compose(withRouter, connect(null, null))(App);
