import React from "react";
import "./collapsible-block.scss";

interface Props {
  children: React.ReactNode;
}

const CollapsibleBlocksWrapper: React.FC<Props> = (props: Props) => {
  const { children } = props;
  return (
    <div className="collapsible-blocks-wrapper">
      {children}
    </div>
  )
}

export default CollapsibleBlocksWrapper;