import React from "react";
import "./patient-info-copy.scss";
interface Props {
  title: string;
  icon: string;
  to: string;
}

const PatientInfoCopy = (props: Props) => {

  return (
    <div className="patientInfoCopy youtube-embed">
      <div className="content__wrapper">
        <div className="links__wrapper">
          <div className="links__wrapper_column">
            <iframe src="https://www.youtube.com/embed/23MjLdQPno8?controls=0&autoplay=1" title="SPZOZ Przychodnia Syców - informacja dla pacjentów niedosłyszących" frameBorder="0" allow="accelerometer; autoplay;" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientInfoCopy;
