import React from "react";
import "./TextInput.scss";
import { FieldError, FieldErrorsImpl, Merge, RefCallBack } from "react-hook-form";

interface TextInputProps {
  id?: string;
  label?: string;
  name?: string;
  validation_msg?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  required?: boolean;
  hiddenBridgeField?: boolean;
  // type?: "text" | "textarea" | "number"
  inputProps?: React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> & {
    rows?: number;
    control?: any;
    placeholder?: string;
    ref?: RefCallBack;
    onChange?: any;
    onBlur?: any;
    register?: any;
    type?: "text" | "textarea" | "number" | "password" | "email";
  };
}

const TextInput = (props: TextInputProps) => {
  const {
    id,
    label,
    validation_msg,
    required,
    inputProps = { placeholder: undefined, ref: undefined, register: undefined, rows: 0, type: "text" },
    hiddenBridgeField,
  } = props;

  return (
    <div className={`form-child TextInput type_${inputProps.type} ${hiddenBridgeField ? "hidden-bridge" : ""}`}>
      {label ? (
        <label htmlFor={id}>
          <span className="label">{label + (required ? "*" : "")}</span>{" "}
          <span className={`validation-messages ${validation_msg ? "active" : ""}`}>{validation_msg ? <>({validation_msg})</> : <>&nbsp;</>}</span>
        </label>
      ) : null}
      {inputProps.type === "textarea" ? (
        <textarea id={id} rows={inputProps.rows ? inputProps.rows : 8} className="form-control" placeholder={inputProps.placeholder ?? label} {...inputProps} />
      ) : (
        <input type="text" id={id} {...inputProps} placeholder={inputProps.placeholder ?? label} />
      )}
    </div>
  );
};

export default TextInput;
