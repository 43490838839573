import React from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { toggleContrast } from "../../../redux/contrastState/contrastState.actions";
import { selectContrastState } from "../../../redux/contrastState/contrastState.selectors";
import { selectFontSizeState } from "../../../redux/fontSizeState/fontSizeState.selectors";
import "./contrast-changer.scss";
import ContrastImg from "./contrast.svg";

interface Props {

}

const ContrastChanger: React.FC<Props> = () => {
  const theme = useSelector(selectContrastState);
  const fontSize = useSelector(selectFontSizeState);

  const dispatch = useDispatch();

  const handleKeyDownMinus = (e: any) => {
    if (e.key === 'Enter') dispatch(toggleContrast()) 
  }

  return (
    <>
      <Helmet htmlAttributes={{ class: `theme-${theme} font-size-${fontSize}` }} />

      <div className="contrast-changer">
        <div className="changer-btn changer-btn--contrast" onClick={() => dispatch(toggleContrast())} onKeyDown={handleKeyDownMinus} tabIndex={0}>
          <img src={ContrastImg} alt="Zmień kontrast" />
        </div>
      </div>
    </>
  )

}

export default ContrastChanger;