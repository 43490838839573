import React from "react";
import Helmet from 'react-helmet';
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import "./rozpozadzenie-ministra-zdrowia-page.scss";

const RozpozadzenieMinistraZdrowiaPage: React.FC<any> = () => {
  return (
    <PageWrapper className="rules-page rozpozadzenie-ministra-zdrowia-page">
      <Helmet>
        <title>{'Rozporządzenie Ministra Zdrowia | SPZOZ Przychodnia Syców'}</title>
      </Helmet>
      <Container>
        <section className="pageWrapper__section">
          <MainHeader>
          ROZPORZĄDZENIE MINISTRA ZDROWIA
          </MainHeader>
          <br />
          <StandardTextBlock>
            <br/>
            <p>Dz.U.2014.1144</p>
            <br/>
            <br/>
            <p className="act-name" style={{ textAlign: 'center' }}><strong>ROZPORZĄDZENIE MINISTRA ZDROWIA</strong></p>
            <br/>
            <p className="act-day" style={{ textAlign: 'center' }}>z dnia 26 sierpnia 2014 r.</p>
            <br/>
            <p className="act-title" style={{ textAlign: 'center' }}>w sprawie badań lekarskich kandydatów do szkół ponadgimnazjalnych lub wyższych i na kwalifikacyjne kursy zawodowe, uczniów tych szkół, studentów, słuchaczy kwalifikacyjnych kursów zawodowych oraz uczestników studiów doktoranckich</p>
            <br/>
            <p className="act-pubday" style={{ textAlign: 'center' }}>(Dz. U. z dnia 28 sierpnia 2014 r.)</p>
            <br/>
            <p className="preambula">
              <p>Na podstawie art. 6 ust. 5 ustawy z dnia 27 czerwca 1997 r. o służbie medycyny pracy (Dz. U. z 2004 r. Nr 125, poz. 1317, z późn. zm.) zarządza się, co następuje:</p>
            </p>
            <br/>
            <br/>
            <br/>
            <p><span className="parL">§1.</span>Rozporządzenie określa zakres i tryb oraz sposób dokumentowania badań lekarskich:</p>
            <p className="pktB parB_pktB"><span className="pktL">1)</span>kandydatów do szkół ponadgimnazjalnych lub wyższych i na kwalifikacyjne kursy zawodowe, uczniów tych szkół, studentów oraz słuchaczy kwalifikacyjnych kursów zawodowych, którzy w trakcie praktycznej nauki zawodu narażeni są na działanie czynników szkodliwych, uciążliwych lub niebezpiecznych dla zdrowia;</p>
            <p className="pktB parB_pktB"><span className="pktL">2)</span>uczestników studiów doktoranckich, którzy w trakcie studiów są narażeni na działanie czynników szkodliwych, uciążliwych lub niebezpiecznych dla zdrowia.</p>
            <p><span className="parL">§2.</span>Ilekroć w niniejszym rozporządzeniu jest mowa o:</p>
            <p className="pktB parB_pktB"><span className="pktL">1)</span>osobach badanych – rozumie się przez nie kandydatów do szkół ponadgimnazjalnych lub wyższych i na kwalifikacyjne kursy zawodowe, uczniów tych szkół, studentów oraz słuchaczy kwalifikacyjnych kursów zawodowych, którzy w trakcie praktycznej nauki zawodu narażeni są na działanie czynników szkodliwych, uciążliwych lub niebezpiecznych dla zdrowia, oraz uczestników studiów doktoranckich, którzy w trakcie studiów są narażeni na działanie czynników szkodliwych, uciążliwych lub niebezpiecznych dla zdrowia;</p>
            <p className="pktB parB_pktB"><span className="pktL">2)</span>placówkach dydaktycznych – rozumie się przez nie szkołę ponadgimnazjalną, szkołę wyższą, organizatora kwalifikacyjnych kursów zawodowych oraz jednostkę uprawnioną do prowadzenia studiów doktoranckich.</p>
            <p><span className="parL">§3.</span><span className="ustB parB_ustB"><span className="ustL">1.</span>Lekarz przeprowadza badanie lekarskie na podstawie skierowania wydanego przez placówkę dydaktyczną.</span></p>
            <p className="ustB parB_ustB"><span className="ustL">2.</span>Skierowanie na badanie lekarskie zawiera:</p>
            <p className="pktB ustB_pktB"><span className="pktL">1)</span>pieczątkę placówki dydaktycznej;</p>
            <p className="pktB ustB_pktB"><span className="pktL">2)</span>imię i nazwisko, datę urodzenia i numer PESEL osoby badanej, a jeżeli nie posiada numeru PESEL – rodzaj, serię i numer dokumentu potwierdzającego tożsamość;</p>
            <p className="pktB ustB_pktB"><span className="pktL">3)</span>informację o kierunku kształcenia oraz o czynnikach szkodliwych, uciążliwych lub niebezpiecznych dla zdrowia, występujących w miejscu odbywania praktycznej nauki zawodu, studiów, kwalifikacyjnych kursów zawodowych albo studiów doktoranckich;</p>
            <p className="pktB ustB_pktB"><span className="pktL">4)</span>datę wystawienia skierowania;</p>
            <p className="pktB ustB_pktB"><span className="pktL">5)</span>pieczątkę i podpis osoby kierującej na badanie lekarskie.</p>
            <p>
              <p><span className="parL">§4.</span><span className="ustB parB_ustB"><span className="ustL">1.</span>Lekarz przeprowadza badanie lekarskie z uwzględnieniem:</span></p>
              <p className="pktB ustB_pktB"><span className="pktL">1)</span>oceny potencjalnych lub istniejących zagrożeń dla zdrowia, występujących w miejscu odbywania praktycznej nauki zawodu, studiów, kwalifikacyjnych kursów zawodowych albo studiów doktoranckich;</p>
              <p className="pktB ustB_pktB"><span className="pktL">2)</span>zakresu i częstotliwości przeprowadzania badań profilaktycznych pracowników określonych w przepisach wydanych na podstawie art. 229 § 8 ustawy z dnia 26 czerwca 1974 r. – Kodeks pracy (Dz. U. z 1998 r. Nr 21, poz. 94, z późn. zm.);</p>
              <p className="pktB ustB_pktB"><span className="pktL">3)</span>prac wzbronionych młodocianym określonych w przepisach wydanych na podstawie art. 204 § 3 ustawy z dnia 26 czerwca 1974 r. – Kodeks pracy.</p>
            </p>
            <p className="ustB parB_ustB"><span className="ustL">2.</span>Po przeprowadzeniu badania lekarskiego lekarz wydaje zaświadczenie lekarskie.</p>
            <p className="ustB parB_ustB"><span className="ustL">3.</span>Zaświadczenie lekarskie zawiera:</p>
            <p className="pktB ustB_pktB"><span className="pktL">1)</span>pieczątkę jednostki służby medycyny pracy przeprowadzającej badanie lekarskie;</p>
            <p className="pktB ustB_pktB"><span className="pktL">2)</span>datę wydania zaświadczenia lekarskiego;</p>
            <p className="pktB ustB_pktB"><span className="pktL">3)</span>imię i nazwisko, datę urodzenia i numer PESEL osoby badanej, a jeżeli nie posiada numeru PESEL – rodzaj, serię i numer dokumentu potwierdzającego tożsamość;</p>
            <p className="pktB ustB_pktB"><span className="pktL">4)</span>kierunek praktycznej nauki zawodu albo kształcenia;</p>
            <p className="pktB ustB_pktB"><span className="pktL">5)</span>nazwę i adres placówki dydaktycznej, która wydała skierowanie na badania lekarskie;</p>
            <p className="pktB ustB_pktB"><span className="pktL">6)</span>orzeczenie o istnieniu lub braku przeciwwskazań zdrowotnych do wykonywania i pobierania praktycznej nauki zawodu, studiów, kwalifikacyjnych kursów zawodowych albo studiów doktoranckich;</p>
            <p className="pktB ustB_pktB"><span className="pktL">7)</span>podpis i pieczątkę lekarza;</p>
            <p className="pktB ustB_pktB"><span className="pktL">8)</span>datę następnego badania lekarskiego wynikającą z przepisów wydanych na podstawie art. 229 § 8 ustawy z dnia 26 czerwca 1974 r. – Kodeks pracy;</p>
            <p className="pktB ustB_pktB"><span className="pktL">9)</span>pouczenie o terminie i sposobie wniesienia odwołania od zaświadczenia lekarskiego.</p>
            <p className="ustB parB_ustB"><span className="ustL">4.</span>W przypadku stwierdzenia w zaświadczeniu lekarskim istnienia przeciwwskazań zdrowotnych do wykonywania i pobierania praktycznej nauki zawodu, studiów, kwalifikacyjnych kursów zawodowych albo studiów doktoranckich lekarz informuje osobę badaną o przyczynach uzasadniających wydanie takiego zaświadczenia oraz wpisuje uzasadnienie do dokumentacji badania lekarskiego osoby badanej.</p>
            <p className="ustB parB_ustB"><span className="ustL">5.</span>Zaświadczenie lekarskie wydaje się w dwóch egzemplarzach, z których jeden osoba badana przekazuje placówce dydaktycznej, a drugi jest dołączany do dokumentacji badania lekarskiego osoby badanej. W przypadku gdy osoba badana, której zaświadczenie lekarskie dotyczy, nie posiada pełnej zdolności do czynności prawnych, zaświadczenie wydaje się jej przedstawicielowi ustawowemu, który przekazuje je placówce dydaktycznej.</p>
            <p><span className="parL">§5.</span><span className="ustB parB_ustB"><span className="ustL">1.</span>Od zaświadczenia lekarskiego osobie badanej oraz placówce dydaktycznej przysługuje odwołanie wnoszone na piśmie.</span></p>
            <p className="ustB parB_ustB"><span className="ustL">2.</span>Odwołanie wraz z uzasadnieniem wnosi się w terminie 14 dni od dnia otrzymania zaświadczenia lekarskiego, za pośrednictwem lekarza, który wydał zaświadczenie lekarskie, do wojewódzkiego ośrodka medycyny pracy, właściwego ze względu na siedzibę placówki dydaktycznej, a w przypadku gdy odwołanie dotyczy zaświadczenia lekarskiego wydanego w wojewódzkim ośrodku medycyny pracy – do instytutu badawczego w dziedzinie medycyny pracy.</p>
            <p className="ustB parB_ustB"><span className="ustL">3.</span>Lekarz, za którego pośrednictwem jest wnoszone odwołanie, przekazuje je wraz z dokumentacją badania lekarskiego osoby badanej do podmiotu odwoławczego w terminie 7 dni od dnia otrzymania odwołania.</p>
            <p className="ustB parB_ustB"><span className="ustL">4.</span>Badanie lekarskie w trybie odwoławczym przeprowadza się w terminie 14 dni od dnia otrzymania odwołania przez podmiot odwoławczy.</p>
            <p className="ustB parB_ustB"><span className="ustL">5.</span>Do zaświadczeń lekarskich wydanych w trybie odwoławczym stosuje się odpowiednio przepisy § 4.</p>
            <p className="ustB parB_ustB"><span className="ustL">6.</span>Zaświadczenie lekarskie wydane w trybie odwoławczym jest ostateczne.</p>
            <p><span className="parL">§6.</span>W przypadku gdy zaświadczenie lekarskie wydał lekarz kolejowego ośrodka medycyny pracy, odwołanie od zaświadczenia lekarskiego składa się, za jego pośrednictwem, do Centrum Naukowego Medycyny Kolejowej. Przepisy § 5 stosuje się odpowiednio.</p>
            <p><span className="parL">§7.</span>Lekarz dokumentuje badanie lekarskie zgodnie z przepisami wydanymi na podstawie art. 11 ust. 4 ustawy z dnia 27 czerwca 1997 r. o służbie medycyny pracy.</p>
            <p><span className="parL">§8.</span>Do przeprowadzania badań lekarskich, rozpoczętych i niezakończonych przed dniem wejścia w życie niniejszego rozporządzenia, stosuje się przepisy dotychczasowe.</p>
            <p><span className="parL">§9.</span>Rozporządzenie wchodzi w życie z dniem 1 września 2014 r.</p>
            <p></p>
            <p>&nbsp;————————————————————-</p>
            <p></p>
            <p style={{ textAlign: 'justify' }}>Wzór skierowania na badanie lekarskie dla kandydatów do szkół ponadgimnazjalnych lub wyższych i na kwalifikacyjne kursy zawodowe, uczniów tych szkół, studentów, słuchaczy kwalifikacyjnych kursów zawodowych oraz uczestników studiów doktoranckich &nbsp;<span><a aria-label="Skierowanie PDf 1331 KB dokument otworzy się w nowym oknie" href="./do-pobrania/Skierowanie.pdf" target="_blank">do pobrania</a></span></p>
            <br/>
          </StandardTextBlock>
        </section>
      </Container>
    </PageWrapper>
  )
}

export default RozpozadzenieMinistraZdrowiaPage;