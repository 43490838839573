import React, { useEffect } from "react";
import Helmet from 'react-helmet';
import { useLocation } from "react-router-dom";
import CollapsibleBlock from "../../components/collapsible-block/collapsible-block";
import CollapsibleBlocksWrapper from "../../components/collapsible-block/collapsible-blocks-wrapper";
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import './informacje-dla-pacjenta-page.scss';

const InformacjeDlaPacjentaPage: React.FC<any> = (props: any) => {

  const location = useLocation();
  const patientHash = location.hash; 

  useEffect(() => {
    if(patientHash === ''){
      window.scroll(0,0);
    }else {
      setTimeout(() => {
        const id = patientHash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          let dims = element.getBoundingClientRect();
          let headerHeight = document.querySelector(".navbarBarWrapper")?.clientHeight;
          headerHeight && window.scrollBy(0, dims.top-headerHeight)
        }
      }, 0);
    }
  }, [patientHash]);

  return (
    <PageWrapper className="informacje-dla-pacjenta-page">
      <Helmet>
        <title>{'Informacje dla pacjenta | SPZOZ Przychodnia Syców'}</title>
      </Helmet>
      <Container>
        <section className="pageWrapper__section">
          <MainHeader>
            INFORMACJE DLA PACJENTA
          </MainHeader>
          <br />
          <CollapsibleBlocksWrapper>
          <CollapsibleBlock
              title="Informacje dotyczące dokumentacji medycznej" id='dokumentacja-medyczna' >
              <StandardTextBlock>
                <p><a aria-label="Komunikat w sprawie dokumentacji medycznej PDF 115KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/informacja-dokumentacja-medyczna.pdf">Komunikat w sprawie dokumentacji medycznej (115kB)</a></p>
              </StandardTextBlock>
            </CollapsibleBlock>
          <CollapsibleBlock
              title="Rejestracja" id='rejestracja' isOpenInit={patientHash === '#rejestracja' && true} >
              <StandardTextBlock>
                <p><strong>1. Ogólne zasady rejestracji.</strong></p>
                <ul style={{listStyleType: "lower-alpha"}}>
                  <li>Rejestracja pacjentów odbywa się codziennie od poniedziałku do piątku od godz. 7.00 do 17.30.</li>
                  <li>Pacjent może zarejestrować się osobiście, telefonicznie lub za pośrednictwem osoby trzeciej.</li>
                  <li><strong>Pacjent może zarejestrować maksymalnie 2 osoby.</strong></li>
                </ul>
                <br />
                <p><strong>2. Wymagane dokumenty:</strong></p>
                <ul style={{listStyleType: "lower-alpha"}}>
                  <li>dowód tożsamości,</li>
                  <li><strong>Poza kolejnością przyjmowane są uprawnione osoby</strong></li>
                </ul>
                <br />
                <p><strong>3. Ustalanie terminu wizyty:</strong></p>
                <ul style={{listStyleType: "lower-alpha"}}>
                  <li>Pacjentowi wyznaczany jest termin wizyty, który może być zmieniony po obustronnym uzgodnieniu,</li>
                  <li>Poza kolejnością przyjmowane są uprawnione osoby
                    <ul>
                      <li>Zasłużeni Honorowi Dawcy Krwi,</li>
                      <li>Zasłużeni Dawcy Przeszczepu,</li>
                      <li>Inwalidzi wojenni i wojskowi oraz kombatanci,</li>
                      <li>Uprawnieni żołnierze lub pracownicy w zakresie leczenia urazów lub chorób nabytych podczas wykonywania zadań poza granicami kraju,</li>
                      <li>Weterani poszkodowani korzystają poza kolejnością ze świadczeń opieki zdrowotnej udzielanych w zakresie leczenia urazów i chorób nabytych podczas wykonywania zadań poza granicami państwa.</li>
                    </ul>
                  </li>
                </ul>
                <br />
                <p><strong>4. Ważne informacje:</strong></p>
                <ul style={{listStyleType: "lower-alpha"}}>
                  <li>Świadczenie udzielane jest pacjentowi, który posiada aktualną deklarację wyboru lekarza  POZ.,</li>
                  <li>W przypadku rezygnacji z ustalonego terminu wizyty lub konieczności zmiany terminu obowiązkiem pacjenta jest powiadomienie o tym fakcie rejestracji. Umożliwi to wykorzystanie wolnego terminu przez inne osoby.</li>
                  <li>Pacjent w przypadku nagłego zachorowania lub nagłego pogorszenia stanu zdrowia może być przyjęty w trybie nagłym, czyli w dniu zgłoszenia. Decyzję o przyjęciu pacjenta w trybie nagłym podejmuje lekarz.</li>
                  <li>W przypadku wystąpienia  schorzenia przewlekłego świadczenia są udzielane w terminie uzgodnionym ze świadczeniobiorcą.</li>
                </ul>
                <br />
                <p><strong>5. Czas oczekiwania na wystawienie recepty do 5 dni po złożeniu wniosku.</strong></p>
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="Informacja o świadczeniach w zakresie POZ w nocy, w dni wolne oraz niedziele i święta"  id={'info-poz-w-dni-wolne'}>
            <StandardTextBlock>
                <p><strong>Nocna i świąteczna opieka zdrowotna</strong></p>
                <br />
                <p>Świadczenia Nocnej i Świątecznej Opieki Zdrowotnej <strong>w razie nagłego zachorowania lub nagłego pogorszenia stanu zdrowia</strong> sprawuje Powiatowy Zespół Szpitali w Oleśnicy:</p>
                <p>Świadczenia udzielane będą w pomieszczeniach znajdujących się w budynku:</p>
                <ul>
                  <li>Szpital w Oleśnicy przy ul. Armii Krajowej 1, tel.<a aria-label="Pod numerem telefonu 71 776 73 37" href="tel:717767337">71 776 73 37</a> codziennie w godz. od 18:00-8:00 oraz całodobowo w dni ustawowo wolne od pracy</li>
                  <li>Szpital w Sycowie przy ul. Oleśnickiej 25, tel.<a aria-label="Pod numerem telefonu 71 776 74 00" href="tel:717767400">71 776 74 00</a> od piątku do poniedziałku w godz. 20:00-8:00 oraz od godz. 20:00 dnia poprzedzającego dzień wolny od pracy do godz. 8:00 dnia po okresie świątecznym</li>
                </ul>
                <p><strong>Stan nagłego zagrożenia zdrowotnego</strong> – stan polegający na nagłym lub przewidywanym w krótkim czasie pojawieniu się objawów pogarszania zdrowia, którego bezpośrednim następstwem może być poważne uszkodzenie funkcji organizmu lub uszkodzenie ciała lub utrata życia, wymagający podjęcia natychmiastowych medycznych czynności ratunkowych i leczenia (Ustawa z dnia 8 września 2008r. o Państwowym Ratownictwie Medycznym Dz. U. 2013 nr 0 poz. 757).</p>
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="Przygotowanie do Badania USG"  id={'badania-usg'}>
              <StandardTextBlock>
                <p>1. Badanie przeprowadza się na czczo.</p>
                <p>2. Przez 3 dni przed badaniem ESPUMISAN (bez recepty) 3 x 2 tabl.</p>
                <p>3. Rano w dniu badania ESPUMISAN 2 tabl., na czczo.</p>
                <p>4. Przy ocenie pęcherza lub prostaty pęcherz powinien być pełny.</p>
                <p>5. Zabrać poprzedni wynik badania USG.</p>
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="Przydatne pliki do pobrania"  id={'pliki-do-pobrania'} >
              <StandardTextBlock>
                <p><a aria-label="Wniosek o wydanie recepty w formie elektronicznej DOC 17KB " target="_blank" href="./do-pobrania/Wniosek-o-wydanie-recepty-w-formie-elektronicznej-x2.docx">Wniosek o wydanie recepty w formie elektronicznej (DOC 17KB)</a></p>
                <p><a aria-label="Deklaracja wyboru świadczeniodawcy udzielającego świadczeń z zakresu podstawowej opieki zdrowotnej oraz lekarza podstawowej opieki zdrowotnej PDF 310KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/Druk-deklaracji-LEKARZ.pdf">Deklaracja wyboru świadczeniodawcy udzielającego świadczeń z zakresu podstawowej opieki zdrowotnej oraz lekarza podstawowej opieki zdrowotnej (PDF 310KB)</a></p>
                <p><a aria-label="Deklaracja wyboru świadczeniodawcy udzielającego świadczeń z zakresu podstawowej opieki zdrowotnej oraz położnej podstawowej opieki zdrowotnej PDF 303KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/Druk-deklaracji-POLOZNA.pdf">Deklaracja wyboru świadczeniodawcy udzielającego świadczeń z zakresu podstawowej opieki zdrowotnej oraz położnej podstawowej opieki zdrowotnej (PDF 303KB)</a></p>
                <p><a aria-label="Deklaracja wyboru świadczeniodawcy udzielającego świadczeń z zakresu podstawowej opieki zdrowotnej oraz pielęgniarki podstawowej opieki zdrowotnej PDF 303KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/Druk-deklaracji-PIELEGNIARKA.pdf">Deklaracja wyboru świadczeniodawcy udzielającego świadczeń z zakresu podstawowej opieki zdrowotnej oraz pielęgniarki podstawowej opieki zdrowotnej (PDF 303KB)</a></p>
                <p><a aria-label="Ankieta wstępnej kwalifikacji PDF 271KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/Ankieta-wstepnej-kwalifikacji.pdf">Ankieta wstępnej kwalifikacji (PDF 271KB)</a></p>
                <br />
                <p>Wzory oświadczeń, które pacjent (lub jego opiekun) będą mogli składać po 1 stycznia 2013 r. w przypadku, kiedy NFZ nie potwierdzi prawa do świadczeń, a pacjent ma do nich prawo.</p>
                <br />
                <p><a aria-label="Oświadczenie pacjenta PDF 328KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/oswiadczenie_1_2015.pdf">Oświadczenie pacjenta (PDF 328KB)</a></p>
                <p><a aria-label="Oświadczenie opiekuna PDF 335KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/oswiadczenie_2_2015.pdf">Oświadczenie opiekuna (PDF 335KB)</a></p>
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="Punkt pobrań materiałów do badań laboratoryjnych"  id={'punkt-pobran-materialow'} >
              <StandardTextBlock>
                <p>PUNKT POBRAŃ</p>
                <p>Czynny od 7.30 do 10.30 (pok. 21)</p>
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="Informacje dotyczące wydawania wyników badań" id={'informacje-o-wynikach-badan'}>
              <StandardTextBlock >
                <p><strong>INFORMACJA DOTYCZĄCA WYDAWANIA WYNIKÓW BADAŃ</strong></p>
                <p>Wyniki badań zarówno laboratoryjnych jak i diagnostycznych, stanowią dokumentację medyczną i postępowanie z nimi musi odbywać się zgodnie z wymaganiami ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta z dnia 6 listopada 2008 r. oraz ustawy o ochronie danych osobowych z dnia 29 sierpnia 1997 r.</p>
                <p>Wyniki badań laboratoryjnych i diagnostycznych, tak jak inna dokumentacja medyczna mogą być wydane zgodnie ze ściśle określonymi zasadami.</p>
                <p><strong>WYDAWANIE WYNIKÓW:</strong></p>
                <ul>
                  <li>Wyniki badań wydawane są w godz.:</li>
                  <p>– 7:30 – 12:00 w gabinecie nr 9</p>
                  <p>– 12:00 – 18:00 w gabinecie zabiegowym (pok. 129)</p>
                  <p><strong>– odbiór wyników z dnia poprzedniego od godz. 11:00!</strong></p>
                  <li>Wyniki badań mogą być wydane:</li>
                  <p>– pacjentowi po przedstawieniu dokumentu tożsamości ze zdjęciem,</p>
                  <p>– lekarzowi zlecającemu dane badanie lub w przypadku umów zewnętrznych – uprawnionemu pracownikowi jednostki zlecającej,</p>
                  <p>– innym osobom po okazaniu dokumentu tożsamości oraz upoważnienia od pacjenta;</p>
                  <li>Odpisy sprawozdań z wyników badań wykonywane są na podstawie pisemnego zlecenia lekarza.</li>
                  <li>Nie udziela się telefonicznych informacji o wynikach badań,a jedynie o ich wykonaniu.</li>
                </ul>
                <p>Upoważnienie do odbioru wyniku badania jest dostępne w:</p>
                <ul>
                  <li>punkcie pobrań (gab. nr 21, parter),</li>
                  <li>punkt wydawania wyników i recept (gab. nr 9, parter),</li>
                  <li>gabinet zabiegowy (gab. nr 129, I piętro),</li>
                  <li>na stronie internetowej www.spzozprzychodnia.pl</li>
                  <li>wzór upoważnienia jednorazowego <a aria-label="wzór upoważnienia jednorazowego DOC 15KB" target="_blank" href="./do-pobrania/upowaznienie-wyniki-jednorazowe.docx">do pobrania w (.docx 15KB)</a> oraz <a aria-label="wzór upoważnienia jednorazowego PDF 95,1KB" target="_blank" href="./do-pobrania/upowaznienie-wyniki-jednorazowe.pdf">do pobrania w (.PDF 95,1 KB)</a></li>
                  <li>wzór upoważnienia stałe <a aria-label="wzór upoważnienia stałe DOCX 19KB" target="_blank" href="./do-pobrania/upowaznienie-wyniki-stale-A5.docx">do pobrania w (.docx 19KB)</a> oraz <a aria-label="wzór upoważnienia jednorazowego PDF 21,1 KB" target="_blank" href="./do-pobrania/upowaznienie-wyniki-stale-A5.pdf">do pobrania w (.PDF 21,1 KB)</a></li>
                </ul>
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="Badania KTG"  id={'badania-ktg'} >
              <StandardTextBlock>
                <p>Od dnia 02.05.2016r. w SPZOZ "Przychodnia" w Sycowie przy ul. Wrocławskiej 2, w poradni ginekologiczno-położniczej (gab. 114) istnieje możliwośd wykonania badania <strong>KTG (kardiotokografia)</strong>. Tel. do poradni: <strong><a aria-label="Pod numerem telefonu 62 785 51 47" href="tel:627855147">62 785 51 47</a></strong></p>
                <ul>
                  <li>KTG dla pacjentek powyżej 36 tygodnia ciąży, zadeklarowanych do naszej przychodni - <strong>bezpłatnie</strong></li>
                  <li>KTG dla pacjentek niezadeklarowanych do naszej przychodni - <strong>30 zł</strong></li>
                </ul>
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="Upoważnienie do uzyskania dokumentacji medycznej"  id={'upowaznienia-do-dokumentacji-medycznej'}>
              <StandardTextBlock>
                <a aria-label="Upoważnienie do uzyskania dokumentacji medycznej DOCX 21KB" target="_blank" href="./do-pobrania/upowaznienie-dok-med-dorosli.docx">Pobierz upoważnienie (DOCX 21KB)</a>
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock 
              title="Przydatne linki" id={'przydatne-linki'}>
              <StandardTextBlock>
                <p><a aria-label="Ministerstwo Zdrowia strona otworzy się w nowym oknie" target="_blank" rel="noopener noreferrer" href="https://www.gov.pl/web/zdrowie">Ministerstwo Zdrowia</a></p>
                <p><a aria-label="Centrala NFZ strona otworzy się w nowym oknie" target="_blank" rel="noopener noreferrer" href="http://www.nfz.gov.pl">Centrala NFZ</a></p>
                <p><a aria-label="Oddział NFZ Wrocła strona otworzy się w nowym oknie" target="_blank" rel="noopener noreferrer" href="https://www.nfz-wroclaw.pl">Oddział NFZ Wrocław</a></p>
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock 
              title="Karta praw pacjenta" id={'karta-praw-pacjenta'}>
              <StandardTextBlock>
              <div className="policy">
            <p>Samodzielnym Publicznych Zakładzie Opieki</p>
            <p>Zdrowotnej &#8220;Przychodnia&#8221; w Sycowie</p>
            <p>Podstawa prawna:</p>
            <p>1) konstytucja RP z dnia 2 kwietnia 1997 roku (Dz. U. nr 78, poz. 483),</p>
            <p>2) ustawa z dnia 15 kwietnia 2011 roku o działalności leczniczej: tekst jednolity &#8211; Dz. U. z dnia<br /> 15 lutego 2013 roku, poz. 217,</p>
            <p>3) ustawa z dnia 27 sierpnia 2004 roku o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych (Dz. U. nr 164, poz. 1027 ze zmianami),</p>
            <p>4) ustawa z dnia 6 listopada 2008 roku o prawach pacjenta i Rzeczniku Praw Pacjenta (Dz. U.<br />nr 52, poz. 417 z dnia 31 marca 2009 roku ze zmianami).</p>
            <p>5) ustawa z dnia 5 lipca 1996 roku o zawodach pielęgniarki i położnej (Dz. U. z 2011 roku,<br />nr 174, poz. 1039 ze zmianami),</p>
            <p>6) ustawa z dnia 5 grudnia 1996 roku o zawodzie lekarza (tekst jednolity: Dz. U. z 2011 roku<br />nr 277, poz. 1634 ze zmianami),</p>
            <p>7) ustawa z dnia 29 listopada 2000 roku &#8211; Prawo atomowe: tekst jednolity: Dz. U. z 2012 roku, poz. 264.</p>
            <h3>1. Prawa pacjenta wynikając z Konstytucji RP:</h3>
            <p>1) każdy ma prawo do ochrony zdrowia &#8211; art. 68, ust. 1,</p>
            <p>2) każdy obywatel ma prawo do równego dostępu do świadczeń opieki zdrowotnej finansowanych ze środków publicznych, na warunkach i w zakresie określonych w ustawie &#8211; art. 68 ust. 2.</p>
            <h3>2. Prawa pacjenta w SPZOZ &#8220;Przychodnia&#8221; w Sycowie, o których stanowi ustawa o działalności leczniczej:</h3>
            <p>1) pacjent zgłaszający się do danej jednostki organizacyjnej przychodni ma prawo do natychmiastowego udzielenia mu świadczeń zdrowotnych w przypadku zagrożenia życia lub zdrowia, niezależnie od okoliczności,</p>
            <p>2) pacjent ma prawo do:</p>
            <p>a) świadczeń zdrowotnych odpowiadających wymaganiom wiedzy medycznej, a w sytuacji ograniczonych możliwości udzielenia odpowiednich świadczeń &#8211; do korzystania z rzetelnej, opartej na kryteriach medycznych procedury ustalającej kolejność dostępu do świadczeń,</p>
            <p>b) udzielenia mu świadczeń zdrowotnych przez osoby uprawnione do ich udzielania,<br />w pomieszczeniach i przy zastosowaniu urządzeń odpowiadających określonym wymaganiom fachowym i sanitarnym,</p>
            <p>c) informacji o swoim stanie zdrowia,</p>
            <p>d) wyrażenia zgody albo odmowy na udzielenie mu określonych świadczeń zdrowotnych, po uzyskaniu odpowiedniej informacji,</p>
            <p>e) intymności i poszanowania godności w czasie udzielania świadczeń zdrowotnych,</p>
            <p>f) udostępniania mu dokumentacji medycznej lub wskazania innej osoby, której dokumentacja ta może być udostępniana,</p>
            <p>g) zapewnienia ochrony danych zawartych w dokumentacji medycznej dotyczącej jego osoby,</p>
            <p>h) dostępu do informacji o prawach pacjenta.</p>
            <h3>3. Prawa pacjenta określone w ustawie o zawodzie lekarza:</h3>
            <p>Pacjent ma prawo do:</p>
            <p>1) udzielania mu świadczeń przez lekarza, zgodnie ze wskazaniami aktualnej wiedzy medycznej, dostępnymi lekarzowi metodami i środkami zapobiegania, rozpoznawania leczenia chorób, zgodnie z zasadami etyki zawodowej oraz należytą starannością,</p>
            <p>2) uzyskania od lekarza pomocy lekarskiej w każdym przypadku, gdy zwłoka w jej udzieleniu mogłaby spowodować niebezpieczeństwo utraty życia, ciężkiego uszkodzenia ciała lub ciężkiego rozstroju zdrowia oraz w innych przypadkach nie cierpiących zwłoki,</p>
            <p>3) uzyskania od lekarza przystępnej informacji o swoim stanie zdrowia, rozpoznaniu, proponowanych oraz możliwych metodach diagnostycznych, leczniczych, dających się przewidzieć następstwach ich zastosowania albo zaniechania, wynikach leczenia oraz rokowaniu,</p>
            <p>4) zażądania od lekarza informacji o swoim stanie zdrowia i o rokowaniu wówczas, gdy sądzi, że lekarz ogranicza mu informację z uwagi na jego dobro,</p>
            <p>5) decydowania o osobach, którym lekarz może udzielać informacji, o których mowa w pkt. 3,</p>
            <p>6) wyrażenia żądania, aby lekarz nie udzielał mu informacji, o których mowa w pkt.3,</p>
            <p>7) nie wyrażenia zgody na przeprowadzenie przez lekarza badania lub udzielenia mu innego świadczenia zdrowotnego,</p>
            <p>8) wyrażenia zgody albo odmowy na wykonanie mu zabiegu operacyjnego albo zastosowania wobec niego metody leczenia lub diagnostyki stwarzającej podwyższone ryzyko, w celu podjęcia przez pacjenta decyzji, lekarz musi udzielić mu informacji, o której mowa w pkt. 3,</p>
            <p>9) uzyskania od lekarza informacji, jeżeli w trakcie wykonywania zabiegu, bądź stosowania określonej metody leczenia lub diagnostyki, w związku z wystąpieniem okoliczności stanowiących zagrożenie dla życia lub zdrowia pacjenta, lekarz dokonał zmian w ich zakresie,</p>
            <p>10) poszanowania przez lekarza jego intymności i godności osobistej,</p>
            <p>11) wyrażenia zgody albo odmowy na uczestniczenie przy udzielaniu mu świadczeń zdrowotnych osób innych niż personel medyczny niezbędny ze względu na rodzaj świadczeń,</p>
            <p>12) wnioskowania do lekarza o zasięgnięcie przez niego opinii właściwego lekarza specjalisty,</p>
            <p>13) dostatecznie wcześniejszego uprzedzenia go przez lekarza o zamiarze odstąpienia od jego leczenia i wskazania mu realnych możliwości uzyskania określonego świadczenia zdrowotnego u innego lekarza lub w zakładzie opieki zdrowotnej,</p>
            <p>14) zachowania w tajemnicy informacji związanych z nim, a uzyskanych przez lekarza w związku z wykonywaniem zawodu,</p>
            <p>15) uzyskania od lekarza informacji o niekorzystnych dla niego skutkach, w przypadku wyrażenia zgody na ujawnienie dotyczących go informacji, będących w posiadaniu lekarza,</p>
            <p>16) wyrażenia zgody lub odmowy na podawanie przez lekarza do publicznej wiadomości danych umożliwiających identyfikacje pacjenta,</p>
            <p>17) wyrażenia zgody lub odmowy na udział w eksperymencie medycznym po uprzednim poinformowaniu go przez lekarza o celach, sposobach i warunkach przeprowadzenia eksperymentu, spodziewanych korzyściach leczniczych lub poznawczych, ryzyku oraz o możliwościach cofnięcia zgody i odstąpienia od udziału w eksperymencie w każdym jego stadium,</p>
            <p>18) poinformowania go przez lekarza o niebezpieczeństwie dla zdrowia i życia jakie może spowodować natychmiastowe przerwanie eksperymentu,</p>
            <p>19) cofnięcia zgody na eksperyment medyczny w każdym stadium eksperymentu.</p>
            <h3>4. Prawa pacjenta określone w ustawie o zawodzie pielęgniarki i położnej:</h3>
            <p>Pacjent ma prawo do:</p>
            <p>1) udzielania mu świadczeń zdrowotnych przez pielęgniarkę, położną, posiadającą prawo wykonywania zawodu, zgodnie z aktualną wiedzą medyczną, dostępnymi metodami i środkami, zgodnie z zasadami etyki zawodowej oraz ze szczególną starannością,</p>
            <p>2) udzielenia mu pomocy przez pielęgniarkę, położną, zgodnie z posiadanymi przez nią kwalifikacjami, w każdym przypadku niebezpieczeństwa utraty przez niego życia lub poważnego uszczerbku na jego zdrowiu,</p>
            <p>3) uzyskania od pielęgniarki, położnej informacji o jego prawach,</p>
            <p>4) uzyskania od pielęgniarki, położnej informacji o swoim stanie zdrowia, w zakresie realizowanym przez pielęgniarkę, lub położną,</p>
            <p>5) zachowania w tajemnicy informacji związanych z nim, a uzyskanych przez pielęgniarkę, położną w związku z wykonywaniem zawodu,</p>
            <p>6) wyrażania zgody lub odmowy na podawanie przez pielęgniarkę, położną do publicznej widomości danych umożliwiających identyfikację pacjenta.</p>
            <h3>5. Prawa pacjenta określone w ustawie o ochronie zdrowia psychicznego:</h3>
            <p>Pacjent z zaburzeniami psychicznymi, chory psychicznie lub upośledzony umysłowo ma prawo do:</p>
            <p>1) bezpłatnych świadczeń zdrowotnych udzielanych mu przez publiczne podmioty psychiatrycznej opieki zdrowotnej,</p>
            <p>2) zapewnienia mu rodzaju metod postępowania leczniczego właściwych nie tylko z uwagi na cele zdrowotne, ale także ze względu na interesy oraz inne jego dobro osobiste i dążenie do jego poprawy zdrowia w sposób najmniej dla pacjenta uciążliwy,</p>
            <p>3) uprzedzenie go o zamiarze zastosowania wobec niego przymusu bezpośredniego, przy czym dopuszczenie zastosowania tego środka wobec pacjenta musi wynikać z ustawy,</p>
            <p>4) uprzedzenia o zamiarze przeprowadzenia badania psychiatrycznego bez jego zgody oraz podania przyczyn takiej decyzji, badanie takie może być przeprowadzone, jeżeli zachowanie pacjenta wskazuje na to, że z powodu zaburzeń psychicznych może zagrażać bezpośrednio własnemu życiu, albo życiu lub zdrowiu innych osób, bądź nie jest zdolny do zaspokojenia podstawowych potrzeb życiowych.</p>
            <h3>6. Prawa pacjenta określone w ustawie o pobieraniu i przeszczepianiu komórek, tkanek i narządów:</h3>
            <p>Pacjent ma prawo do:</p>
            <p>1) wyrażenia i cofnięcia w każdym czasie sprzeciwu na pobranie po jego śmierci komórek, tkanek lub narządów,</p>
            <p>2) dobrowolnego wyrażenia przed lekarzem zgody na pobieranie od niego za życia komórek, tkanek lub narządów w celu ich przeszczepienia określonemu biorcy, jeżeli ma pełna zdolność do czynności prawnych,</p>
            <p>3) ochrony danych osobowych dotyczących dawcy / biorcy przeszczepu i objęcia ich tajemnica,</p>
            <p>4) pacjent mający być biorcą ma prawo do umieszczenia go na liście osób oczekujących na przeszczepienie komórek, tkanek i narządów i być wybrany jako biorca w oparciu o kryteria medyczne.</p>
            <h3>7. Prawa pacjenta określone w ustawie o prawach pacjenta i Rzeczniku Praw Pacjenta:</h3>
            <p>1.Pacjent ma prawo do:</p>
            <p>1) tajemnicy informacji z nim związanych,</p>
            <p>2) wyrażenia zgody na udzielanie świadczeń zdrowotnych,</p>
            <p>3) poszanowania intymności i godności pacjenta,</p>
            <p>4) do dokumentacji medycznej,</p>
            <p>5) zgłoszenia sprzeciwu wobec opinii albo orzeczenia lekarza,</p>
            <p>6) poszanowania życia prywatnego i rodzinnego.</p>
            <p>2. Dokumentacja medyczna jest udostępniana pacjentowi:</p>
            <p>1) do wglądu w siedzibie jednostki organizacyjnej przychodni, w której udzielono świadczenia zdrowotnego,</p>
            <p>2) poprzez sporządzenie jej wyciągów, odpisów lub kopii za odpłatnością zgodnie z cennikiem przychodni, opracowanym na podstawie art. 28 ustawy z dnia 6 listopada 2008 roku o prawach pacjenta i Rzeczniku Praw Pacjenta: Dz. U. z 2009 roku, nr 52, poz. 417.</p>
            <br/>
            <br/>
            <p><em><span>* Wyrok TK w sprawie zakresu świadczeń zdrowotnych określonych w Konstytucji RP &#8211; </span></em></p>
            <p><em><span>„Nie jest rolą Konstytucji RP dokładne określenie zakresu świadczeń opieki zdrowotnej, jakie należą się wszystkim obywatelom. O tym decyduje ustawa.”</span></em></p>
            <p><span>&#8211; <em>Zgodnie z wyrokiem TK, zakład opieki zdrowotnej świadczy usługi tylko do poziomu finansowania (liczby porad) o którym mowa jest w umowach na świadczenia specjalistyczne, zdrowia psychicznego, stomatologiczne i profilaktyczne.. </em></span></p>
            <p><em><span>Ponad limity można realizować świadczenia zdrowotne tylko w przypadku zagrożenia życia.</span></em></p>
          </div>
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock 
              title="Tryb przyjmowania skarg i wniosków" id={'skargi-i-wnioski'}>
              <StandardTextBlock>
              <div className="policy">
            <p>W Samodzielnym Publicznych Zakładzie Opieki</p>
            <p>Zdrowotnej &#8220;Przychodnia&#8221; w Sycowie</p>
            <p>Podstawa prawna: Rozporządzenie Rady Ministrów z dnia 08.01.2002 roku.</p>
            <p>1) Skargi i wnioski mogą być wnoszone pisemnie, telefaksem, pocztą elektroniczną, a także ustnie do protokołu.</p>
            <p>2) W razie zgłoszenia skargi lub wniosku ustnie, przyjmujący zgłoszenie sporządza protokół, który podpisują wnoszący skargę lub wniosek i przyjmujący zgłoszenie.</p>
            <p>3) W protokole zamieszcza się datę przyjęcia skargi lub wniosku, imię i nazwisko oraz adres zgłaszającego i zwięzły opis treści sprawy.</p>
            <p>4) Skargi niezawierające imienia i nazwiska oraz adresu wnoszącego pozostawia się bez rozpoznania.5. Przedmiotem skargi może być w szczególności zaniedbanie lub nienależyte wykonywanie zadań przez SP ZOZ “Przychodnia” w Sycowie lub przez pracowników w nim zatrudnionych, naruszenie praworządności lub interesów skarżących, a także przewlekłe lub biurokratyczne załatwianie spraw.6. Skargę lub wniosek na działalność poradni, pracowni czy rejestracji rozpatruje dyrektor  SP ZOZ “Przychodnia” w Sycowie.</p>
            <p>5) Skargi i wnioski na działalność przychodni i wiejskiego ośrodka zdrowia przyjmuje dyrektor SP ZOZ “Przychodnia” w Sycowie, przy ul. Wrocławskiej 2 w Sycowie we wtorki w godzinach od 8:00 do 9:30         w pok . 214  – tel.<a aria-label="Pod numerem telefonu 62 785 51 91" href="tel:627855191">62 785 51 91</a> .</p>
            <p>6) Jeżeli z treści skargi lub wniosku nie można należycie ustalić ich przedmiotu, dyrektor SP ZOZ “Przychodnia” w Sycowie wzywa wnoszącego skargę lub wniosek do złożenia, w terminie 7 dni od dnia otrzymania wezwania, wyjaśnienia lub uzupełnienia, z pouczeniem, że nieusunięcie tych braków spowoduje pozostawienie skargi lub wniosku bez rozpoznania.</p>
            <br/>
            <br/>
            <p>Dyrektor SPZOZ "Przychodnia" w Sycowie</p>
            <p>Piotr Nogala</p>
          </div>
              </StandardTextBlock>
            </CollapsibleBlock>
          </CollapsibleBlocksWrapper>
        </section>
      </Container>
    </PageWrapper>
  )
}

export default InformacjeDlaPacjentaPage;