import React, { } from "react";
import "./map-section.scss";

interface Props {
  maxWidth?: number
}

const FullMapSection: React.FC<Props> = (props: Props) => {
  const { maxWidth } = props;

  return (
    <section className="map-section" style={{ maxWidth: maxWidth ? maxWidth : '100%' }}>
      <iframe className="map no-invert" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39938.793087737475!2d17.594687750047562!3d51.27110567671153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47100404c83a3259%3A0x1f2de66a072fe16!2s56-500%20Stradomia%20Wierzchnia!5e0!3m2!1spl!2spl!4v1602272566872!5m2!1spl!2spl" frameBorder="0" style={{ border: 0 }} allowFullScreen={false} aria-hidden="false" tabIndex={0}></iframe>
    </section>
  )
}

export default FullMapSection;