import React from "react";
import "./princing-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import CollapsibleBlock from "../../components/collapsible-block/collapsible-block";
import CollapsibleBlocksWrapper from "../../components/collapsible-block/collapsible-blocks-wrapper";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import { medicalServices, orthodontics, vaccinations } from "./pricing-page-data";
import PricesRow from "../../components/row-info/prices-row";
import { dentists } from "../princing-page/pricing-page-data";

const PrincingPage: React.FC<any> = () => {

  return (
    <PageWrapper className="princing-page">
      <Helmet>
        <title>{'Cennik usług | SPZOZ Przychodnia Syców'}</title>
      </Helmet>

      <section className="pageWrapper__section">
        <Container>
          <MainHeader>CENNIK USŁUG</MainHeader>
          <CollapsibleBlocksWrapper>
            <CollapsibleBlock
              title="CENNIK SZCZEPIONEK"
              isSplited={true}
            >
              {vaccinations.map(item=><PricesRow pricesData={item}/>)}
              <br />
              <br />
            </CollapsibleBlock>
            {/* <CollapsibleBlock
              title="CENNIK SZCZEPIONEK PRZECIWKO GRYPIE"
              isSplited={true}
            >
              <StandardTextBlock>
                <p>
                  Szczepionki dla dorosłych:
              </p>
                <br />
                <p>
                  <strong>
                    Vaxigrip 0,5ml 40,00 zł
                </strong>
                </p>
                <br />
                <p>
                  <strong>
                    Influvac 0,5ml  40,00 zł
                </strong>
                </p>
              </StandardTextBlock>
            </CollapsibleBlock> */}
            <CollapsibleBlock
              title="CENNIK USŁUG - STOMATOLOG"
              isSplited={true}
            >
              <StandardTextBlock>
                <p><strong>USŁUGI PONADSTANDARDOWE OFEROWANE PRZEZ PORADNIĘ STOMATOLOGICZNĄ</strong></p>
                <p>OBOWIĄZUJĄCY OD DNIA 01.09.2022r.</p>
              </StandardTextBlock>
              {dentists.map(item=><PricesRow pricesData={item}/>)}
              <br />
              <br/>
              <p><strong>Pacjenci nie objęci ubezpieczeniem w ramach NFZ - 120 zł za każdą wizytę</strong></p>
              <br/>
              <br/>
            </CollapsibleBlock>

            <CollapsibleBlock
              title="CENNIK USŁUG PONADSTANDARDOWYCH – ORTODONCJA"
              isSplited={true}
            >
              <p><strong>Usługi ponadstandardowe świadczone przez Poradnię Ortodontyczną w zakresie:</strong></p>
              <StandardTextBlock>
                <ul>
                  <li>Leczenia ortodontycznego wad zgryzu z zastosowaniem aparatu do zdejmowania</li>
                  <li>jedno- i dwuszczękowego po ukończeniu 12 roku życia,</li>
                  <li>Kontroli wyników leczenia po ukończeniu 13 roku życia,</li>
                  <li>Naprawy aparatu ortodontycznego wykonanego poza ubezpieczeniem po ukończeniu 13 roku życia,</li>
                  <li>Wymiany i naprawy aparatu ortodontycznego uszkodzonego z powodu nieprawidłowego użytkowania.</li>
                </ul>
              </StandardTextBlock>
              <br />
              <br />
              {orthodontics.map(item=><PricesRow pricesData={item}/>)}
              <br />
              <br />
            </CollapsibleBlock>
            <CollapsibleBlock
              title="CENNIK USŁUG MEDYCZNYCH"
              isSplited={true}
            >
              {medicalServices.map(item=><PricesRow pricesData={item}/>)}
              <br />
              <br />
            </CollapsibleBlock>

            {/* <CollapsibleBlock
              title="CENNIK - SZYBKI TEST NA GRYPĘ"
              isSplited={true}
            >
              <StandardTextBlock>
                GRYPA A/B INFLUENZA – 25,00 zł. / szt. obowiązuje od 01.10.2020
              </StandardTextBlock>
            </CollapsibleBlock> */}
          </CollapsibleBlocksWrapper>
        </Container>
      </section>
    </PageWrapper >
  )
}

export default PrincingPage;